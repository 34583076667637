import React, { memo, useEffect, useCallback, useState, useRef } from 'react';
import { Form, Input, Checkbox, Button, InputNumber } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// Import everything
import { ethers } from 'ethers';

import Alert from 'react-bootstrap/Alert';

import { Network, Alchemy } from 'alchemy-sdk';
import { Magic } from 'magic-sdk';
import { ConnectExtension } from '@magic-ext/connect';
import Web3 from 'web3';
import FormFieldStacked from '../../../components/commons/FormFieldStacked';

import {
	VALIDATE_MESSAGES,
	ALCHEMY_API_KEY,
	MAGIC_CONNECT_KEY,
	NODE_ENV,
	MAGIC_CONNECT_NETWORK
} from '../../../constants/common';
import {
	IMAGE_FILETYPES,
	VIDEO_FILETYPES,
	IMAGE_LARGE_MIN_HEIGHT,
	IMAGE_LARGE_MIN_WIDTH,
	IMAGE_LARGE_MAX_HEIGHT,
	IMAGE_LARGE_MAX_WIDTH,
	IMAGE_LARGE_FILE_UPLOAD_SIZE,
	VIDEO_STANDARD_MIN_HEIGHT,
	VIDEO_STANDARD_MIN_WIDTH,
	VIDEO_STANDARD_MAX_HEIGHT,
	VIDEO_STANDARD_MAX_WIDTH,
	VIDEO_STANDARD_FILE_UPLOAD_SIZE, IMAGE_VIDEO_FILE_SIZE_LIMIT
} from '../../../constants/file';
import {
	checkCollectionsContractAddressAvailability,
	checkMembershipShortCodeAvailability,
	createSpaceAddMembership
} from '../../../services/space';
import { getSpaceInfo } from '../../../states/spaceGlobal/actions';
import { hideTooltip, showTooltip } from '../../../utils/input';
import { getListIcon } from '../../../utils/list';
import {
	ACCESS_TYPES_LIST_WITH_DEFAULT,
	CONTENT_TYPES_LIST_WITH_DEFAULT,
	MEMBERSHIP_TYPES, PRICING_TYPES_LIST, SELECT_CURRENCIES, UPDATES_VISIBILITY
} from '../../../constants/space';
import routes from '../../../routes';
import DropdownWithItems from '../../../components/commons/DropdownWithItems';
import UploadDragger from '../../../components/commons/UploadDragger';

import './../SpaceCreate.scss';
import TextareaAutosize from 'react-textarea-autosize';
import ExplicitContent from '../../../components/commons/Modals/ExplicitContent';
import { getSitewideLink } from '../../../utils/sitewideLinks';
import { CustomValidators } from '../../../utils/CustomValidators';
import UploadDraggerV2 from '../../../components/commons/UploadDraggerV2';
import {RenewalOptions} from "../../../utils/MembershipItemUtils";
import ReactQuill from "react-quill";

const SpaceCreateAddMembership = () => {

	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [createSpaceCollectionDetailsForm] = Form.useForm();
	const [createSpaceAddMembershipForm] = Form.useForm();

	const [submitted, setSubmitted] = useState(false);
	const [initialLoad, setInitialLoad] = useState(true);
	const [photoIsRemoved, setPhotoIsRemoved] = useState(false);
	const [videoIsRemoved, setVideoIsRemoved] = useState(false);
	const [isSubmitClicked, setSubmitClicked] = useState(false);
	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const [enableExpirationChecked, setExpirationChecked] = useState(false);
	const [submittedVerification, setSubmittedVerification] = useState(false);
	const [formHasErrorMembership, setFormHasErrorMembership] = useState(true);
	const [formHasErrorCollection, setFormHasErrorCollection] = useState(true);
	const [formIsOnFocusMembership, setFormIsOnFocusMembership] = useState(false);
	const [formIsOnFocusCollection, setFormIsOnFocusCollection] = useState(false);
	const [collectionContractFound, setCollectionContractFound] = useState(false);
	const [contractAddressVerified, setContractAddressVerified] = useState(false);
	const [modalOpenExplicitAccess, setModalOpenExplicitAccess] = useState(false);
	const [modalOpenExplicitContent, setModalOpenExplicitContent] = useState(false);
	const [submittedCollectionDetails, setSubmittedCollectionDetails] = useState(false);
	const [enableNumericCount, setEnableNumericCount] = useState(false);
	const [isGoBack, setIsGoBack] = useState(false);

	const [selectedExplicitContent, setSelectedExplicitContent] = useState([]);
	const [selectedExplicitAccess, setSelectedExplicitAccess] = useState([]);


	const [membershipName, setMembershipName] = useState(null);
	const [membershipSummary, setMembershipSummary] = useState(null);
	const [blurredField, setBlurredField] = useState(null);
	const [membershipListingText, setMembershipListingText] = useState(null);
	const [membershipDescription, setMembershipDescription] = useState(null);
	const [membershipSKUShortcode, setMembershipSKUShortcode] = useState(null);
	const [membershipIndividualTitling, setMembershipIndividualTitling] = useState(null);
	const [membershipPurchasePrice, setMembershipPurchasePrice] = useState(null);
	const [membershipTotalAvailable, setMembershipTotalAvailable] = useState(null);
	const [membershipAccountLimit, setMembershipAccountLimit] = useState(null);
	const [membershipIncludedMonths, setMembershipIncludedMonths] = useState(null);
	const [membershipOneMonth, setMembershipOneMonth] = useState(null);
	const [membershipThreeMonths, setMembershipThreeMonths] = useState(null);
	const [membershipSixMonths, setMembershipSixMonths] = useState(null);
	const [membershipTwelveMonths, setMembershipTwelveMonths] = useState(null);
	const [membershipPurchasePassword, setMembershipPurchasePassword] = useState(null);

	const [alert, setAlert] = useState(null);
	const [space, setSpace] = useState(null);
	const [verifyAlert, setVerifyAlert] = useState(null);
	const [membershipID, setMembershipID] = useState(null);
	const [benefitID, setBenefitID] = useState(null);
	const [collectionAlert, setCollectionAlert] = useState(null);
	const [contractAddress, setContractAddress] = useState(null);
	const [membershipImage, setMembershipImage] = useState(null);
	const [membershipVideo, setMembershipVideo] = useState(null);
	const [membershipImageError, setMembershipImageError] = useState(null);
	const [membershipVideoError, setMembershipVideoError] = useState(null);
	const [contractAddressDetails, setContractAddressDetails] = useState(null);
	const [membershipVideoBlobPreview, setMembershipVideoBlobPreview] = useState(null);
	const [membershipImageBlobPreview, setMembershipImageBlobPreview] = useState(null);

	const [selectedAccessType, setSelectedAccessType] = useState('has_no_explicit');
	const [selectedPricingType, setSelectedPricingType] = useState('default');
	const [selectedCurrencyType, setSelectedCurrencyType] = useState('USD');
	const [selectedContentType, setSelectedContentType] = useState('has_no_explicit');
	const [selectedVisibilityType, setSelectedVisibilityType] = useState('public');

	const [membershipType, setMembershipType] = useState(null);
	const [walletAccounts, setWalletAccounts] = useState(null);
	const membershipNameRef = useRef(null);
	const contractAddressRef = useRef(null);
	const membershipSummaryRef = useRef(null);
	const membershipListingTextRef = useRef(null);
	const membershipSKUShortcodeRef = useRef(null);
	const membershipMaxPerAccountRef = useRef(null);

	const toolbarOptions = [
		['bold', 'italic', 'underline', 'strike'], // toggled buttons
		[{size: ['small', false, 'large', 'huge']}], // custom dropdown
		[{font: []}],
		[{header: 1}, {header: 2}], // custom button values
		[{header: [1, 2, 3, 4, 5, 6, false]}],
		['blockquote', 'code-block'],
		['link', 'image', 'video', 'formula'],
		[{list: 'ordered'}, {list: 'bullet'}],
		[{script: 'sub'}, {script: 'super'}], // superscript/subscript
		[{indent: '-1'}, {indent: '+1'}], // outdent/indent
		[{direction: 'rtl'}], // text direction
		[{color: []}, {background: []}], // dropdown with defaults from theme
		[{align: []}],
		['clean'] // remove formatting button
	];

	// Optional Config object, but defaults to demo api-key and eth-mainnet.
	const settings = {
		apiKey: ALCHEMY_API_KEY, // Replace with your Alchemy API Key.
		network: Network.ETH_MAINNET // Replace with your network.
	};

	const alchemy = new Alchemy(settings);

	// Magic Link Web 3
	const magic = new Magic(MAGIC_CONNECT_KEY, {
		network: MAGIC_CONNECT_NETWORK,
		locale: 'en_US',
		extensions: [new ConnectExtension()]
	});

	window.magic = magic;
	const web3 = new Web3(magic.rpcProvider);

	// Regex to allow numbers with up to 2 decimal places if (valid) { setValue(value); } };
	const handleMonthlyChange = (value, monthlyOptions) => {
		const valid = /^\d*\.?\d{0,2}$/.test(value);
		if (valid) {
			if (RenewalOptions.monthly === monthlyOptions) {
				setMembershipOneMonth(value);
			} else if (RenewalOptions.quarterly === monthlyOptions) {
				setMembershipThreeMonths(value);
			} else if (RenewalOptions.biannually === monthlyOptions) {
				setMembershipSixMonths(value);
			} else if (RenewalOptions.annually === monthlyOptions) {
				setMembershipTwelveMonths(value);
			}
		}
	};

	const handleCollectionDetailsFormOnChange = useCallback(async () => {
		if (spaceInfo) {
			const data = {
				space_id: spaceInfo.id,
				contract_address: createSpaceCollectionDetailsForm.getFieldValue('contract_address')
			};

			const contract_address_check_result = await checkCollectionsContractAddressAvailability(
				data
			);
			if (contract_address_check_result) {
				if (contract_address_check_result.availability) {
					await createSpaceCollectionDetailsForm
						.validateFields()
						.then(async () => {
							setFormHasErrorCollection(false);
						})
						.catch((errors) => {
							setFormHasErrorCollection(true);
						});
				} else {
					setCollectionAlert({
						variant: 'danger',
						message: 'Collection contract address already exists.'
					});
				}
			}
		}
	}, [spaceInfo]);

	const handleCollectionDetailsFormOnBlur = useCallback(async () => {
		if (spaceInfo) {
			const data = {
				space_id: spaceInfo.id,
				contract_address: createSpaceCollectionDetailsForm.getFieldValue('contract_address')
			};

			const contract_address_check_result = await checkCollectionsContractAddressAvailability(
				data
			);
			if (contract_address_check_result) {
				if (contract_address_check_result.availability) {
					await createSpaceCollectionDetailsForm
						.validateFields()
						.then(async () => {
							setContractAddress(
								createSpaceCollectionDetailsForm.getFieldValue('contract_address')
							);
							await getCollections(
								createSpaceCollectionDetailsForm.getFieldValue('contract_address')
							);
							setFormHasErrorCollection(false);
						})
						.catch((errors) => {
							setFormHasErrorCollection(true);
						});
				} else {
					setCollectionAlert({
						variant: 'danger',
						message: 'Collection contract address already exists.'
					});
				}
			}
		}
	}, [spaceInfo]);

	const handleFormMembershipChanges = useCallback(async () => {
		setMembershipName(createSpaceAddMembershipForm.getFieldValue('name'));
		setMembershipSummary(createSpaceAddMembershipForm.getFieldValue('summary'));
		setMembershipListingText(createSpaceAddMembershipForm.getFieldValue('listing_text'));
		// setMembershipSKUShortcode(createSpaceAddMembershipForm.getFieldValue('sku_shortcode'));
		setMembershipIndividualTitling(createSpaceAddMembershipForm.getFieldValue('individual_title'));
		// setMembershipPurchasePrice(createSpaceAddMembershipForm.getFieldValue('price'));
		// setMembershipTotalAvailable(createSpaceAddMembershipForm.getFieldValue('max_supply'));
		// setMembershipAccountLimit(createSpaceAddMembershipForm.getFieldValue('max_per_account'));
		setMembershipPurchasePassword(createSpaceAddMembershipForm.getFieldValue('password'));
		setMembershipIncludedMonths(createSpaceAddMembershipForm.getFieldValue('included_months'));
	}, [
		membershipName,
		membershipSummary,
		membershipListingText,
		membershipDescription,
		membershipSKUShortcode,
		membershipIndividualTitling,
		membershipPurchasePrice,
		membershipTotalAvailable,
		membershipAccountLimit,
		membershipOneMonth,
		membershipThreeMonths,
		membershipSixMonths,
		membershipTwelveMonths,
		membershipPurchasePassword,
		membershipIncludedMonths
	]);

	const handleMembershipFormOnBlur = useCallback(async () => {
		await createSpaceAddMembershipForm
			.validateFields()
			.then(async () => {
				setFormHasErrorMembership(false);
			})
			.catch((errors) => {
				console.log(errors)
				setFormHasErrorMembership(true);
			});
	}, []);

	const getCollectionOwner = async (collection_address) => {
		try {
			const contractABI = ['function owner() view returns (address)'];
			const provider = new ethers.providers.AlchemyProvider('mainnet', ALCHEMY_API_KEY);
			const myContract = new ethers.Contract(collection_address, contractABI, provider);
			const owner = await myContract.owner();
			return owner;
		} catch (error) {
			console.error(`Failed to get owner: ${error}`);
		}
	};

	const getCollections = async (collection_address, proceed_verify_wallet_address = false) => {
		setVerifyAlert(null);
		setCollectionAlert(null);
		setCollectionContractFound(null);
		// Print NFT metadata returned in the response:

		const checkOwnerRes = await getCollectionOwner(collection_address);
		// console.log('Owner Address: ' + checkOwnerRes);

		setSubmittedCollectionDetails(true);

		await alchemy.nft
			.getContractMetadata(collection_address)
			.then((result) => {
				// The contract owner
				// Other vars: address, contractDeployer, name, description
				// console.log(result);
				if (result) {
					const contractAddressData = {
						collection_name: result.openSea.collectionName ?? 'No Name',
						description: result.openSea.description ?? 'No Description',
						creator_wallet_address: checkOwnerRes ?? 'No Address',
						wallet_name: result.tokenType
					};
					setContractAddressDetails(contractAddressData);
					setCollectionContractFound(true);

					if (proceed_verify_wallet_address) {
						verifyWalletAddress(contractAddressData);
					}
				} else {
					setContractAddressDetails(null);
					setCollectionContractFound(false);
					setCollectionAlert({
						variant: 'danger',
						message: 'Collection not found.'
					});
				}
				setSubmittedCollectionDetails(false);
			})
			.catch((error) => {
				// console.log('error')
				// console.log(error)
				const invalid_address_error = 'contractAddress should be a valid address';
				if (error && error.message && error.message.includes(invalid_address_error)) {
					setCollectionAlert({
						variant: 'danger',
						message: 'Invalid Contract Address.'
					});
					setSubmittedCollectionDetails(false);
				}
			});

		setSubmittedCollectionDetails(false);
	};

	const handleCollectionDetailsSubmit = useCallback(
		async (values) => {
			setContractAddressDetails(null);
			await getCollections(values.contract_address);
		},
		[authUserInfo]
	);

	const verifyWalletAddress = useCallback(
		async (passedContractAddressDetails) => {
			setSubmittedVerification(true);
			await magic.connect.disconnect().catch((e) => {
				// console.log(e);
			});
			setVerifyAlert(null);
			const createdSpace = spaceInfo;
			let passed_creator_wallet_address = null;
			if (passedContractAddressDetails) {
				passed_creator_wallet_address = passedContractAddressDetails.creator_wallet_address;
			}
			if (contractAddressDetails) {
				passed_creator_wallet_address = contractAddressDetails.creator_wallet_address;
			}

			web3.eth
				.getAccounts()
				.then((accounts) => {
					setWalletAccounts(accounts?.[0]);
					if (passed_creator_wallet_address) {
						console.log('User Wallet Address: ' + accounts?.[0]);
						console.log('Collection Owner Address: ' + passed_creator_wallet_address);

						let isCorrectWallet = accounts?.[0] == passed_creator_wallet_address;

						//SET correct is true on dev environment for testing
						if (NODE_ENV === 'development') isCorrectWallet = true;

						setContractAddressVerified(isCorrectWallet);

						if (!isCorrectWallet) {
							setVerifyAlert({
								variant: 'danger',
								message: 'Collection Contract validation failed.'
							});
						}

						if (createdSpace) {
							const spaceMemberships = createdSpace.memberships;
							if (
								createdSpace &&
								spaceMemberships &&
								spaceMemberships.length > 0
							) {
								const firstMembership = spaceMemberships[0];
								createSpaceAddMembershipForm.setFieldsValue({
									name: firstMembership.name,
									listing_text: firstMembership.listing_text,
									summary: firstMembership.summary,
									sku_shortcode: firstMembership.sku_shortcode,
									max_per_account: firstMembership.max_per_account
								});
								setMembershipID(firstMembership.id);
								setMembershipName(firstMembership.name);
								setMembershipListingText(firstMembership.listing_text);
								setMembershipSummary(firstMembership.summary);
								setMembershipSKUShortcode(firstMembership.sku_shortcode);
								setMembershipTotalAvailable(null);
								// setMembershipTotalAvailable(firstMembership.max_supply);
								setExpirationChecked(
									firstMembership.enable_expiration_and_subscription
								);
								setSelectedContentType(firstMembership.content_type ?? 'has_no_explicit');
								setSelectedAccessType(firstMembership.content_access ?? 'has_no_explicit');
								if (firstMembership.photo) {
									setMembershipImageBlobPreview(firstMembership.photo);
								}
								if (firstMembership.video) {
									setMembershipVideoBlobPreview(firstMembership.video);
								}

								setTimeout(() => {
									createSpaceAddMembershipForm
										.validateFields()
										.then(async () => {
											setFormHasErrorMembership(false);
										})
										.catch((errors) => {
											setFormHasErrorMembership(true);
										});
								}, 1000);
							}
						}
					} else {
						setContractAddressVerified(
							accounts?.[0] === contractAddressDetails.creator_wallet_address
						);
					}
					setSubmittedVerification(false);
				})
				.catch((error) => {
					setSubmittedVerification(false);
				});
		},
		[contractAddressDetails, spaceInfo]
	);

	const handleSubmit = useCallback(async (values) => {
			setSubmitted(true);
			setAlert(null);
			setDisplayDropDownError(true);
			setMembershipImageError(null);

			let hasError = false;

			if (!selectedContentType || selectedContentType === 'default' ||
				!selectedAccessType || selectedAccessType === 'default' ||
				!selectedPricingType || selectedPricingType === 'default' ||
				!selectedCurrencyType || selectedCurrencyType === 'default') {
				hasError = true;
			}

			if (!membershipDescription || membershipDescription ==='default') {
				hasError = true;
			}

			if (!membershipImageBlobPreview) {
				window.scrollTo(0, 0);
				setMembershipImageError('Membership Image is required.');
				hasError = true;
			}

			// if (!contractAddress) {
			// 	setCollectionAlert({
			// 		variant: 'danger',
			// 		message: 'Collection contract address not found.'
			// 	});
			// 	hasError = true;
			// }
			if (formHasErrorMembership || hasError) {
				setSubmitted(false);
				setTimeout(() => {
					setBlurredField('all');
				}, 1000);
				return;
			}

			const data = values;
			// data.type = MEMBERSHIP_TYPES.NFT_COLLECTION;
			data.type = MEMBERSHIP_TYPES.COMMUNITY;
			// data.collection_contract_address = contractAddress;
			data.name = membershipName;
			data.listing_text = membershipListingText;
			data.summary = membershipSummary;
			data.description = membershipDescription;
			data.sku_shortcode = membershipSKUShortcode;
			data.individual_title = membershipIndividualTitling;
			data.max_per_account = membershipAccountLimit;
			data.max_supply = membershipTotalAvailable;
			data.expiration_subscription_enabled = selectedPricingType === 'recurring';
			data.content_type = selectedContentType;
			data.content_access = selectedAccessType;
			data.explicit_content = JSON.stringify(selectedExplicitContent);
			data.explicit_access = JSON.stringify(selectedExplicitAccess);
			data.visibility = selectedVisibilityType;
			data.currency = selectedCurrencyType;
			data.is_include_benefit = selectedPricingType === 'recurring';
			data.enable_add_numeric_count = enableNumericCount;
			data.pricing_type = selectedPricingType;

			data.price = membershipPurchasePrice;

			if (selectedPricingType === 'recurring' && !membershipOneMonth && !membershipThreeMonths && !membershipSixMonths && !membershipTwelveMonths) {
				setSubmitted(false);
				setAlert({
					variant: 'danger',
					message: "At least one monthly option must be provided."
				});
				return;
			}

			data.monthly_price = !membershipOneMonth ? null : membershipOneMonth;
			data.three_month_renewal = !membershipThreeMonths ? null : membershipThreeMonths;
			data.six_month_renewal = !membershipSixMonths ? null : membershipSixMonths;
			data.twelve_month_renewal = !membershipTwelveMonths ? null : membershipTwelveMonths;
			data.included_months = membershipIncludedMonths;
			data.password = membershipPurchasePassword;

			const url = routes.createSpaceAddMembershipTraitsPage();
			data.last_step_link = url.replace(':space_username', space_username);

			if (membershipImage) {
				data.membership_image = membershipImage;
			} else {
				if (photoIsRemoved) {
					data.photo_is_empty = true;
				}
			}

			if (membershipVideo) {
				data.membership_video = membershipVideo;
			} else {
				if (videoIsRemoved) {
					data.video_is_empty = true;
				}
			}

			try {
				if (authUserInfo) {
					data.user_id = authUserInfo.id;
					data.space_id = spaceInfo.id;
					if (membershipID) {
						data.membership_id = membershipID;
					}
					if (benefitID) {
						data.benefit_id = benefitID;
					}
					const response = await createSpaceAddMembership(data);
					if (response && response.result) {
						dispatch(getSpaceInfo(space_username));
						setSubmitted(false);
						const url = routes.createSpaceAddMembershipTraitsPage();
						navigate(url.replace(':space_username', space_username));
						setDisplayDropDownError(false);
					} else {
						setAlert({
							variant: 'danger',
							message: response.message
						});
						setTimeout(()=>{
							setAlert(null);
						},3000)
						setDisplayDropDownError(false);
						setSubmitted(false);
					}
				}
			} catch (error) {
				console.log(error);
				setSubmitted(false);
			}
		},
		[
			formHasErrorMembership,
			space_username,
			authUserInfo,
			spaceInfo,
			contractAddress,
			enableExpirationChecked,
			membershipID,
			benefitID,
			membershipImage,
			membershipVideo,
			selectedContentType,
			selectedAccessType,
			selectedPricingType,
			selectedCurrencyType,
			selectedPricingType,
			enableNumericCount,
			selectedVisibilityType,
			photoIsRemoved,
			videoIsRemoved,
			membershipName,
			membershipSummary,
			membershipListingText,
			membershipDescription,
			membershipSKUShortcode,
			membershipIndividualTitling,
			selectedExplicitAccess,
			selectedExplicitContent,
			membershipImageBlobPreview,
			membershipPurchasePrice,
			membershipOneMonth,
	 		membershipThreeMonths,
	        membershipSixMonths,
		    membershipTwelveMonths,
	        membershipIncludedMonths,
	        membershipPurchasePassword,
		]
	);

	const saveAndGoBack = useCallback(async () => {
		if (isGoBack) {
			dispatch(getSpaceInfo(space_username));
			const url = routes.createSpaceAddDetailsPage();
			navigate(url.replace(':space_username', space_username));
			return;
		}
		setSubmitted(true);
		setMembershipImageError(null);

		let hasError = false;

		if (!selectedContentType || selectedContentType === 'default' ||
			!selectedAccessType || selectedAccessType === 'default' ||
			!selectedPricingType || selectedPricingType === 'default' ||
			!selectedCurrencyType || selectedCurrencyType === 'default') {
			hasError = true;
		}

		if (!membershipDescription || membershipDescription ==='default') {
			hasError = true;
		}

		if (!membershipImageBlobPreview) {
			setMembershipImageError('Membership Image is required.');
			hasError = true;
		}

		if (formHasErrorMembership || hasError) {
			setSubmitted(false);
			setTimeout(() => {
				setBlurredField('all');
			}, 1000);
			return;
		}

		const data = {};
		// data.type = MEMBERSHIP_TYPES.NFT_COLLECTION;
		data.type = MEMBERSHIP_TYPES.COMMUNITY;
		// data.collection_contract_address = contractAddress;
		data.name = membershipName;
		data.listing_text = membershipListingText;
		data.summary = membershipSummary;
		data.description = membershipDescription;
		data.sku_shortcode = membershipSKUShortcode;
		data.individual_title = membershipIndividualTitling;
		data.max_per_account = membershipAccountLimit;
		data.max_supply = membershipTotalAvailable;
		data.expiration_subscription_enabled = selectedPricingType === 'recurring';
		data.content_type = selectedContentType;
		data.content_access = selectedAccessType;
		data.explicit_content = JSON.stringify(selectedExplicitContent);
		data.explicit_access = JSON.stringify(selectedExplicitAccess);
		data.visibility = selectedVisibilityType;
		data.currency = selectedCurrencyType;
		data.is_include_benefit = selectedPricingType === 'recurring';
		data.enable_add_numeric_count = enableNumericCount;
		data.pricing_type = selectedPricingType;

		data.price = membershipPurchasePrice;
		data.monthly_price = !membershipOneMonth ? null : membershipOneMonth;
		data.three_month_renewal = !membershipThreeMonths ? null : membershipThreeMonths;
		data.six_month_renewal = !membershipSixMonths ? null : membershipSixMonths;
		data.twelve_month_renewal = !membershipTwelveMonths ? null : membershipTwelveMonths;
		data.included_months = membershipIncludedMonths;
		data.password = membershipPurchasePassword;

		// console.log(data);
		// console.log(membershipOneMonth);

		if (membershipImage) {
			data.membership_image = membershipImage;
		} else {
			if (photoIsRemoved) {
				data.photo_is_empty = true;
			}
		}

		if (membershipVideo) {
			data.membership_video = membershipVideo;
		} else {
			if (videoIsRemoved) {
				data.video_is_empty = true;
			}
		}

		try {
			if (authUserInfo) {
				data.user_id = authUserInfo.id;
				data.space_id = spaceInfo.id;

				if (membershipID) {
					data.membership_id = membershipID;
				}
				if (benefitID) {
					data.benefit_id = benefitID;
				}

				setAlert(null);
				setDisplayDropDownError(true);
				const response = await createSpaceAddMembership(data);
				if (response && response.result) {
					setSubmitted(false);
					dispatch(getSpaceInfo(space_username));
					const url = routes.createSpaceAddDetailsPage();
					navigate(url.replace(':space_username', space_username));
				} else {
					setSubmitted(false);
					setAlert({
						variant: 'danger',
						message: response.message
					});
					window.scrollTo(0, 0);
				}
				setDisplayDropDownError(false);
			}
		} catch (error) {
			setSubmitted(false);
			console.log(error);
		}
	}, [
		space_username,
		authUserInfo,
		spaceInfo,
		contractAddress,
		membershipName,
		membershipSummary,
		membershipListingText,
		membershipDescription,
		membershipSKUShortcode,
		membershipIndividualTitling,
		enableExpirationChecked,
		membershipTotalAvailable,
		membershipAccountLimit,
		membershipOneMonth,
		membershipThreeMonths,
		membershipSixMonths,
		membershipTwelveMonths,
		membershipIncludedMonths,
		membershipPurchasePrice,
		membershipID,
		benefitID,
		membershipImage,
		membershipVideo,
		selectedContentType,
		selectedAccessType,
		selectedPricingType,
		selectedCurrencyType,
		enableNumericCount,
		selectedVisibilityType,
		photoIsRemoved,
		videoIsRemoved,
		selectedExplicitAccess,
		selectedExplicitContent,
		membershipImageBlobPreview,
		membershipPurchasePassword,
		isGoBack
	]);

	const handleMembershipImageUploadFileChange = (file, blobPreview, error = false) => {
		if (!file) {
            // if(error){
            //     setMembershipImageError('Membership Image is Invalid.');
            // }
			setPhotoIsRemoved(true);
		} else {
			setPhotoIsRemoved(false);
            if(!error){
                setMembershipImageError(null);
            }
		}

		setMembershipImage(file);
		setMembershipImageBlobPreview(blobPreview);
	};

	const handleMembershipVideoUploadFileChange = (file, blobPreview, error = false) => {
		if (!file) {
            // if(error){
            //     setMembershipVideoError('Membership Video is Invalid.');
            // }
			setVideoIsRemoved(true);
		} else {
            if(!error){
                setMembershipVideoError(null);
            }
			setVideoIsRemoved(false);
		}

		setMembershipVideo(file);
		setMembershipVideoBlobPreview(blobPreview);
	};

	const preventNegativeInputRegex = (input) => { const pattern = /^(0|[1-9][0-9]*)$/; return pattern.test(input);}

	const preventNegativeInput = (e) => {
		const inputValue = e.target.value;
		const nextValue = inputValue + e.key;
		if (['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(e.key)) { return; }
		if (!preventNegativeInputRegex(nextValue)) { e.preventDefault(); }
	}

	const handleTotalAvailableChange = (e) => {
		const value = e.target.value;
		if (!isNaN(value) && value >= 0) {
			setMembershipTotalAvailable(value);
		}
	};

	const handleMonthlyPriceOnBlur = (e, monthlyType) => {
		const inputValue = e.target.value;
		// This regex pattern matches numbers that end with a period.
		// ^       : Asserts the start of the string
		// \d+     : Matches one or more digits (0-9)
		// \.      : Matches a literal period (dot)
		// $       : Asserts the end of the string
		const isValid = /^\d+\.$/.test(inputValue);
		if (isValid) {
			// Append '00' to make it a valid decimal number with two decimal places
			const correctedValue = inputValue + '00';

			if (monthlyType === RenewalOptions.monthly) {
				setMembershipOneMonth(correctedValue);
				createSpaceAddMembershipForm.setFieldValue('one_month_renewal', correctedValue);
			} else if (monthlyType === RenewalOptions.quarterly) {
				setMembershipThreeMonths(correctedValue);
				createSpaceAddMembershipForm.setFieldValue('three_month_renewal', correctedValue);
			} else if (monthlyType === RenewalOptions.biannually) {
				setMembershipSixMonths(correctedValue);
				createSpaceAddMembershipForm.setFieldValue('six_month_renewal', correctedValue);
			} else if (monthlyType === RenewalOptions.annually) {
				setMembershipTwelveMonths(correctedValue);
				createSpaceAddMembershipForm.setFieldValue('twelve_month_renewal', correctedValue);
			}

		}
	};

	const handleFinishFailed = useCallback(async (errorInfo) => {
		setSubmitClicked(true);
		// Set the blurred field to the first field with an error
		if (errorInfo.errorFields.length > 0) {
			setBlurredField('all');
		}
	}, [blurredField, isSubmitClicked]);

	const handleOnblurField = useCallback(async (field) => {
		if (!isSubmitClicked) {
			setBlurredField(field);
		}
		setSubmitClicked(false);
	}, [blurredField, isSubmitClicked]);

	const handlePurchasePriceOnBlur = (e) => {
		handleOnblurField('price').then(() => {});
		const inputValue = e.target.value;
		// This regex pattern matches numbers that end with a period.
		// ^       : Asserts the start of the string
		// \d+     : Matches one or more digits (0-9)
		// \.      : Matches a literal period (dot)
		// $       : Asserts the end of the string
		const isValid = /^\d+\.$/.test(inputValue);
		if (isValid) {
			// Append '00' to make it a valid decimal number with two decimal places
			const correctedValue = inputValue + '00';
			setMembershipPurchasePrice(correctedValue);
			createSpaceAddMembershipForm.setFieldValue('price', correctedValue);
		}
	};

	const preventPurchasePriceNegativeAndZeroInput = (e) => {
		// Get the current input value
		const inputValue = e.target.value + e.key;
		if (['Backspace', 'Delete'].includes(e.key)) {
			return;
		}
		// This regex validates input values to allow positive whole numbers and floating-point numbers
		// with up to two decimal places, but it does not allow zero.
		// Explanation:
		// ^                    : Start of the string
		// [1-9]\d*             : Matches positive whole numbers excluding leading zeros and zero itself
		// (\.\d{0,2})?         : Optionally matches a decimal point followed by zero, one, or two digits
		// $                    : End of the string
		const isValidNotAllowZeroNumber = /^[1-9]\d*(\.\d{0,2})?$/.test(inputValue);
		if ((!selectedPricingType || selectedPricingType === 'default' || selectedPricingType === 'one-time') && !isValidNotAllowZeroNumber) {
			const isCurrentValueValid = /^[1-9]\d*(\.\d{0,2})?$/.test(e.key);
			if (!isCurrentValueValid) {
				e.preventDefault();
			}
		}
		// This regex validates input values to allow zero, positive whole numbers, and floating-point numbers
		// with up to two decimal places.
		// Explanation:
		// ^                  : Start of the string
		// (0|[1-9]\d*)       : Matches zero or positive whole numbers excluding leading zeros
		// (\.\d{0,2})?       : Optionally matches a decimal point followed by zero, one, or two digits
		// $                  : End of the string
		const isValidAllowZeroNumber = /^(0|[1-9]\d*)(\.\d{0,2})?$/.test(inputValue);
		if (selectedPricingType === 'recurring' && !isValidAllowZeroNumber) {
			const isValidAllowZeroNumber = /^[1-9]\d*(\.\d{0,2})?$/.test(e.key);
			if (!isValidAllowZeroNumber) {
				e.preventDefault();
			}
			const currentValue = e.target.value;
			if (currentValue <= 0) {
				setMembershipPurchasePrice('');
				createSpaceAddMembershipForm.setFieldValue('price', '');
			}
		}
	}

	const handlePurchasePriceNegativeAndZeroInput = (e) => {
		// Get the current input value
		const inputValue = e.target.value;
		// This regex validates input values to allow positive whole numbers and floating-point numbers
		// with up to two decimal places, but it does not allow zero.
		// Explanation:
		// ^                    : Start of the string
		// [1-9]\d*             : Matches positive whole numbers excluding leading zeros and zero itself
		// (\.\d{0,2})?         : Optionally matches a decimal point followed by zero, one, or two digits
		// $                    : End of the string
		const isValidNotAllowZeroNumber = /^[1-9]\d*(\.\d{0,2})?$/.test(inputValue);
		if (selectedPricingType === 'one-time'){
			if (isValidNotAllowZeroNumber) {
				setMembershipPurchasePrice(inputValue);
				createSpaceAddMembershipForm.setFieldValue('price', inputValue);
			} else {
				setMembershipPurchasePrice('');
				createSpaceAddMembershipForm.setFieldValue('price', '');
			}
		} else {
			setMembershipPurchasePrice(inputValue);
			createSpaceAddMembershipForm.setFieldValue('price', inputValue);
		}
		handleMembershipFormOnBlur().then(() => {});
	}

	const preventMonthlyPriceNegativeInput = (e) => {
		// Get the current input value
		const inputValue = e.target.value + e.key;
		if (['Backspace', 'Delete'].includes(e.key)) {
			return;
		}
		// This regex validates input values to allow positive whole numbers and floating-point numbers
		// with up to two decimal places, but it does not allow zero.
		// Explanation:
		// ^                    : Start of the string
		// [1-9]\d*             : Matches positive whole numbers excluding leading zeros and zero itself
		// (\.\d{0,2})?         : Optionally matches a decimal point followed by zero, one, or two digits
		// $                    : End of the string
		const isValidNotAllowZeroNumber = /^[1-9]\d*(\.\d{0,2})?$/.test(inputValue);
		if (!isValidNotAllowZeroNumber) {
			e.preventDefault();
		}
	}

	const preventSpaceInput = (e) => {
		// Get the current input value
		// const inputValue = e.target.innerText;
		const inputValue = e.target.innerHTML;
		const maxWords = 2400;
		// Count the current number of words
		if (inputValue) {
			const wordCount = inputValue.split(/\s+/).filter(word => word.length > 0).length;
			// console.log(e.key)
			// console.log(wordCount)
			// Prevent specific keys and space if word count is 2400 or more
			if (wordCount > maxWords && e.key !== 'Backspace') {
				e.preventDefault();
			}
		}
	};

	// const preventSpaceInput = (e) => {
	// 	// Get the current input value
	// 	const inputValue = e.target.innerText;
	// 	const maxWords = 2400;
	// 	// Count the current number of words
	// 	// console.log(inputValue)
	// 	if (inputValue) {
	// 		const wordCount = inputValue.split(/\s+/).filter(word => word.length > 0).length;
	// 		// console.log(e.key)
	// 		// console.log(wordCount)
	// 		// Prevent specific keys and space if word count is 2400 or more
	// 		if (e.key === ' ' && wordCount >= maxWords) {
	// 			e.preventDefault();
	// 		} else {
	// 			// Proceed with handling description change
	// 			const value = inputValue; // Adjust to ensure correct value source
	// 			if (value === '<p><br></p>') {
	// 				// Allow clearing the editor
	// 				createSpaceAddMembershipForm.setFieldValue('description', '');
	// 				setMembershipDescription(null);
	// 			} else {
	// 				if (wordCount <= maxWords) {
	// 					setMembershipDescription(value);
	// 					createSpaceAddMembershipForm.setFieldValue('description', value);
	// 				} else {
	// 					// If word limit is exceeded, truncate and prevent additional input
	// 					const truncatedText = words.slice(0, maxWords).join(' ');
	// 					setMembershipDescription(truncatedText);
	// 					createSpaceAddMembershipForm.setFieldValue('description', truncatedText);
	// 					alert('You have reached the maximum word limit of 2400 words.');
	// 				}
	// 			}
	// 		}
	// 	}
	//
	// 	handleFormMembershipChanges().then(r => {});
	// };

	const preventIncludedMonthsInvalidInput = (e) => {
		// Get the current input value
		const inputValue = e.target.value + e.key;
		if (['Backspace', 'Delete'].includes(e.key)) {
			return;
		}
		// This regex validates input values to allow numbers from 1 to 23 inclusive,
		// but it does not allow zero or numbers outside this range.
		// ^          : Asserts the start of the string
		// 1?[1-9]    : Matches numbers 1-9 and 10-19
		//    1?      : Matches an optional '1' (for numbers 1-19)
		//    [1-9]   : Matches digits 1 through 9 (for 1-9 and 11-19)
		// 2[0-3]     : Matches numbers 20-23
		//    2       : Matches the digit '2'
		//    [0-3]   : Matches digits 0 through 3
		// $          : Asserts the end of the string
		const isValidNotAllowZeroNumber = /^(1?[1-9]|2[0-3])$/.test(inputValue);
		if (!isValidNotAllowZeroNumber) {
			e.preventDefault();
		}
	}

	const handleAccountLimitChange = (e) => {
		const value = e.target.value;
		if (!isNaN(value) && value >= 0) {
			setMembershipAccountLimit(value);
		}
	};

	const handleDescriptionChange = (content, delta, source, editor) => {
		const maxWords = 2400;
		const innerText = editor.getText(); // Get plain text from the editor
		// const words = innerText.split(/\s+/).filter(word => word.length > 0);
		const words = content.split(/\s+/).filter(word => word.length > 0);
		const wordCount = words.length;
		// console.log(content);
		// console.log(innerText);
		// console.log(wordCount);
		if ('<p><br></p>' === content) {
			// Allow clearing the editor
			createSpaceAddMembershipForm.setFieldValue('description', '');
			setMembershipDescription(null);
		} else {
			if (wordCount <= maxWords) {
				setMembershipDescription(content);
				createSpaceAddMembershipForm.setFieldValue('description', content);
			}
			// else {
			// 	// If word limit is exceeded, truncate and prevent additional input
			// 	// const truncatedText = content.slice(0, maxWords).join(' ');
			// 	const truncatedWords = content.slice(0, maxWords);
			// 	const truncatedText = truncatedWords.join(' ');
			// 	const truncatedHTML = truncatedText.replace(/\n/g, '<br>'); // Convert newlines to HTML breaks
			// 	setMembershipDescription(truncatedHTML);
			// 	createSpaceAddMembershipForm.setFieldValue('description', truncatedHTML);
			// }
		}
		handleFormMembershipChanges().then(r => {});
	};

	const onChangeEnableExpiration = (e) => {
		setExpirationChecked(e.target.checked);
	};

	const onChangeEnableNumericCount = (e) => {
		setEnableNumericCount(e.target.checked);
	};

	const tooltipFocused = (input) => {
		if (input === 'contract_address') {
			contractAddressRef.current.focus();
		}
		if (input === 'name') {
			membershipNameRef.current.focus();
		}
		if (input === 'listing_text') {
			membershipListingTextRef.current.focus();
		}
		if (input === 'summary') {
			membershipSummaryRef.current.focus();
		}
		if (input === 'sku_shortcode') {
			membershipSKUShortcodeRef.current.focus();
		}
		if (input === 'max_per_account') {
			membershipMaxPerAccountRef.current.focus();
		}
	};

	// const setFormSpaceData = async (createdSpace) => {
	// 	const spaceMemberships = createdSpace.memberships;
	// 	if (createdSpace && spaceMemberships && spaceMemberships.length > 0) {
	// 		const firstMembership = spaceMemberships[0];
	//
	// 		if (firstMembership) {
	// 			setMembershipType(firstMembership.type);
	// 			// createSpaceCollectionDetailsForm.setFieldsValue({
	// 			// 	contract_address: firstMembership.collection_contract_address
	// 			// });
	// 			// setContractAddress(firstMembership.collection_contract_address);
	// 			// if (
	// 			// 	firstMembership.type === 'nft_collection' &&
	// 			// 	firstMembership.collection_contract_address
	// 			// ) {
	// 			// 	await getCollections(firstMembership.collection_contract_address, true);
	// 			// }
	//
	// 			if (firstMembership.explicit_content) {
	// 				setSelectedExplicitContent(JSON.parse(firstMembership.explicit_content));
	// 			}
	//
	// 			if (firstMembership.explicit_access) {
	// 				setSelectedExplicitAccess(JSON.parse(firstMembership.explicit_access));
	// 			}
	// 		}
	// 	}
	// };

	const setFormSpaceData = useCallback(async (createdSpace) => {
			const spaceMemberships = createdSpace.memberships;
			if (createdSpace && spaceMemberships && spaceMemberships.length > 0) {
				const firstMembership = spaceMemberships[0];
				const firstBenefit = firstMembership.benefits[0];

				let description = firstMembership.description;
				if ('<p><br></p>' === description) {
					setMembershipDescription(null);
					description = null;
				} else {
					setMembershipDescription(description);
				}
				let purchasePrice = '0.00';
				if (firstMembership?.pricing_type === 'one-time') {
					purchasePrice = firstMembership.price && firstMembership.price > 0 ? parseInt(firstMembership.price, 10) : '';
				} else if (firstMembership?.pricing_type === 'recurring') {
					purchasePrice = firstMembership.price && firstMembership.price >= 0 ? parseInt(firstMembership.price, 10) : '';
				}
				setMembershipID(firstMembership.id);
				setMembershipType(firstMembership.type);
				createSpaceAddMembershipForm.setFieldsValue({
					name: firstMembership.name,
					listing_text: firstMembership.listing_text,
					summary: firstMembership.summary,
					sku_shortcode: firstMembership.sku_shortcode,
					individual_title: firstMembership.individual_title,
					description: description,
					// price: firstMembership.benefits.length > 0 ? firstMembership.benefits[0].price : null,
					price: purchasePrice,
					max_supply: firstMembership.max_supply,
					max_per_account: firstMembership.max_per_account,
					royalty_percentage: firstMembership.royalty_percentage,
					included_months: firstBenefit?.included_months ?? 1
				});
				setMembershipName(firstMembership.name);
				setMembershipListingText(
					firstMembership.listing_text ? firstMembership.listing_text : ''
				);
				setMembershipSummary(firstMembership.summary ? firstMembership.summary : '');
				setMembershipSKUShortcode(firstMembership.sku_shortcode);
				setMembershipTotalAvailable(firstMembership.max_supply);
				setExpirationChecked(firstMembership.enable_expiration_and_subscription);
				setEnableNumericCount(firstMembership.enable_add_numeric_count);
				setMembershipIndividualTitling(firstMembership.individual_title);
				setMembershipDescription(firstMembership.description);
				setMembershipAccountLimit(firstMembership.max_per_account);

				setSelectedContentType(firstMembership.content_type ?? 'has_no_explicit');
				setSelectedAccessType(firstMembership.content_access ?? 'has_no_explicit');
				setSelectedExplicitContent(JSON.parse(firstMembership.explicit_content));
				setSelectedExplicitAccess(JSON.parse(firstMembership.explicit_access));
				setSelectedVisibilityType(firstMembership.visibility);
				setSelectedPricingType(firstMembership?.pricing_type ? firstMembership?.pricing_type : 'default');
				setSelectedCurrencyType(firstMembership.currency ?  firstMembership.currency : 'USD');
				setMembershipPurchasePrice(purchasePrice);

				if (firstBenefit) {
					setMembershipIncludedMonths(firstBenefit.included_months ? parseInt(firstBenefit.included_months) : null);
					setMembershipOneMonth(firstBenefit.monthly_price ? parseInt(firstBenefit.monthly_price, 10) : null);
					setMembershipThreeMonths(firstBenefit.three_month_renewal ? parseInt(firstBenefit.three_month_renewal, 10) : null);
					setMembershipSixMonths(firstBenefit.six_month_renewal ? parseInt(firstBenefit.six_month_renewal, 10) : null);
					setMembershipTwelveMonths(firstBenefit.twelve_month_renewal ? parseInt(firstBenefit.twelve_month_renewal, 10) : null);
					setBenefitID(firstBenefit.id);
				}
				// setMembershipPurchasePassword(firstMembership.password);

				if (firstMembership.photo) {
					if (!photoIsRemoved) {
						setMembershipImageBlobPreview(firstMembership.photo);
					}
				}
				if (firstMembership.video) {
					if (!videoIsRemoved) {
						setMembershipVideoBlobPreview(firstMembership.video);
					}
				}

				await createSpaceAddMembershipForm
					.validateFields()
					.then(async () => {
						setFormHasErrorMembership(false);
					})
					.catch((errors) => {
						console.log(errors)
						setFormHasErrorMembership(true);
					});
			}
		},
		[
			photoIsRemoved,
			videoIsRemoved,
			membershipName,
			membershipSummary,
			membershipListingText,
			membershipDescription,
			membershipSKUShortcode,
			membershipIndividualTitling,
			membershipPurchasePrice,
			membershipTotalAvailable,
			membershipAccountLimit,
			membershipOneMonth,
			membershipThreeMonths,
			membershipSixMonths,
			membershipTwelveMonths,
			membershipPurchasePassword,
			membershipIncludedMonths,
			selectedPricingType
		]
	);


	const onChangePricingType = useCallback(
		async (value) => {
			setSelectedPricingType(value);
			if (value === 'free') {
				setMembershipPurchasePrice('0.00');
				createSpaceAddMembershipForm.setFieldValue('price', '0.00');
			} else {
				setMembershipPurchasePrice('');
				createSpaceAddMembershipForm.setFieldValue('price', '');
				const spaceMemberships = spaceInfo.memberships;
				if (spaceMemberships && spaceMemberships.length > 0) {
					const firstMembership = spaceMemberships[0];
					let purchasePrice = '';
					if (value === 'one-time') {
						purchasePrice = firstMembership.price && firstMembership.price > 0 ? parseInt(firstMembership.price, 10) : '';
					} else if (value === 'recurring') {
						purchasePrice = firstMembership.price && firstMembership.price >= 0 ? parseInt(firstMembership.price, 10) : '';
					}
					setMembershipPurchasePrice(!purchasePrice ? '' : purchasePrice);
					createSpaceAddMembershipForm.setFieldValue('price', purchasePrice);
				}
			}
		},
		[membershipPurchasePrice, spaceInfo]
	);

	const onChangeCurrencyType = useCallback(
		async (value) => {
			setSelectedCurrencyType(value);

			// if (value == 'has_explicit') {
			// 	setModalOpenExplicitContent(true);
			// } else {
			// 	setSelectedExplicitContent([]);
			// }
		},
		[]
	);

	const openContentTypeModal = useCallback(
		async (value) => {
			setSelectedContentType(value);
			if (value === 'has_explicit') {
				setModalOpenExplicitContent(true);
			} else {
				setSelectedExplicitContent([]);
			}
		},
		[selectedExplicitContent]
	);

	const openAccessTypeModal = useCallback(
		async (value) => {
			setSelectedAccessType(value);
			if (value === 'has_explicit') {
				setModalOpenExplicitAccess(true);
			} else {
				setSelectedExplicitAccess([]);
			}
		},
		[selectedExplicitAccess]
	);

	const gotoAddCommunityMembership = useCallback(async () => {
		if (membershipType && membershipType === 'nft_collection') {
			return;
		}

		dispatch(getSpaceInfo(space_username));
		// const url = routes.createSpaceAddCommunityPage();
		const url = routes.createSpaceAddMembershipPage();
		navigate(url.replace(':space_username', space_username));
	}, [membershipType]);

	let timeoutId;
	const shortcodeTimeout = (value) => {
		if (timeoutId) {
			clearTimeout(timeoutId); // Clear the previous timeout if it exists
		}

		timeoutId = setTimeout(async () => {
			const data = {
				shortcode: value
			};
			let available = false;
			if (spaceInfo) {
				data['space_id'] = spaceInfo.id;
			}
			if (membershipID) {
				data['membership_id'] = membershipID;
			}
			const response = await checkMembershipShortCodeAvailability(data);
			if (response && response.result) {
				available = response.availability;
			}

			if (!available) {
				createSpaceAddMembershipForm.setFields([ { name: 'sku_shortcode', errors: ['Shortcode not available.'], }, ]);
			}
		}, 2000);
	};

	// const handleShortCode = (value) => {
	// 	setMembershipSKUShortcode(value);
	// 	shortcodeTimeout(value);
	// }

	useEffect(() => {
		if (membershipType && membershipType === 'community') {
			gotoAddCommunityMembership().then((r) => {});
		}
	}, [membershipType]);

	useEffect(()=>{
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 500);
	},[])

	useEffect(() => {}, [membershipID, membershipImageError]);

	useEffect(()=>{
		setIsGoBack(false);
		if (selectedPricingType === 'recurring' &&
			!membershipName &&
			!membershipListingText &&
			!membershipSummary &&
			!membershipDescription &&
			!membershipSKUShortcode &&
			!membershipIndividualTitling &&
			!membershipAccountLimit &&
			!membershipTotalAvailable &&
			// selectedPricingType == null &&
			// selectedContentType == null &&
			// selectedAccessType == null &&
			// selectedExplicitContent == null &&
			// selectedExplicitAccess == null &&
			// selectedVisibilityType == null &&
			// selectedCurrencyType == null &&
			// enableNumericCount == null &&
			!membershipPurchasePrice &&
			membershipIncludedMonths) {
			setIsGoBack(true);
		}
		if (selectedPricingType === 'free' &&
			!membershipName &&
			!membershipListingText &&
			!membershipSummary  &&
			!membershipDescription &&
			!membershipSKUShortcode &&
			!membershipIndividualTitling &&
			!membershipAccountLimit &&
			!membershipTotalAvailable &&
			// selectedPricingType == null &&
			// selectedContentType == null &&
			// selectedAccessType == null &&
			// selectedExplicitContent == null &&
			// selectedExplicitAccess == null &&
			// selectedVisibilityType == null &&
			// selectedCurrencyType == null &&
			// enableNumericCount == null &&
			(!membershipPurchasePrice  || membershipPurchasePrice === '0.00' || membershipPurchasePrice === 0.00)) {
			setIsGoBack(true);
		}
		if (selectedPricingType === 'one-time' || selectedPricingType === 'default' &&
			!membershipName &&
			!membershipListingText &&
			!membershipSummary &&
			!membershipDescription &&
			!membershipSKUShortcode &&
			!membershipIndividualTitling &&
			!membershipAccountLimit &&
			!membershipTotalAvailable &&
			// selectedPricingType == null &&
			// selectedContentType == null &&
			// selectedAccessType == null &&
			// selectedExplicitContent == null &&
			// selectedExplicitAccess == null &&
			// selectedVisibilityType == null &&
			// selectedCurrencyType == null &&
			// enableNumericCount == null &&
			!membershipPurchasePrice) {
			setIsGoBack(true);
		}

	},[
		membershipName,
		membershipListingText,
		membershipSummary,
		membershipDescription,
		membershipSKUShortcode,
		membershipIndividualTitling,
		membershipAccountLimit,
		membershipTotalAvailable,
		selectedPricingType,
		selectedContentType,
		selectedAccessType,
		selectedExplicitContent,
		selectedExplicitAccess,
		selectedVisibilityType,
		selectedCurrencyType,
		enableNumericCount,
		membershipPurchasePrice,
		membershipOneMonth,
		membershipThreeMonths,
		membershipSixMonths,
		membershipTwelveMonths,
		membershipIncludedMonths,
		membershipPurchasePassword,
	])

	useEffect(() => {
		// console.log(spaceInfoLoading)
		// console.log(spaceInfo)
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				setFormSpaceData(spaceInfo).then((r) => {});
			}
		} else {
			if (!spaceInfoLoading && initialLoad) {
				setInitialLoad(false);
				dispatch(getSpaceInfo(space_username));
			}
			if (spaceInfoLoading === 'failed') {
				navigate(routes.createSpace());
			}
		}

		document.title = 'Commonspace Spaces';
	}, [authUserInfo, spaceInfo, spaceInfoLoading, space_username, initialLoad]);

	useEffect(()=>{
	},[
		membershipOneMonth,
		membershipSixMonths,
		membershipTwelveMonths,
		membershipThreeMonths
	])

	return (
		<>
			{/* <main className="dashboard-wrapper flex add-membership">
				<div className="container !py-20">
					<div className="grid grid-cols-12 md:gap-y-8 md:gap-y-0 md:gap-8">
						<div className="col-span-12 md:col-span-8 flex items-center">
							<h1 className=" header-large">Create Your First Membership</h1>
						</div>
						<div className="col-span-12 md:col-span-4 flex items-enter justify-center md:justify-end">
							<p className="body-txtitalic--large mt-4">Step 3 of 5</p>
						</div>
						<div className="col-span-12 mt-4">
							<h5 className="body-text--reg">
								{getSitewideLink('memberships_create_space_3')} are how people join
								your Space and become a{' '}
								{getSitewideLink('member_create_space_3')}. They can be free or
								paid, and determine which access and benefits a Member gets. You can
								create more than one to offer free, paid, and premium memberships
								which anyone can get with an email and payment method. Members can
								own multiple Memberships, but only 1 can be active at a time. Create
								your first Membership here!
							</h5>
						</div>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
						<div className="md:col-span-8 flex items-center justify-between !order-last md:!order-first">
							<h5 className="header-medium leading-10">Choose a Membership Type</h5>
							<span className="body-text--reg">Required</span>
						</div>
						<div className="md:col-span-4 !order-first md:!order-last flex justify-center items-center">

							{getSitewideLink('view_guide_membership', 'btn btn-lg btn-secondary')}
						</div>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
						<div className="md:col-span-4 flex items-center !order-2 md:!order-1">
							<div
								className={
									'selectable-cards-not-selected ' +
									(membershipType && membershipType === 'nft_collection'
										? 'disabled'
										: '')
								}
								onClick={gotoAddCommunityMembership}
							>
								<div className="cards-bg">
									<p className="text-center">
										<i className="gradient-color-txt fa-light fa-cubes"></i>
									</p>
									<h5 className="gradient-color-txt text-center">
										Community Membership
									</h5>
									<p className="body-text--smallest">
										Create a free or paid membership that can be sold for a
										one-time cost or have a monthly renewable price, be setup as
										Member-bound or tradable, and have many other configurable
										options.
									</p>
									<p className="body-txtitalic--smallest text-center">
										Created with Polygon
									</p>
								</div>
							</div>
						</div>
						<div className="md:col-span-4 flex items-center !order-3 md:!order-3">
							<div className="selectable-cards-selected">
								<div className="cards-bg">
									<p className="text-center">
										<i className="gradient-color-txt fa-brands fa-ethereum"></i>
									</p>
									<h5 className="gradient-color-txt text-center">
										NFT Collection
									</h5>
									<p className="body-text--smallest">
										You can use an existing NFT Collection on Ethereum to create
										a Membership. Any holder will be able to connect their
										wallet and claim a Membership for free. To use an NFT, you
										must authenticate the Creator Wallet. Learn more.
									</p>
									<p className="body-txtitalic--smallest text-center">
										Ethereum Blockchain
									</p>
								</div>
							</div>
						</div>
						<div className="md:col-span-4 flex items-center !order-1 md:!order-3">
							<p className="body-txtitalic--smallest color-reg">
								Memberships allow anyone to join your Space and controls their
								access to Updates, Benefits, Members Areas, and any Apps you
								integrate with, such as Shopify, Discord, Zoom, and more. You can
								also use an existing NFT collection that you’ve created as the basis
								for a Membership.
								<br />
								<br />
								There are additional Membership Types with different features which
								can be added after you have launched your Space.{' '}
								{getSitewideLink('learn_more_memberships')}
							</p>
						</div>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
						<div className="md:col-span-8 flex items-center justify-between !order-last md:!order-first">
							<h5 className="header-medium leading-normal">
								Verify Existing Ethereum NFT Collection
							</h5>
							<span className="body-text--reg">Required</span>
						</div>
						<div className="md:col-span-4 flex items-center !order-first md:!order-last">
							<p className="body-txtitalic--smallest color-reg">
								You can use an existing Ethereum NFT collection as the basis of a
								Membership, allowing Holders to claim and access the Membership as
								long as they own the NFT.
							</p>
						</div>
					</div>

					<Form
						form={createSpaceCollectionDetailsForm}
						name="createSpaceCollectionDetailsForm"
						id="createSpaceCollectionDetailsForm"
						validateMessages={VALIDATE_MESSAGES}
						onFocus={() => {
							setFormIsOnFocusCollection(true);
						}}
						onBlur={() => {
							setFormIsOnFocusCollection(false);
							handleCollectionDetailsFormOnBlur();
						}}
						onChange={handleCollectionDetailsFormOnChange}
						onFinish={handleCollectionDetailsSubmit}
						className={formIsOnFocusCollection ? 'hide-antd-error-messages' : 'antd-error-messages-23'}
					>
						<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
							<>
								{collectionAlert && (
									<div className="md:col-span-8">
										<Alert
											key={collectionAlert.variant}
											variant={collectionAlert.variant}
											className={
												'custom-alert ' +
												(collectionAlert.variant === 'danger'
													? 'custom-alert-danger'
													: 'custom-alert-success')
											}
										>
											{collectionAlert.message}
										</Alert>
									</div>
								)}
							</>
							<div className="md:col-span-8 my-auto !order-last md:!order-first">
								<label className="block text-base font-normal leading-6 text-gray-900 gradient-color-txt mb-2">
									Collection Contract Address*
								</label>
								<div className="relative rounded-none ">
								<Form.Item
										label="Contract Address"
										name="contract_address"
										className="mb-0 hidden-label"
										validateTrigger="onBlur"
										rules={[{ required: true }]}
									>

									<FormFieldStacked
									formType='input'
									type="text"
									placeholder="42 characters starting with an “0x”."
									disabled={submitted}
									></FormFieldStacked>
									</Form.Item>
								</div>

							</div>

							<div className="md:col-span-4 flex items-end !order-first md:!order-last">
								<p className="body-txtitalic--smallest color-reg">
									Specify the Contract Address of the Collection you would like to
									use as the basis of your Membership.
								</p>
							</div>
						</div>

						<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
							<div className="md:col-span-8 !order-last md:!order-first">
								<Form.Item>
									<Button
										className="btn btn-lg btn-secondary btn-submit"
										type="submit"
										disabled={
											submittedCollectionDetails ||
											formHasErrorCollection ||
											collectionContractFound
										}
									>
										{submittedCollectionDetails && (
											<i className="fa-solid fa-spinner fa-spin"></i>
										)}
										{contractAddressDetails
											? 'Collection Details Found'
											: 'Check Collection Contract'}
									</Button>
								</Form.Item>
							</div>
							<div className="md:col-span-4 flex items-center !order-first md:!order-last ">
								<p className="body-txtitalic--smallest color-reg">
									Click here to load the Collection Contract details.
								</p>
							</div>
						</div>
					</Form>

					{contractAddressDetails && (
						<>
							<div className="grid grid-cols-1 md:grid-cols-12 md:gap-y-8 md:gap-y-0 md:gap-8 mt-8">
								<div className="md:col-span-2 !order-2 md:!order-1">
									<h5 className="header-small">Contract Address:</h5>
								</div>
								<div className="md:col-span-6 !order-3 md:!order-2">
									<h5 className="header-small reg-color break-words">{contractAddress}</h5>
								</div>
								<div className="md:col-span-4 flex items-center !order-1 md:!order-3">
									<p className="body-txtitalic--smallest color-reg">
										Please check that this is the correct Collection Contract
										Address and Name (if any).
									</p>
								</div>
							</div>

							<div className="grid grid-cols-1 md:grid-cols-12 md:gap-y-8 md:gap-y-0 md:gap-8 mt-3">
								<div className="md:col-span-2">
									<h5 className="header-small">Collection Name:</h5>
								</div>
								<div className="md:col-span-6">
									<h5 className="header-small reg-color">
										{contractAddressDetails.collection_name}
									</h5>
								</div>
							</div>

							<div className="grid grid-cols-1 md:grid-cols-12 md:gap-y-8 md:gap-y-0 md:gap-8 mt-3">
								<div className="md:col-span-2 flex items-center !order-2 md:!order-1">
									<h5 className="header-small">Overview:</h5>
								</div>
								<div className="md:col-span-6 flex items-center !order-3 md:!order-2">
									<p className="body-text--small">
										{contractAddressDetails.description}
									</p>
								</div>
								<div className="md:col-span-4 flex items-center !order-1 md:!order-3">
									<p className="body-txtitalic--smallest color-reg">
										Please check that this is the correct Collection Overview
										information (if any).
									</p>
								</div>
							</div>

							<div className="grid grid-cols-1 md:grid-cols-12 md:gap-y-8 md:gap-y-0 md:gap-8 mt-3">
								<div className="md:col-span-2">
									<h5 className="header-small">Creator Wallet:</h5>
								</div>
								<div className="md:col-span-6">
									<h5 className="header-small reg-color break-words">
										{contractAddressDetails.creator_wallet_address}
									</h5>
								</div>
							</div>

							<div className="grid grid-cols-1 md:grid-cols-12 md:gap-y-8 md:gap-y-0 md:gap-8 mt-3">
								<div className="md:col-span-2 !order-2 md:!order-1">
									<h5 className="header-small">Wallet Name:</h5>
								</div>
								<div className="md:col-span-6 !order-3 md:!order-2">
									<h5 className="header-small reg-color">
										{contractAddressDetails.wallet_name}
									</h5>
								</div>
								<div className="md:col-span-4 flex items-center !order-1 md:!order-3">
									<p className="body-txtitalic--smallest color-reg">
										Please check that this is the correct Collection Contract
										Creator Wallet Address and Name (if any).
									</p>
								</div>
							</div>

							<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
								<>
									{verifyAlert && (
										<div className="md:col-span-8 mb-3">
											<Alert
												key={verifyAlert.variant}
												variant={verifyAlert.variant}
												className={
													'custom-alert ' +
													(verifyAlert.variant === 'danger'
														? 'custom-alert-danger'
														: 'custom-alert-success')
												}
											>
												{verifyAlert.message}
											</Alert>
										</div>
									)}
								</>
								<div className="md:col-span-8 !order-last md:!order-first">
									<Button
										type="button"
										className="btn btn-lg btn-secondary btn-submit break-all"
										disabled={submittedVerification}
										onClick={verifyWalletAddress}
									>
										{submittedVerification && (
											<i className="fa-solid fa-spinner fa-spin"></i>
										)}
										{contractAddressVerified
											? 'Collection Verified'
											: 'Connect Creator Wallet to Verify & Authorize'}
									</Button>
								</div>
								<div className="md:col-span-4 flex items-center !order-first md:!order-last">
									<p className="body-txtitalic--smallest color-reg">
										Verify and authorized use by connecting the{' '}
										{getSitewideLink('membership_owner_wallet')} for the
										Collection.
									</p>
								</div>
							</div>

							{contractAddressVerified && (
								<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
									<div className="md:col-span-8 flex items-center justify-center !order-last md:!order-first">
										<p className="body-txtitalic--reg text-center gradient-color-txt">
											Confirmed! Your Existing NFT Collection has been
											verified
											<br /> and the relevant metadata has been imported
											below.
										</p>
									</div>
									<div className="md:col-span-4 flex items-center !order-first md:!order-last">
										<p className="body-txtitalic--smallest color-reg">
											Continue to the next step below.
										</p>
									</div>
								</div>
							)}
						</>
					)}

					{contractAddressVerified && (
						<Form
							form={createSpaceAddMembershipForm}
							name="createSpaceAddMembershipForm"
							id="createSpaceAddMembershipForm"
							validateMessages={VALIDATE_MESSAGES}
							onFocus={() => {
								setFormIsOnFocusMembership(true);
							}}
							onBlur={() => {
								setTimeout(() => {
									setFormIsOnFocusMembership(false);
									handleMembershipFormOnBlur();
								}, 200);
							}}
							onChange={handleFormMembershipChanges}
							onFinish={handleSubmit}
							className={formIsOnFocusMembership ? 'hide-antd-error-messages' : 'antd-error-messages-15'}
						>
							<>
								{alert && (
									<div className="grid grid-cols-1 md:grid-cols-12">
										<div className="md:col-span-8">
											<Alert
												key={alert.variant}
												variant={alert.variant}
												className={
													'custom-alert ' +
													(alert.variant === 'danger'
														? 'custom-alert-danger'
														: 'custom-alert-success')
												}
											>
												{alert.message}
											</Alert>
										</div>
									</div>
								)}
							</>
							<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
								<div className="md:col-span-8 !order-last md:!order-last">
									<div className="input-group-com input-border-bottom">
										<label className="text-label body-text--small">Name*</label>
										<Form.Item
											label="Name"
											name="name"
											className="mb-0 hidden-label"
											validateTrigger="onBlur"
											rules={[
												{ required: true },
												{
													max: 32,
													message: 'Must not exceed 32 characters.'
												}
											]}
										>
											<Input
												placeholder="Up to 32 characters, including spaces."
												className="input-lg body-text--small"
												maxLength={32}
												disabled={submitted}
												ref={membershipNameRef}
											/>
										</Form.Item>
									</div>
								</div>
								<div className="md:col-span-4 flex items-end !order-first md:!order-last">
									<p className="body-txtitalic--smallest color-reg">
										This is the displayed Name of the Membership and is what
										people will see when browsing your Space and viewing their
										purchased Membership. This is usually similar to the NFT
										Collection name.
									</p>
								</div>
							</div>

							<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-3">
								<div className="md:col-span-8 !order-last md:!order-last">
									<div className="input-group-com input-border-bottom">
										<label className="text-label body-text--small">
											Listing Text*
										</label>
										<Form.Item
											label="Listing Text"
											name="listing_text"
											className="mb-0 hidden-label"
											validateTrigger="onBlur"
											rules={[
												{ required: true },
												{
													max: 128,
													message: 'Must not exceed 128 characters.'
												}
											]}
										>
											<TextareaAutosize
												placeholder="Up to 128 characters, including spaces."
												className="input-lg body-text--small p-0"
												disabled={submitted}
												maxLength={128}
												ref={membershipListingTextRef}
											/>
										</Form.Item>
									</div>
								</div>
								<div className="md:col-span-4 flex items-end !order-first md:!order-last">
									<p className="body-txtitalic--smallest color-reg">
										The Listing Text is used when listing your Membership on
										your Space for Holders to claim. It should be 1 to 2
										sentences summarizing the Membership and who can claim it.
									</p>
								</div>
							</div>

							<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-3">
								<div className="md:col-span-8 !order-last md:!order-last">
									<div className="input-group-com input-border-bottom">
										<label className="text-label body-text--small">
											Summary*
										</label>
										<Form.Item
											label="Summary"
											name="summary"
											className="mb-0 hidden-label"
											validateTrigger="onBlur"
											rules={[
												{ required: true },
												{
													max: 128,
													message: 'Must not exceed 128 characters.'
												}
											]}
										>
											<TextareaAutosize
												placeholder="Up to 128 characters, including spaces."
												className="input-lg body-text--small p-0"
												disabled={submitted}
												maxLength={128}
												ref={membershipSummaryRef}
											/>
										</Form.Item>
									</div>
								</div>
								<div className="md:col-span-4 flex items-end !order-first md:!order-last">
									<p className="body-txtitalic--smallest color-reg">
										This Summary shows when Members view their Membership. It
										should be 1 to 2 sentences summarizing the Membership and
										its details.
									</p>
								</div>
							</div>

							<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-3">
								<div className="md:col-span-8 !order-last md:!order-last">
									<div className="input-group-com input-border-bottom">
										<label className="text-label body-text--small">
											SKU Shortcode*
										</label>
										<Form.Item
											label="SKU Shortcode"
											name="sku_shortcode"
											className="mb-0 hidden-label"
											validateTrigger="onBlur"
											rules={[
												{ required: true },
												{
													min: 2,
													message: 'Must be minimum 2 characters.'
												},
												{
													max: 8,
													message: 'Must not exceed 8 characters.'
												},
												({ getFieldValue }) => ({
													async validator(_, value) {
														if (value) {
															const data = {
																shortcode: value
															};

															if (spaceInfo) {
																data['space_id'] = spaceInfo.id;
															}

															if (membershipID) {
																data['membership_id'] =
																	membershipID;
															}

															let available = false;
															const response =
																await checkMembershipShortCodeAvailability(
																	data
																);
															if (response && response.result) {
																available = response.availability;
															}

															if (!available) {
																return Promise.reject(
																	new Error(
																		'Shortcode not available.'
																	)
																);
															}
														}

														if (
															(value && value.match(/\ /g)) ||
															/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
																value
															)
														) {
															return Promise.reject(
																new Error('Invalid Input')
															);
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<Input
												placeholder="2 to 8 characters. Letters and numbers only, no spaces."
												className="input-lg body-text--small"
												disabled={submitted}
												maxLength={8}
												ref={membershipSKUShortcodeRef}
											/>
										</Form.Item>
									</div>
								</div>
								<div className="md:col-span-4 flex items-end !order-first md:!order-last">
									<p className="body-txtitalic--smallest color-reg">
										Choose a unique SKU Shortcode based the Membership Name.
										This is must be unique within your Space and is used in
										transactions, support, and other areas.
									</p>
								</div>
							</div>

							<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-3">
								<div className="md:col-span-8 !order-last md:!order-last">
									<div className="input-group-com input-border-bottom">
										<label className="text-label body-text--small">
											Max Per Account*
										</label>
										<Form.Item
											label="Max Per Account"
											name="max_per_account"
											className="mb-0 hidden-label"
											validateTrigger="onBlur"
											rules={[
												{ required: true },
												{ validator: CustomValidators.maxPerCount }
											]}
										>
											<Input
												controls={false}
												placeholder="Enter “0” for unlimited."
												className="input-lg body-text--small"
												disabled={submitted}
												ref={membershipMaxPerAccountRef}
											/>
										</Form.Item>
									</div>
								</div>
								<div className="md:col-span-4 flex items-end !order-first md:!order-last">
									<p className="body-txtitalic--smallest color-reg">
										You can put a limit how many Memberships can be claimed by a
										single account at one time. Enter “0” to set this to
										unlimited.
									</p>
								</div>
							</div>

							<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4">
								<div className="md:hidden">
									<p className="body-txtitalic--smallest color-reg">
										Your Membership Image (up to 8MB) should be as high
										resolution as possible, up to 3840px height and width. This
										will also be used for any thumbnails and previews.
									</p>
								</div>
								<div className="md:col-span-4">
									<div className="upload-rec no-bg">
										<UploadDragger
											passedError={membershipImageError}
											cropperAspectRatio={4 / 3}
											hideDragIcon="true"
											hideText="true"
											uploadFileTypes={IMAGE_FILETYPES.toString()}
											uploadedFilePreview={membershipImageBlobPreview}
											onUploadFileChange={
												handleMembershipImageUploadFileChange
											}
											minHeight={IMAGE_LARGE_MIN_HEIGHT}
											minWidth={IMAGE_LARGE_MIN_WIDTH}
											maxHeight={IMAGE_LARGE_MAX_HEIGHT}
											maxWidth={IMAGE_LARGE_MAX_WIDTH}
											uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
											disableCropper={true}
										/>
										<i className="fa-regular fa-plus"></i>
									</div>
									<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
										Membership Image*
									</p>
									<p className="text-center body-txtitalic--smallest">
										Up to 3840px, square preferred. JPEG, PNG, SVG, or GIF
									</p>
								</div>
								<div className="md:hidden">
									<p className="mt-8 body-txtitalic--smallest color-reg">
										You can add a Membership Video (up to 12MB). This will be
										viewable by Members when viewing their purchased Membership.
										Optional.
									</p>
								</div>
								<div className="md:col-span-4">
									<div className="upload-rec no-bg">
										<UploadDragger
											hideDragIcon="true"
											hideText="true"
											uploadFileTypes={VIDEO_FILETYPES.toString()}
											uploadedFilePreview={membershipVideoBlobPreview}
											onUploadFileChange={
												handleMembershipVideoUploadFileChange
											}
											minHeight={VIDEO_STANDARD_MIN_HEIGHT}
											minWidth={VIDEO_STANDARD_MIN_WIDTH}
											maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
											maxWidth={VIDEO_STANDARD_MAX_WIDTH}
											uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
										/>
										<i className="fa-regular fa-plus"></i>
									</div>
									<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">
										Membership Video
									</p>
									<p className="text-center body-txtitalic--smallest">
										Up to 1920px/1080p, square preferred. MP4 or WEBM.
									</p>
								</div>
								<div className="hidden md:grid md:col-span-4">
									<p className="body-txtitalic--smallest color-reg">
										Your Membership Image (up to 8MB) should be as high
										resolution as possible, up to 3840px height and width. This
										will also be used for any thumbnails and previews.
									</p>
									<p className="mt-8 body-txtitalic--smallest color-reg">
										You can add a Membership Video (up to 12MB). This will be
										viewable by Members when viewing their purchased Membership.
										Optional.
									</p>
								</div>
							</div>

							<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
								<div className="md:col-span-4 items-center !order-2 md:!order-1">
									<DropdownWithItems
										title="Select Content Type"
										customButtonclassName="btn-lg btn-secondary whitespace-pre-wrap"
										displayError={
											displayDropDownError &&
											selectedContentType === 'default'
										}
										icon={getListIcon(
											CONTENT_TYPES_LIST_WITH_DEFAULT,
											selectedContentType
										)}
										selectedValue={selectedContentType}
										listItems={CONTENT_TYPES_LIST_WITH_DEFAULT}
										onDropdownChange={(value) => openContentTypeModal(value)}
									/>
									<ExplicitContent
										modalOpen={modalOpenExplicitContent}
										setModalOpen={setModalOpenExplicitContent}
										selectedItems={selectedExplicitContent}
										isExplicitContent={true}
										onSelectedItems={(value) =>
											setSelectedExplicitContent(value)
										}
									/>
								</div>
								<div className="md:col-span-4 items-center !order-3 md:!order-2">
									<DropdownWithItems
										title="Select Access Type"
										customButtonclassName="btn-lg btn-secondary whitespace-pre-wrap"
										displayError={
											displayDropDownError && selectedAccessType === 'default'
										}
										icon={getListIcon(
											ACCESS_TYPES_LIST_WITH_DEFAULT,
											selectedAccessType
										)}
										selectedValue={selectedAccessType}
										listItems={ACCESS_TYPES_LIST_WITH_DEFAULT}
										onDropdownChange={(value) => openAccessTypeModal(value)}
									/>
									<ExplicitContent
										modalOpen={modalOpenExplicitAccess}
										setModalOpen={setModalOpenExplicitAccess}
										selectedItems={selectedExplicitAccess}
										isExplicitContent={false}
										onSelectedItems={(value) =>
											setSelectedExplicitAccess(value)
										}
									/>
								</div>

								<div className="md:col-span-4 flex items-end !order-1 md:!order-3">
									<p className="body-txtitalic--smallest color-reg">
										Change the Content setting if your Details, Image, or Video
										contains explicit or sensitive content. Change the Access
										setting if your Space will provide access to explicit or
										sensitive content.{' '}
										{getSitewideLink('view_content_guideline_memberships')}.
									</p>
								</div>
							</div>
							<div className="grid grid-cols-1 md:grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-8">
								<div className="col-span-12 md:col-span-8">
									<Form.Item>
										<Button
											className="btn btn-lg btn-primary whitespace-pre-wrap"
											type="submit"
											disabled={submitted || formHasErrorMembership}
										>
											{submitted && (
												<i className="fa-solid fa-spinner fa-spin"></i>
											)}
											Save Membership & Continue
										</Button>
									</Form.Item>
								</div>
								<div className="col-span-12 md:col-span-4">
									<Button
										className="btn btn-lg btn-warning bg-none"
										disabled={submitted || formHasErrorMembership}
										onClick={saveAndGoBack}
									>
										Save & Go Back
									</Button>
								</div>
							</div>
						</Form>
					)}
				</div>
			</main> */}
            <main id="cs-platform-main" className="cs-main main-theme">
                <section id="cs-platform-create-basics-c1" className="p-section-safeview theme-transparent">
                    <div className="centerframe-grid gap-2gutter">
                        <div className="block-full">
                            <div className="cs-stack-heading">
                                <div className="stack-row split">
                                    <h1>Create Your First Membership</h1>
                                    <h3>Step 3 of 5</h3>
                                </div>
                                <p>
                                {getSitewideLink('memberships_create_space_3')} are how people join
								your Space and become a Member
								{/*{getSitewideLink('member_create_space_3')}. */}. They can be free or
								paid, and determine which access and benefits a Member gets. You can
								create more than one to offer free, paid, and premium memberships
								which anyone can get with an email and payment method. Members can
								own multiple Memberships, but only 1 can be active at a time.
                                </p>
                            </div>
                            <div className="cs-stack-form-guided">
                                <div className="formrow title">
                                    <div>
                                        <h2>Choose a Membership Type</h2>
                                        <p>Required</p>
                                    </div>
                                    <a href="https://help.common.space" className="button-secondary-gradient w-full"><span><p>Space Creation Guide <i className="fa-regular fa-arrow-up-right-from-square i-r"></i></p></span></a>
                                    {/* {getSitewideLink('view_guide_membership', 'button-secondary-gradient')} */}
                                </div>
                                <div className="formrow">
                                    <fieldset className="cs-form-card-stack form-radio-set card-stack gradient secondary set-input-hidden" aria-label="Options">
                                        <div className="form-stack-radio-options stack-row-base gap-gutter">
                                            <label htmlFor="block_noradio_stack_gradient_secondary_option_1" className="form-radio-option">
                                                <div onClick={gotoAddCommunityMembership}>
                                                    <span>
                                                        <input id="block_noradio_stack_gradient_secondary_option_1" type="radio" name="set_block_noradio_stack_gradient_secondary_name" value="option_1" className="hidden" checked={true}/>
                                                        <i className="fa-regular fa-users"></i>
                                                        <p className="cs-form-card-stack-title" aria-label="Option_1" aria-description="Option 1 Description">Community</p>
                                                    </span>
                                                    <span>
                                                        <p>Create a customizable membership that can be free, paid, or have a subscription.</p>
                                                    </span>
                                                </div>
                                            </label>
                                            {/*<label htmlFor="block_noradio_stack_gradient_secondary_option_2" className="form-radio-option pointer-events-none hidden">*/}
                                            {/*    <div >*/}
                                            {/*        <span>*/}
                                            {/*            <input id="block_noradio_stack_gradient_secondary_option_2" type="radio" name="set_block_noradio_stack_gradient_secondary_name" value="option_2" className="hidden"/>*/}
                                            {/*            <i className="fa-brands fa-ethereum !text-gray-400"></i>*/}
                                            {/*            <p className="cs-form-card-stack-title !text-gray-400" aria-label="Option_2" aria-description="Option 2 Description">NFT Collection</p>*/}
                                            {/*        </span>*/}
                                            {/*        <span>*/}
                                            {/*            <p>You can use an existing NFT Collection on Ethereum to create a Membership. Any holder will be able to connect their wallet and claim a Membership for free. Authentication required.</p>*/}
                                            {/*        </span>*/}
                                            {/*    </div>*/}
                                            {/*</label>*/}
                                        </div>
                                    </fieldset>
                                    <div className="cs-formrow-helper-split">
										<p>Memberships allow anyone to join your Space and controls their access to Updates, Benefits, Members Areas, and any apps you integrate with, such as Shopify, Discord, Zoom, and more.</p>
										{/*<p>Memberships allow anyone to join your Space and controls their*/}
                                        {/*    access to Updates, Benefits, Members Areas, and any Apps you*/}
                                        {/*    integrate with, such as Shopify, Discord, Zoom, and more.*/}
										{/*	/!*You can*!/*/}
                                        {/*    /!*also use an existing NFT collection that you’ve created as the basis*!/*/}
                                        {/*    /!*for a Membership.*!/*/}
                                        {/*    <br />*/}
                                        {/*    <br />*/}
                                        {/*    There are additional Membership Types with different features which*/}
                                        {/*    can be added after you have launched your Space.{' '}*/}
                                        {/*    {getSitewideLink('learn_more_memberships')}</p>*/}
                                    </div>
                                </div>
								<div className="formrow title">
									<div>
										<h3>Membership Product Details</h3>
									</div>
									<div>
										<p>Membership Details can be changed but some changes may only affect to new sales.</p>
									</div>
								</div>

								<Form
									form={createSpaceAddMembershipForm}
									name="createSpaceAddMembershipForm"
									id="createSpaceAddMembershipForm"
									validateMessages={VALIDATE_MESSAGES}
									onFocus={() => {
										setFormIsOnFocusMembership(true);
									}}
									onBlur={() => {
										setTimeout(() => {
											setFormIsOnFocusMembership(false);
											handleMembershipFormOnBlur().then(r => {});
										}, 200);
									}}
									onChange={handleFormMembershipChanges}
									onFinish={handleSubmit}
									onFinishFailed={handleFinishFailed}
									className='cs-stack-form-guided'
								>
									<div className="formrow media">
										<div className="cs-stack-form-media stack-row">
											<div className="cs-stack-form-media-item">
												<UploadDraggerV2
													onError={setMembershipImageError}
													cropperAspectRatio={4 / 3}
													shape="square"
													hideDragIcon="true"
													hideText="true"
													uploadFileTypes={IMAGE_FILETYPES.toString()}
													uploadedFilePreview={membershipImageBlobPreview}
													onUploadFileChange={
														handleMembershipImageUploadFileChange
													}
													minHeight={IMAGE_LARGE_MIN_HEIGHT}
													minWidth={IMAGE_LARGE_MIN_WIDTH}
													maxHeight={IMAGE_LARGE_MAX_HEIGHT}
													maxWidth={IMAGE_LARGE_MAX_WIDTH}
													uploadSizeLimit={IMAGE_VIDEO_FILE_SIZE_LIMIT}
													// uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
													disableCropper={true}
												/>
												<p className="cs-label">Membership Image</p>
												<p className="cs-helper">Up to 3840px JPEG, PNG, or GIF</p>
												{membershipImageError && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{membershipImageError}</h6>
															</div>
														</div>
													</div>
												)}
											</div>
											<div className="cs-stack-form-media-item">
												<UploadDraggerV2
													onError={setMembershipVideoError}
													hideDragIcon="true"
													hideText="true"
													shape="square"
													uploadFileTypes={VIDEO_FILETYPES.toString()}
													uploadedFilePreview={membershipVideoBlobPreview}
													onUploadFileChange={
														handleMembershipVideoUploadFileChange
													}
													minHeight={VIDEO_STANDARD_MIN_HEIGHT}
													minWidth={VIDEO_STANDARD_MIN_WIDTH}
													maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
													maxWidth={VIDEO_STANDARD_MAX_WIDTH}
													uploadSizeLimit={IMAGE_VIDEO_FILE_SIZE_LIMIT}
													// uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
												/>
												<p className="cs-label">Membership Video</p>
												<p className="cs-helper">Optional up to 1920px/1080p MOV or MP4</p>
												{membershipVideoError && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{membershipVideoError}</h6>
															</div>
														</div>

													</div>
												)}
											</div>
										</div>
										<div className="cs-formrow-helper-split">
											<p>Your Membership Image (up to 4MB) is used on Product Pages and previews. A square format is preferred.</p>
											<p>You can add an optional Membership Video (up to 12MB) which appears in limited pages. Optional.</p>
											{/*<p>Your Membership Image (up to 8MB) should be as high*/}
											{/*	resolution as possible, up to 3840px height and width. This*/}
											{/*	will also be used for any thumbnails and previews.</p>*/}
											{/*<p>You can add a Membership Video (up to 12MB). This will be*/}
											{/*	viewable by Members when viewing their purchased Membership.*/}
											{/*	Optional.</p>*/}
										</div>
									</div>

									<Form.Item
										name="name"
										label="Membership Product Name"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{
												max: 32,
												message: 'Must not exceed 32 characters.'
											}
										]}
									>
										<div className="formrow">
											<div className="form-input air">
												<label htmlFor="text">Membership Product Name</label>
												<input id="name" name="name" type="text"
													   onBlur={()=>handleOnblurField('name')}
													   value={membershipName} maxLength={32} placeholder="Up to 32 characters, including spaces."/>
												{ ((blurredField === 'name' || blurredField === 'all') && createSpaceAddMembershipForm.getFieldsError()[0]?.errors.length > 0) && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{createSpaceAddMembershipForm.getFieldsError()[0]?.errors}</h6>
															</div>
														</div>

													</div>
												)}
											</div>
											<div className="cs-formrow-helper-end">
												<p>This shows on the Membership Product Page and listing which people can purchase.</p>
											</div>
										</div>
									</Form.Item>

									<Form.Item
										name="summary"
										label="Summary"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{
												max: 128,
												message: 'Must not exceed 128 characters.'
											}
										]}
									>
										<div className="formrow">
											<div className="form-textarea air">
												<label htmlFor="text">Summary</label>
												<textarea id="summary" name="summary"
														  onBlur={()=>handleOnblurField('summary')}
														  maxLength={128} value={membershipSummary} placeholder="Up to 128 characters, including spaces." ></textarea>
												{ ((blurredField === 'summary' || blurredField === 'all') && createSpaceAddMembershipForm.getFieldsError()[1]?.errors.length > 0) && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{createSpaceAddMembershipForm.getFieldsError()[1]?.errors}</h6>
															</div>
														</div>

													</div>
												)}
											</div>
											<div className="cs-formrow-textarea-helper">
												<p>This should be 1 to 2 sentences long and is shown on the Product page and purchased Memberships.</p>
											</div>
										</div>
									</Form.Item>

									<Form.Item
										name="listing_text"
										label="Listing Text"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{
												max: 128,
												message: 'Must not exceed 128 characters.'
											}
										]}
									>
										<div className="formrow">
											<div className="form-textarea air">
												<label htmlFor="text">Listing Text</label>
												<textarea id="listing_text" name="listing_text"
														  onBlur={()=>handleOnblurField('listing_text')}
														  value={membershipListingText} maxLength={128}
														  placeholder="Up to 128 characters, including spaces." ></textarea>
												{ ((blurredField === 'listing_text' || blurredField === 'all') && createSpaceAddMembershipForm.getFieldsError()[2]?.errors.length > 0) && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{createSpaceAddMembershipForm.getFieldsError()[2]?.errors}</h6>
															</div>
														</div>
													</div>
												)}
											</div>
											<div className="cs-formrow-textarea-helper">
												<p>This is marketing text for your Membership, it should be 1 to 2 sentences summarizing and promoting the Membership.</p>
											</div>
										</div>
									</Form.Item>

									<Form.Item
										name="description"
										label="Full Description"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
												{ validator: (_, value) => {
														const tempDiv = document.createElement('div');
														tempDiv.innerHTML = value;
														const innerText = tempDiv.innerText ;
														// console.log(innerText)
														// Count the words
														const wordCount = innerText ? innerText.split(/\s+/).filter(word => word.length > 0).length : 0;
														// console.log(wordCount);
														if (wordCount < 2) {
															return Promise.reject(new Error('Must be minimum 2 words.'));
														} else if (wordCount > 2400) {
															return Promise.reject(new Error('Must not exceed 2400 words.'));
														} return Promise.resolve();
													},
												},
										]}
									>
										<div className="formrow">
											<div className="form-textarea air">
												<label htmlFor="description">Full Description</label>
												{/*<textarea id="description" name="description" rows="7" value={membershipDescription}  required />*/}
												<ReactQuill
													theme="snow"
													modules={{
														toolbar: toolbarOptions
													}}
													value={membershipDescription}
													onBlur={()=>handleOnblurField('description')}
													onKeyDown={preventSpaceInput}
													onChange={handleDescriptionChange}
												/>
												{((blurredField === 'description' || blurredField === 'all') && createSpaceAddMembershipForm.getFieldsError()[3]?.errors.length > 0) && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{createSpaceAddMembershipForm.getFieldsError()[3]?.errors[0]}</h6>
															</div>
														</div>

													</div>
												)}
											</div>
											<div className="cs-formrow-textarea-helper">
												<p>This displays on your Product page and on the details page of a purchased Membership. It should provides the full details about the Membership. You can format and add up to approximately 2,400 words.</p>
											</div>
										</div>
									</Form.Item>

									<Form.Item
										name="sku_shortcode"
										label="Shortcode"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{
												min: 2,
												message: 'Must be minimum 2 characters.'
											},
											{
												max: 8,
												message: 'Must not exceed 8 characters.'
											},
											({ getFieldValue }) => ({
												async validator(_, value) {
													if (value) {
														shortcodeTimeout(value);

														if (value.length > 8 || value.length < 2) {
															return Promise.reject(
																new Error(
																	'Shortcode not available.'
																)
															);
														}
													}

													if (
														(value && value.match(/\ /g)) ||
														/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
															value
														)
													) {
														return Promise.reject(
															new Error('Invalid Input')
														);
													}

													return Promise.resolve();
												}
											})
										]}
									>
										<div className="formrow">
											<div className="form-input air">
												<label htmlFor="text">Shortcode</label>
												<input id="sku_shortcode"
													   name="sku_shortcode"
													   value={membershipSKUShortcode}
													   onBlur={()=>handleOnblurField('sku_shortcode')}
													   onChange={(e)=>setMembershipSKUShortcode(e.target.value)}
													   type="text"
													   placeholder="2 to 8 characters. Letters and numbers only, no spaces."/>
												{ ((blurredField === 'sku_shortcode' || blurredField === 'all') && createSpaceAddMembershipForm.getFieldsError()[4]?.errors.length > 0) && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{createSpaceAddMembershipForm.getFieldsError()[4]?.errors[0]}</h6>
															</div>
														</div>

													</div>
												)}
											</div>
											<div className="cs-formrow-helper-end">
												<p>Choose a unique ID based the Membership Name. This is used in transactions, support, and other areas.</p>
											</div>
										</div>
									</Form.Item>


									<div className="formrow title">
										<div>
											<h3>Individual Membership Details</h3>
										</div>
										<div>
											<p>Set details for individual Memberships when purchased.</p>
										</div>
									</div>

									<Form.Item
										name="individual_title"
										label="Individual Titling"
										validateTrigger="onBlur"
										rules={[
											{required: true},
											{
												max: 32,
												message: 'Must not exceed 32 characters.'
											}
										]}
										// rules={[
										// 	{ required: true },
										// 	{
										// 		min: 2,
										// 		message: 'Must be minimum 2 characters.'
										// 	},
										// 	{
										// 		max: 8,
										// 		message: 'Must not exceed 8 characters.'
										// 	},
										// 	({ getFieldValue }) => ({
										// 		async validator(_, value) {
										// 			if (value) {
										// 				const data = {
										// 					shortcode: value
										// 				};
										//
										// 				if (spaceInfo) {
										// 					data['space_id'] = spaceInfo.id;
										// 				}
										//
										// 				if (membershipID) {
										// 					data['membership_id'] =
										// 						membershipID;
										// 				}
										//
										// 				let available = false;
										// 				const response =
										// 					await checkMembershipShortCodeAvailability(
										// 						data
										// 					);
										// 				if (response && response.result) {
										// 					available = response.availability;
										// 				}
										//
										// 				if (!available && value.length <= 8) {
										// 					return Promise.reject(
										// 						new Error(
										// 							'Shortcode not available.'
										// 						)
										// 					);
										// 				}
										// 			}
										//
										// 			if (
										// 				(value && value.match(/\ /g)) ||
										// 				/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
										// 					value
										// 				)
										// 			) {
										// 				return Promise.reject(
										// 					new Error('Invalid Input')
										// 				);
										// 			}
										//
										// 			return Promise.resolve();
										// 		}
										// 	})
										// ]}
									>
										<div className="formrow">
											<div className="form-input air">
												<label htmlFor="text">Individual Titling</label>
												<input
													id="individual_title"
													name="individual_title"
													type="text"
													maxLength={32}
													placeholder="Up to 32 characters, including spaces."
													onBlur={()=>handleOnblurField('individual_title')}
													value={membershipIndividualTitling}
												/>
												{((blurredField === 'individual_title' || blurredField === 'all') && createSpaceAddMembershipForm.getFieldsError()[5]?.errors.length > 0) && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{createSpaceAddMembershipForm.getFieldsError()[5]?.errors[0]}</h6>
															</div>
														</div>

													</div>
												)}
											</div>
											<div className="cs-formrow-helper-end">
												<p>This is applied to individual Memberships when purchased. It can be the same or a variation of your Membership Product Name.</p>
											</div>
										</div>
									</Form.Item>

									<div className="formrow">
										<div className="form-input-checkbox gradient md rounded row w-full">
											<div className="checkbox">
												{/*<Checkbox*/}
												{/*	className="inline-flex  items-center mr-8 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"*/}
												{/*	checked={enableExpirationChecked}*/}
												{/*	onChange={onChangeEnableExpiration}*/}
												{/*/>*/}
												<input
													id="checkbox-cs-agreement"
													aria-describedby="checkbox-cs-agreement-description"
													name="checkbox-cs-agreement"
													type="checkbox"
													// required
													checked={enableNumericCount}
													onChange={onChangeEnableNumericCount}
												/>
											</div>
											<div>
												<label for="checkbox-cs-agreement" className="sr-only">Add Numeric Count</label>
												<p>Add Numeric Count to the Individual Title of each purchased Membership</p>
											</div>
										</div>
										<div>
											<p>Add a numeric count to each purchased Membership. For example, the first "Club Membership" sold would be “Club Membership #1”, the second “Club Membership #2”, and so on.</p>
										</div>
									</div>

									<div className="formrow title">
										<div>
											<h3>Pricing & Availability</h3>
										</div>
										<div>
											<p>Specify the type, pricing, and supply for this Membership.</p>
										</div>
									</div>

									<div className="formrow">
										{/*DROPDOWN MENU GRADIENT SET - START*/}
										<div className="dropdown-menu-set">
											{/*DROPDOWN MENU GRADIENT BUTTON - START*/}
											<DropdownWithItems
												title="Select Pricing Type"
												customButtonclassName="icon-dropdown-gradient-alt secondary"
												displayError={blurredField === 'all' && (!selectedPricingType || selectedPricingType === 'default')}
												icon="fa-light fa-dollar"
												selectedValue={selectedPricingType}
												listItems={PRICING_TYPES_LIST}
												onDropdownChange={(value) => onChangePricingType(value)}
											/>
											{/*<button type="button" className="icon-dropdown-gradient-alt secondary" aria-expanded="false" aria-haspopup="true" id="dropdown-button-1"><span><i className="fa-light fa-dollar"></i><p>Select Pricing Type</p><i className="fa-solid fa-caret-down"></i></span></button>*/}
											{/*CSJS ID is for DEMO Purposes only.*/}
											{/*DROPDOWN MENU GRADIENT BUTTON - END*/}

											{/*DROPDOWN MENU GRADIENT UL LI - MENU COMPONENT START*/}
											{/*<ul className="dropdown-menu-gradient hide" aria-orientation="vertical" role="listbox" id="dropdown-menu-1">*/}
											{/*	/!*CSJS ID is for DEMO Purposes only.*!/*/}
											{/*	<li role="option" tabindex="-1">Free</li>*/}
											{/*	<li role="option" tabindex="-1">One-Time Purchase</li>*/}
											{/*	<li role="option" tabindex="-1">Recurring Subscription</li>*/}
											{/*</ul>*/}
											{/*DROPDOWN MENU GRADIENT UL LI - MENU COMPONENT END*/}

										</div>
										{/*DROPDOWN MENU GRADIENT SET - END*/}

										<div>
											<p>Memberships can be Free, One-time Purchase, or Recurring Subscription. Any Memberships pricing changes will only apply to future purchases.</p>
										</div>
									</div>

									{/*CSJS Only display Currency Dropdown Row if One-time Purchase or Recurring Subscription option is chosen in previous dropdown.*/}
									<div className="formrow">
										{/*DROPDOWN MENU GRADIENT SET - START*/}
										<div className="dropdown-menu-set">

											{/*DROPDOWN MENU GRADIENT BUTTON - START*/}
											<DropdownWithItems
												title="Select Currency"
												customButtonclassName="icon-dropdown-gradient-alt secondary"
												displayError={displayDropDownError && selectedCurrencyType === 'default'}
												icon="fa-light fa-dollar"
												selectedValue={selectedCurrencyType}
												listItems={SELECT_CURRENCIES}
												onDropdownChange={(value) => onChangeCurrencyType(value)}
											/>
											{/*<button type="button" className="icon-dropdown-gradient-alt secondary" aria-expanded="false" aria-haspopup="true" id="dropdown-button-1"><span><i className="fa-light fa-dollar"></i><p>Select Currency</p><i className="fa-solid fa-caret-down"></i></span></button>*/}
											{/*CSJS ID is for DEMO Purposes only.*/}
											{/*DROPDOWN MENU GRADIENT BUTTON - END*/}

											{/*DROPDOWN MENU GRADIENT UL LI - MENU COMPONENT START*/}
											{/*<ul className="dropdown-menu-gradient hide" aria-orientation="vertical" role="listbox" id="dropdown-menu-1">*/}
											{/*	/!*CSJS ID is for DEMO Purposes only.*!/*/}
											{/*	<li role="option" tabindex="-1">USD - U.S. Dollar</li>*/}
											{/*	<li role="option" tabindex="-1">JPY - Japanese Yen</li>*/}
											{/*</ul>*/}
											{/*DROPDOWN MENU GRADIENT UL LI - MENU COMPONENT END*/}
										</div>
										{/*DROPDOWN MENU GRADIENT SET - END*/}

										<div>
											<p>Select the Currency for your Membership Price. Any purchases in a different currency will be converted based on market pricing at the time of purchase. <a href="link-via-admin" target="_blank">See officially supported major currencies and cryptocurrencies.</a></p>
										</div>
									</div>


									{/*CSJS Only display Purchase Price Form Row if One-time Purchase or Recurring Subscription option is chosen in previous dropdown.*/}
									{/*{selectedPricingType !== 'free' &&*/}
										<Form.Item
											name="price"
											label="Purchase Price"
											validateTrigger="onBlur"
											rules={[
												{required: selectedPricingType !== 'default'},
											]}
										>
											<div className="formrow">
												<div className="form-input air">
													<label htmlFor="text">Purchase Price</label>
													<input
														id="price"
														name="price"
														type="text"
														// required
														disabled={selectedPricingType === 'free'}
														placeholder="Enter numbers and a decimal only, up to 2 decimal places."
														value={membershipPurchasePrice}
														onChange={handlePurchasePriceNegativeAndZeroInput}
														onKeyDown={preventPurchasePriceNegativeAndZeroInput}
														onBlur={(e) => handlePurchasePriceOnBlur(e)}
														className={selectedPricingType === 'free' ? 'disabled-input' : ''}
													/>
													{(blurredField === 'price' || blurredField === 'all') && createSpaceAddMembershipForm.getFieldsError()[6]?.errors.length > 0 && (
														<div className="alert callout warning !w-full items-center">
															<div className="action">
																<i className="fa-solid fa-triangle-exclamation"></i>
															</div>
															<div className="content">
																<div>
																	<h6>{createSpaceAddMembershipForm.getFieldsError()[6]?.errors[0]}</h6>
																</div>
															</div>
														</div>
													)}
												</div>
												<div className="cs-formrow-helper-end">
													<p>Set the upfront purchase price for your Membership. Paid Memberships have a $1 USD/¥100 JPY Minimum.</p>
												</div>
											</div>
										</Form.Item>
									{/*}*/}

									<div className="formrow">
										<div className="cs-stack-form-inputs stack-row">
											<Form.Item
												name="max_supply"
												label="Total Available"
												validateTrigger="onBlur"
												rules={[
													{ required: selectedPricingType !== 'default' },
												]}
											>
												<div className="form-input air">
													<label htmlFor="text">Total Available</label>
													<input
														id="max_supply"
														name="max_supply"
														type="text"
														// required
														onChange={handleTotalAvailableChange}
														placeholder="Enter 0 for unlimited."
														value={membershipTotalAvailable}
														onBlur={()=>handleOnblurField('max_supply')}
														onKeyDown={preventNegativeInput}
													/>
													{(blurredField === 'max_supply' || blurredField === 'all') && createSpaceAddMembershipForm.getFieldsError()[7]?.errors.length > 0 && (
														<div className="alert callout warning !w-full items-center">
															<div className="action">
																<i className="fa-solid fa-triangle-exclamation"></i>
															</div>
															<div className="content">
																<div>
																	<h6>{createSpaceAddMembershipForm.getFieldsError()[7]?.errors[0]}</h6>
																</div>
															</div>
														</div>
													)}
												</div>
											</Form.Item>

											<Form.Item
												name="max_per_account"
												label="Account Limit"
												validateTrigger="onBlur"
												rules={[
													{ required: selectedPricingType !== 'default' },
												]}
											>
												<div className="form-input air">
													<label htmlFor="text">Account Limit</label>
													<input
														id="max_per_account"
														name="max_per_account"
														type="text"
														placeholder="1 is recommended."
														value={membershipAccountLimit}
														onBlur={()=>handleOnblurField('max_per_account')}
														onChange={handleAccountLimitChange}
														onKeyDown={preventNegativeInput}
													/>
													{(blurredField === 'max_per_account' || blurredField === 'all') && createSpaceAddMembershipForm.getFieldsError()[8]?.errors.length > 0 && (
														<div className="alert callout warning !w-full items-center">
															<div className="action">
																<i className="fa-solid fa-triangle-exclamation"></i>
															</div>
															<div className="content">
																<div>
																	<h6>{createSpaceAddMembershipForm.getFieldsError()[8]?.errors[0]}</h6>
																</div>
															</div>
														</div>
													)}
												</div>
											</Form.Item>
										</div>
										<div className="cs-formrow-helper-split">
											<p>Enter “0” to offer unlimited availability for this Membership or any number to limit availability.</p>
											<p>Enter any number to set a limit on how many of this Membership can be purchased by a single account. Enter “0” for unlimited.</p>
										</div>
									</div>

									{/*CSJS Only display Subscription Details Title and Renewal Form Row if Recurring Subscription option is chosen in earlier dropdown.*/}

									{(selectedPricingType === 'recurring') &&
										<>
											<div className="formrow title">
												<div>
													<h3>Subscription Details</h3>
												</div>
												<div>
													<p>Configure your Subscription Pricing and Included Months here.</p>
												</div>
											</div>

											<Form.Item
												name="included_months"
												label="Months included with Purchase Price"
												validateTrigger="onBlur"
												rules={
													[
														{ required: true, message: 'Months included with Purchase Price is required.' },
														// {
														// 	type: 'number',
														// 	min: 1, max: 23,
														// 	message: 'Please enter a number between 1 and 23.'
														// },
													]}
											>
												<div className="formrow">
													<div className="form-input air">
														<label htmlFor="text">Months included with Purchase Price</label>
														<input
															id="included_months"
															name="included_months"
															type="text"
															placeholder="Enter 1 or more."
															value={membershipIncludedMonths}
															onBlur={()=>handleOnblurField('included_months')}
															onKeyDown={preventIncludedMonthsInvalidInput}
														/>
														{(blurredField === 'included_months' || blurredField === 'all') && createSpaceAddMembershipForm.getFieldsError()[9]?.errors.length > 0 && (
															<div className="alert callout warning !w-full items-center">
																<div className="action">
																	<i className="fa-solid fa-triangle-exclamation"></i>
																</div>
																<div className="content">
																	<div>
																		<h6>{createSpaceAddMembershipForm.getFieldsError()[9]?.errors[0]}</h6>
																	</div>
																</div>

															</div>
														)}
													</div>
													<div className="cs-formrow-helper-end">
														<p>Membership with Subscriptions must include at least 1 month included, after which it must be renewed.</p>
													</div>
												</div>
											</Form.Item>


											<div className="formrow">
												<div className="cs-stack-form-inputs stack-row cs-stack-form-input-quarters">
													<div className="stack-row">
														<Form.Item
															name="one_month_renewal"
															label="1 Month Renewal Price"
															validateTrigger="onBlur"
														>
															<div className="form-input air">
																<label htmlFor="text">1 Month Renewal Price</label>
																<input
																	id="one_month_renewal"
																	name="one_month_renewal"
																	type="text"
																	onChange={(e)=>handleMonthlyChange(e.target.value, RenewalOptions.monthly)}
																	placeholder="Enter numbers and a decimal only, up to 2 decimal places."
																	value={membershipOneMonth}
																	onKeyDown={preventMonthlyPriceNegativeInput}
																	onBlur={(e) => handleMonthlyPriceOnBlur(e, RenewalOptions.monthly)}

																/>
																{/*{createSpaceAddMembershipForm.getFieldsError()[3]?.errors.length > 0 && (*/}
																{/*	<div className="alert callout warning !w-full items-center">*/}
																{/*		<div className="action">*/}
																{/*			<i className="fa-solid fa-triangle-exclamation"></i>*/}
																{/*		</div>*/}
																{/*		<div className="content">*/}
																{/*			<div>*/}
																{/*				<h6>{createSpaceAddMembershipForm.getFieldsError()[3]?.errors[0]}</h6>*/}
																{/*			</div>*/}
																{/*		</div>*/}

																{/*	</div>*/}
																{/*)}*/}
															</div>
														</Form.Item>
														<Form.Item
															name="three_month_renewal"
															label="3 Month Renewal Price"
															validateTrigger="onBlur"
														>
															<div className="form-input air">
																<label htmlFor="text">3 Month Renewal Price</label>
																<input
																	id="three_month_renewal"
																	name="three_month_renewal"
																	type="text"
																	value={membershipThreeMonths}
																	onChange={(e)=>handleMonthlyChange(e.target.value, RenewalOptions.quarterly)}
																	placeholder="Enter numbers and a decimal only, up to 2 decimal places."
																	onKeyDown={preventMonthlyPriceNegativeInput}
																	onBlur={(e) => handleMonthlyPriceOnBlur(e, RenewalOptions.quarterly)}
																/>
																{/*{createSpaceAddMembershipForm.getFieldsError()[3]?.errors.length > 0 && (*/}
																{/*	<div className="alert callout warning !w-full items-center">*/}
																{/*		<div className="action">*/}
																{/*			<i className="fa-solid fa-triangle-exclamation"></i>*/}
																{/*		</div>*/}
																{/*		<div className="content">*/}
																{/*			<div>*/}
																{/*				<h6>{createSpaceAddMembershipForm.getFieldsError()[3]?.errors[0]}</h6>*/}
																{/*			</div>*/}
																{/*		</div>*/}

																{/*	</div>*/}
																{/*)}*/}
															</div>
														</Form.Item>
													</div>
													<div className="stack-row">
														<Form.Item
															name="six_month_renewal"
															label="6 Month Renewal Price"
															validateTrigger="onBlur"
														>
															<div className="form-input air">
																<label htmlFor="text">6 Month Renewal Price</label>
																<input
																	id="six_month_renewal"
																	name="six_month_renewal"
																	type="text"
																	value={membershipSixMonths}
																	onChange={(e)=>handleMonthlyChange(e.target.value, RenewalOptions.biannually)}
																	placeholder="Enter numbers and a decimal only, up to 2 decimal places."
																	onKeyDown={preventMonthlyPriceNegativeInput}
																	onBlur={(e) => handleMonthlyPriceOnBlur(e, RenewalOptions.biannually)}
																/>
																{/*{createSpaceAddMembershipForm.getFieldsError()[3]?.errors.length > 0 && (*/}
																{/*	<div className="alert callout warning !w-full items-center">*/}
																{/*		<div className="action">*/}
																{/*			<i className="fa-solid fa-triangle-exclamation"></i>*/}
																{/*		</div>*/}
																{/*		<div className="content">*/}
																{/*			<div>*/}
																{/*				<h6>{createSpaceAddMembershipForm.getFieldsError()[3]?.errors[0]}</h6>*/}
																{/*			</div>*/}
																{/*		</div>*/}

																{/*	</div>*/}
																{/*)}*/}
															</div>
														</Form.Item>
														<Form.Item
															name="twelve_month_renewal"
															label="12 Month Renewal Price"
															validateTrigger="onBlur"
														>
															<div className="form-input air">
																<label htmlFor="text">12 Month Renewal Price</label>
																<input
																	id="twelve_month_renewal"
																	name="twelve_month_renewal"
																	type="text"
																	onChange={(e)=>handleMonthlyChange(e.target.value, RenewalOptions.annually)}
																	placeholder="Enter numbers and a decimal only, up to 2 decimal places."
																	value={membershipTwelveMonths}
																	onKeyDown={preventMonthlyPriceNegativeInput}
																	onBlur={(e) => handleMonthlyPriceOnBlur(e, RenewalOptions.annually)}
																/>
																{/*{createSpaceAddMembershipForm.getFieldsError()[3]?.errors.length > 0 && (*/}
																{/*	<div className="alert callout warning !w-full items-center">*/}
																{/*		<div className="action">*/}
																{/*			<i className="fa-solid fa-triangle-exclamation"></i>*/}
																{/*		</div>*/}
																{/*		<div className="content">*/}
																{/*			<div>*/}
																{/*				<h6>{createSpaceAddMembershipForm.getFieldsError()[3]?.errors[0]}</h6>*/}
																{/*			</div>*/}
																{/*		</div>*/}

																{/*	</div>*/}
																{/*)}*/}
															</div>
														</Form.Item>
													</div>
												</div>
												<div className="cs-formrow-helper-end">
													<p>Set your Renewal Pricing for one or more time options. To hide an option, leave it blank.</p>
												</div>
											</div>
										</>
									}


									<div className="formrow title">
										<div>
											<h3>Visibility & Content</h3>
										</div>
										<div>
											<p>Set Visibility. Content, and Linked Access. </p>
										</div>
									</div>

									<div className="formrow">
										{/*DROPDOWN MENU GRADIENT SET - START*/}
										<div className="dropdown-menu-set">

											<DropdownWithItems
												title="Update Visibility"
												displayError={!selectedVisibilityType || selectedVisibilityType === 'default'}
												dropDownClass="dropdown-bg"
												customButtonClass="icon-dropdown-gradient-alt secondary"
												selectedValue={selectedVisibilityType}
												listItems={UPDATES_VISIBILITY}
												icon="fa-light fa-eye"
												// icon={getListIcon(
												// 	UPDATES_VISIBILITY,
												// 	selectedVisibilityType
												// )}
												onDropdownChange={(value) => setSelectedVisibilityType(value)}
											/>
											{/*<DropdownWithItems*/}
											{/*	title="No Sensitive Content"*/}
											{/*	customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"*/}
											{/*	displayError={*/}
											{/*		displayDropDownError && selectedContentType === 'default'*/}
											{/*	}*/}
											{/*	icon={getListIcon(*/}
											{/*		CONTENT_TYPES_LIST_WITH_DEFAULT,*/}
											{/*		selectedContentType*/}
											{/*	)}*/}
											{/*	selectedValue={selectedContentType}*/}
											{/*	listItems={CONTENT_TYPES_LIST_WITH_DEFAULT}*/}
											{/*	onDropdownChange={(value) => openContentTypeModal(value)}*/}
											{/*/>*/}

											{/*DROPDOWN MENU GRADIENT BUTTON - START*/}
											{/*<button type="button" className="icon-dropdown-gradient-alt secondary" aria-expanded="false" aria-haspopup="true" id="dropdown-button-5"><span><i className="fa-light fa-eye"></i><p>Public</p><i className="fa-solid fa-caret-down"></i></span></button>*/}
											{/*CSJS ID is for DEMO Purposes only.*/}
											{/*DROPDOWN MENU GRADIENT BUTTON - END*/}

											{/*DROPDOWN MENU GRADIENT UL LI - MENU COMPONENT START*/}
											{/*<ul className="dropdown-menu-gradient hide" aria-orientation="vertical" role="listbox" id="dropdown-menu-5">*/}
											{/*	/!*CSJS ID is for DEMO Purposes only.*!/*/}
											{/*	<li role="option" tabindex="-1">Public</li>*/}
											{/*	<li role="option" tabindex="-1">Members Only</li>*/}
											{/*	<li role="option" tabindex="-1">Password Required</li>*/}
											{/*	<li role="option" tabindex="-1">Hidden</li>*/}
											{/*	<li role="option" tabindex="-1">Custom</li>*/}
											{/*</ul>*/}
											{/*DROPDOWN MENU GRADIENT UL LI - MENU COMPONENT END*/}

										</div>
										{/*DROPDOWN MENU GRADIENT SET - END*/}

										<div>
											<p>Memberships can be visible to Public, Members Only (any members), Password Required, Hidden, or Custom Access (based on owning a specific Membership/Item).</p>
										</div>
									</div>

									{/*CSJS Only display Password Form Row if Password Required option is chosen in previous dropdown.*/}
									{selectedVisibilityType === 'password' &&
										<Form.Item
											name="password"
											label="Choose Password"
											validateTrigger="onBlur"
										>
											<div className="formrow">
												<div className="form-input air">
													<label htmlFor="text">Choose Password</label>
													<input
														id="password"
														name="password"
														type="password"
														placeholder="1+ letters, numbers, and symbols."
														value={membershipPurchasePassword}
													/>
													{/*{createSpaceAddMembershipForm.getFieldsError()[3]?.errors.length > 0 && (*/}
													{/*	<div className="alert callout warning !w-full items-center">*/}
													{/*		<div className="action">*/}
													{/*			<i className="fa-solid fa-triangle-exclamation"></i>*/}
													{/*		</div>*/}
													{/*		<div className="content">*/}
													{/*			<div>*/}
													{/*				<h6>{createSpaceAddMembershipForm.getFieldsError()[3]?.errors[0]}</h6>*/}
													{/*			</div>*/}
													{/*		</div>*/}

													{/*	</div>*/}
													{/*)}*/}
												</div>
												<div className="cs-formrow-helper-end">
													<p>Choose a case-sensitive password required to purchase this Membership.</p>
												</div>
											</div>
										</Form.Item>
									}

									<div className="formrow">
										<div className="cs-stack-form-inputs stack-row">
											<div className="cs-stack-form-buttons">
												<DropdownWithItems
													title="No Sensitive Content"
													displayError={!selectedContentType || selectedContentType === 'default'}
													customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
													icon={getListIcon(
														CONTENT_TYPES_LIST_WITH_DEFAULT,
														selectedContentType
													)}
													selectedValue={selectedContentType}
													listItems={CONTENT_TYPES_LIST_WITH_DEFAULT}
													onDropdownChange={(value) => openContentTypeModal(value)}
												/>
												<ExplicitContent
													modalOpen={modalOpenExplicitContent}
													setModalOpen={setModalOpenExplicitContent}
													selectedItems={selectedExplicitContent}
													isExplicitContent={true}
													onSelectedItems={(value) => setSelectedExplicitContent(value)}
												/>
											</div>

											{/*/!*DROPDOWN MENU GRADIENT SET - START*!/*/}
											{/*<div className="dropdown-menu-set">*/}
											{/*	/!*DROPDOWN MENU GRADIENT BUTTON - START*!/*/}
											{/*	<button type="button" className="icon-dropdown-gradient-alt secondary" aria-expanded="false" aria-haspopup="true" id="dropdown-button-3"><span><i className="fa-light fa-photo-film-music"></i><p>No Sensitive Content</p><i className="fa-solid fa-caret-down"></i></span></button>*/}
											{/*	/!*CSJS ID is for DEMO Purposes only.*!/*/}
											{/*	/!*DROPDOWN MENU GRADIENT BUTTON - END*!/*/}
											{/*	/!*DROPDOWN MENU GRADIENT UL LI - MENU COMPONENT START*!/*/}
											{/*	<ul className="dropdown-menu-gradient hide" aria-orientation="vertical" role="listbox" id="dropdown-menu-3">*/}
											{/*		/!*CSJS ID is for DEMO Purposes only.*!/*/}
											{/*		<li role="option" tabindex="-1">Value</li>*/}
											{/*		<li role="option" tabindex="-1">Value</li>*/}
											{/*		<li role="option" tabindex="-1">Value</li>*/}
											{/*		<li role="option" tabindex="-1">Value</li>*/}
											{/*		<li role="option" tabindex="-1">Value</li>*/}
											{/*	</ul>*/}
											{/*	/!*DROPDOWN MENU GRADIENT UL LI - MENU COMPONENT END*!/*/}
											{/*</div>*/}
											{/*DROPDOWN MENU GRADIENT SET - END*/}

											{/*DROPDOWN MENU GRADIENT SET - START*/}
											<div className="dropdown-menu-set">
												{/*DROPDOWN MENU GRADIENT BUTTON - START*/}
												<DropdownWithItems
													title="No Sensitive Access"
													customButtonclassName="icon-dropdown-gradient-alt secondary active"
													displayError={!selectedAccessType || selectedAccessType === 'default'}
													icon={getListIcon(
														ACCESS_TYPES_LIST_WITH_DEFAULT,
														selectedAccessType
													)}
													selectedValue={selectedAccessType}
													listItems={ACCESS_TYPES_LIST_WITH_DEFAULT}
													onDropdownChange={(value) => openAccessTypeModal(value)}
												/>
												{/*<button type="button" className="icon-dropdown-gradient-alt secondary active" aria-expanded="false" aria-haspopup="true" id="dropdown-button-4"><span><i className="fa-light fa-globe-pointer"></i><p>No Sensitive Access</p><i className="fa-solid fa-caret-down"></i></span></button>*/}
												{/*CSJS ID is for DEMO Purposes only.*/}
												{/*DROPDOWN MENU GRADIENT BUTTON - END*/}
												{/*DROPDOWN MENU GRADIENT UL LI - MENU COMPONENT START*/}
												<ExplicitContent
													modalOpen={modalOpenExplicitAccess}
													setModalOpen={setModalOpenExplicitAccess}
													selectedItems={selectedExplicitAccess}
													isExplicitContent={false}
													onSelectedItems={(value) => setSelectedExplicitAccess(value)}
												/>
												{/*DROPDOWN MENU GRADIENT UL LI - MENU COMPONENT END*/}
											</div>
											{/*DROPDOWN MENU GRADIENT SET - END*/}

										</div>
										<div className="cs-formrow-helper-end">
											<p>Specify if your Membership contains or provides access to sensitive content. {getSitewideLink('view_hosted_content_guideline_memberships', 'gradient-color-txt body-txtitalic--reg')}</p>
										</div>
									</div>

									{/*<Form.Item*/}
									{/*	name="max_per_account"*/}
									{/*	label="Max per Account"*/}
									{/*	validateTrigger="onBlur"*/}
									{/*	rules={[*/}
									{/*		{ required: true },*/}
									{/*		{ validator: CustomValidators.maxPerCount }*/}
									{/*	]}*/}
									{/*>*/}

									{/*	<div className="formrow">*/}
									{/*		<div className="form-input air">*/}
									{/*			<label htmlFor="text">Max Per Account</label>*/}
									{/*			<input id="name" value={membershipMaxPerAccount} name="name" type="number" required placeholder="Enter “0” for unlimited."/>*/}
									{/*			{createSpaceAddMembershipForm.getFieldsError()[4]?.errors.length > 0 && (*/}
									{/*				<div className="alert callout warning !w-full items-center">*/}
									{/*					<div className="action">*/}
									{/*						<i className="fa-solid fa-triangle-exclamation"></i>*/}
									{/*					</div>*/}
									{/*					<div className="content">*/}
									{/*						<div>*/}
									{/*							<h6>{createSpaceAddMembershipForm.getFieldsError()[4]?.errors}</h6>*/}
									{/*						</div>*/}
									{/*					</div>*/}

									{/*				</div>*/}
									{/*			)}*/}
									{/*		</div>*/}
									{/*		<div className="cs-formrow-helper-end">*/}
									{/*			<p>You can put a limit how many Memberships can be claimed by a*/}
									{/*				single account at one time. Enter “0” to set this to*/}
									{/*				unlimited.</p>*/}
									{/*		</div>*/}
									{/*	</div>*/}
									{/*</Form.Item>*/}

									{/*<div className="formrow">*/}
									{/*	<div className="cs-stack-form-buttons">*/}
									{/*		<DropdownWithItems*/}
									{/*			title="Select Content Type"*/}
									{/*			customButtonclassName="button gradient secondary distribute w-full"*/}
									{/*			displayError={*/}
									{/*				displayDropDownError && selectedContentType === 'default'*/}
									{/*			}*/}
									{/*			icon={getListIcon(*/}
									{/*				CONTENT_TYPES_LIST_WITH_DEFAULT,*/}
									{/*				selectedContentType*/}
									{/*			)}*/}
									{/*			selectedValue={selectedContentType}*/}
									{/*			listItems={CONTENT_TYPES_LIST_WITH_DEFAULT}*/}
									{/*			onDropdownChange={(value) => openContentTypeModal(value)}*/}
									{/*		/>*/}
									{/*		<ExplicitContent*/}
									{/*			modalOpen={modalOpenExplicitContent}*/}
									{/*			setModalOpen={setModalOpenExplicitContent}*/}
									{/*			selectedItems={selectedExplicitContent}*/}
									{/*			isExplicitContent={true}*/}
									{/*			onSelectedItems={(value) => setSelectedExplicitContent(value)}*/}
									{/*		/>*/}
									{/*	</div>*/}
									{/*	<div>*/}
									{/*		<p>*/}
									{/*			Change the Content setting if your Details, Image, or Video contains explicit or sensitive content.{' '}*/}
									{/*			{getSitewideLink('view_content_guideline_memberships')}.*/}
									{/*		</p>*/}
									{/*	</div>*/}
									{/*</div>*/}
									{/*<div className="formrow">*/}
									{/*	<div className="cs-stack-form-buttons">*/}
									{/*		<DropdownWithItems*/}
									{/*			title="Select Access Type"*/}
									{/*			customButtonclassName="button gradient secondary distribute w-full"*/}
									{/*			displayError={*/}
									{/*				displayDropDownError && selectedAccessType === 'default'*/}
									{/*			}*/}
									{/*			icon={getListIcon(*/}
									{/*				ACCESS_TYPES_LIST_WITH_DEFAULT,*/}
									{/*				selectedAccessType*/}
									{/*			)}*/}
									{/*			selectedValue={selectedAccessType}*/}
									{/*			listItems={ACCESS_TYPES_LIST_WITH_DEFAULT}*/}
									{/*			onDropdownChange={(value) => openAccessTypeModal(value)}*/}
									{/*		/>*/}
									{/*		<ExplicitContent*/}
									{/*			modalOpen={modalOpenExplicitAccess}*/}
									{/*			setModalOpen={setModalOpenExplicitAccess}*/}
									{/*			selectedItems={selectedExplicitAccess}*/}
									{/*			isExplicitContent={false}*/}
									{/*			onSelectedItems={(value) => setSelectedExplicitAccess(value)}*/}
									{/*		/>*/}
									{/*	</div>*/}
									{/*	<div>*/}
									{/*		<p>*/}
									{/*			Change the Access setting if your Membership will provide access to explicit or sensitive content.{' '}*/}
									{/*			{getSitewideLink('view_access_guideline_memberships')}.*/}
									{/*		</p>*/}
									{/*	</div>*/}
									{/*</div>*/}
									<Form.Item>
										<div className="formrow">
											<button
												disabled={submitted}
												type="submit" className="secondary-gradient">
												<span><p>
													{submitted && (
														<i className="fa-solid fa-spinner fa-spin"></i>
													)}
													Save & Continue
												</p></span>
											</button>
											<div className="cs-formrow-helper-none">
												<p></p>
											</div>
										</div>
									</Form.Item>
									<div className="formrow">
										<button disabled={(!isGoBack && (submitted || formHasErrorMembership))}
												onClick={saveAndGoBack}
												className="secondary-gradient"
										>
											<span><p>
												{submitted && (
													<i className="fa-solid fa-spinner fa-spin"></i>
												)}
												{isGoBack ? 'Go Back' : 'Save & Go Back'}
											</p></span>
										</button>
										<div className="cs-formrow-helper-none">
											<p></p>
										</div>
									</div>

									<div className="formrow">
										{alert && (
											<div className="alert callout warning !w-full items-center">
												<div className="action">
													<i className="fa-solid fa-triangle-exclamation"></i>
												</div>
												<div className="content">
													<div>
														<h6>{alert.message}</h6>
													</div>
												</div>
											</div>
										)}
										<div className="cs-formrow-helper-none">
											<p></p>
										</div>
									</div>

								</Form>
                                {/*<Form*/}
                                {/*    form={createSpaceCollectionDetailsForm}*/}
                                {/*    name="createSpaceCollectionDetailsForm"*/}
                                {/*    id="createSpaceCollectionDetailsForm"*/}
                                {/*    validateMessages={VALIDATE_MESSAGES}*/}
                                {/*    onFocus={() => {*/}
                                {/*        setFormIsOnFocusCollection(true);*/}
                                {/*    }}*/}
                                {/*    onBlur={() => {*/}
                                {/*        setFormIsOnFocusCollection(false);*/}
                                {/*        // handleCollectionDetailsFormOnBlur().then(r => {});*/}
                                {/*    }}*/}
                                {/*    onChange={handleCollectionDetailsFormOnChange}*/}
                                {/*    onFinish={handleCollectionDetailsSubmit}*/}
                                {/*    className='cs-stack-form-guided'*/}
                                {/*>*/}
                                {/*    <Form.Item*/}
								{/*		name="contract_address"*/}
                                {/*        label="Contract Address"*/}
								{/*		validateTrigger="onBlur"*/}
								{/*		rules={[{ required: true }]}*/}
								{/*	>*/}
                                {/*        <div className="formrow">*/}
                                {/*            <div className="form-input air">*/}
                                {/*                <label htmlFor="text">Name</label>*/}
                                {/*                <input id="contract_address"  name="contract_address" value={contractAddress} type="text" required placeholder="42 characters starting with an “0x”."/>*/}
                                {/*                {createSpaceCollectionDetailsForm.getFieldsError()[0]?.errors.length > 0 && (*/}
                                {/*                    <div className="alert callout warning !w-full items-center">*/}
                                {/*                        <div className="action">*/}
                                {/*                            <i className="fa-solid fa-triangle-exclamation"></i>*/}
                                {/*                        </div>*/}
                                {/*                        <div className="content">*/}
                                {/*                            <div>*/}
                                {/*                                <h6>{createSpaceCollectionDetailsForm.getFieldsError()[0]?.errors}</h6>                                                                */}
                                {/*                            </div>  */}
                                {/*                        </div>*/}
                                {/*                        */}
                                {/*                    </div>*/}
                                {/*                )}*/}
                                {/*            </div>*/}
                                {/*            <div className="cs-formrow-helper-end">*/}
                                {/*                <p>Specify the Contract Address of the Collection you would like to*/}
                                {/*                use as the basis of your Membership.</p>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
								{/*	</Form.Item>*/}
								{/*	*/}
                                {/*    <div className='formrow'>*/}
                                {/*        <button type="submit" className="primary-gradient" disabled={*/}
                                {/*                submittedCollectionDetails ||*/}
                                {/*                formHasErrorCollection ||*/}
                                {/*                collectionContractFound*/}
                                {/*            }>*/}
                                {/*            <span><p>*/}
                                {/*            {submittedCollectionDetails && (*/}
                                {/*                <i className="fa-solid fa-spinner fa-spin"></i>*/}
                                {/*            )}*/}
                                {/*            {contractAddressDetails*/}
                                {/*                ? 'Collection Details Found'*/}
                                {/*                : 'Check Collection Contract'}*/}
                                {/*            </p></span>*/}
                                {/*        </button>*/}
                                {/*        <div className="cs-formrow-helper-none">*/}
                                {/*            <p>Click here to load the Collection Contract details.</p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</Form>*/}


                                {/*{contractAddressDetails && (*/}
                                {/*    <>*/}
								{/*		<div className="formrow">*/}
								{/*			<div className="cs-stack-form-inputs stack-col cs-stack-datarows">*/}
								{/*				<div className="datarow">*/}
								{/*					<p>Owner</p>*/}
								{/*					<p>{contractAddressDetails.wallet_name}</p>*/}
								{/*				</div>*/}
								{/*				<div className="datarow">*/}
								{/*					<p>Wallet</p>*/}
								{/*					<p>{contractAddressDetails.creator_wallet_address}</p>*/}
								{/*				</div>*/}
								{/*				<div className="datarow">*/}
								{/*					<p>Collection</p>*/}
								{/*					<p>{contractAddressDetails.collection_name}</p>*/}
								{/*				</div>*/}
								{/*				<div className="datarow">*/}
								{/*					<p>Contract</p>*/}
								{/*					<p>{contractAddress}</p>*/}
								{/*				</div>*/}
								{/*				<div className="datarow cs-data-item-text-long">*/}
								{/*					<p>Overview</p>*/}
								{/*					<p>{contractAddressDetails.description}</p>*/}
								{/*				</div>*/}
								{/*			</div>*/}
								{/*			<div className="cs-formrow-helper-start">*/}
								{/*				<p>Please verify that the Owner, Wallet, Collection, and Contract Address are correct.</p>*/}
								{/*			</div>*/}
								{/*		</div>*/}

								{/*		<div className='formrow'>*/}
								{/*			<button type="submit" className="primary-gradient" disabled={submittedVerification}*/}
								{/*				onClick={verifyWalletAddress}>*/}
								{/*				<span><p>*/}
								{/*				{submittedVerification && (*/}
								{/*					<i className="fa-solid fa-spinner fa-spin"></i>*/}
								{/*				)}*/}
								{/*				{contractAddressVerified*/}
								{/*					? 'Collection Verified'*/}
								{/*					: 'Connect Creator Wallet to Verify & Authorize'}*/}
								{/*				</p></span>*/}
								{/*			</button>*/}
								{/*			<div className="cs-formrow-helper-none">*/}
								{/*				<p>Verify and authorized use by connecting the{' '}*/}
								{/*				{getSitewideLink('membership_owner_wallet')} for the*/}
								{/*				Collection.</p>*/}
								{/*			</div>*/}
								{/*		</div>*/}
								{/*		{contractAddressVerified && (*/}
								{/*				<div className="formrow title text-center">*/}
								{/*					<div className='!w-full !block'>*/}
								{/*						<h4>Confirmed! Your Existing NFT Collection has been*/}
								{/*							verified*/}
								{/*							<br /> and the relevant metadata has been imported*/}
								{/*							below.</h4>*/}
								{/*					</div>*/}
								{/*				</div>*/}
								{/*		)}*/}
								{/*		{contractAddressVerified && (*/}
								{/*			<Form*/}
								{/*				form={createSpaceAddMembershipForm}*/}
								{/*				name="createSpaceAddMembershipForm"*/}
								{/*				id="createSpaceAddMembershipForm"*/}
								{/*				validateMessages={VALIDATE_MESSAGES}*/}
								{/*				onFocus={() => {*/}
								{/*					setFormIsOnFocusMembership(true);*/}
								{/*				}}*/}
								{/*				onBlur={() => {*/}
								{/*					setTimeout(() => {*/}
								{/*						setFormIsOnFocusMembership(false);*/}
								{/*						handleMembershipFormOnBlur().then(r => {});*/}
								{/*					}, 200);*/}
								{/*				}}*/}
								{/*				onChange={handleFormMembershipChanges}*/}
								{/*				onFinish={handleSubmit}*/}
								{/*				className='cs-stack-form-guided'*/}
								{/*			>*/}
								{/*					<Form.Item*/}
								{/*					name="name"*/}
								{/*					label="Name"*/}
								{/*					validateTrigger="onBlur"*/}
								{/*					rules={[*/}
								{/*						{ required: true },*/}
								{/*						{*/}
								{/*							max: 32,*/}
								{/*							message: 'Must not exceed 32 characters.'*/}
								{/*						}*/}
								{/*					]}*/}
								{/*				>*/}
								{/*					<div className="formrow">*/}
								{/*						<div className="form-input air">*/}
								{/*							<label htmlFor="text">Name</label>*/}
								{/*							<input id="name" name="name" type="text" value={membershipName} required placeholder="Up to 32 characters, including spaces."/>*/}
								{/*							{createSpaceAddMembershipForm.getFieldsError()[0]?.errors.length > 0 && (*/}
								{/*								<div className="alert callout warning !w-full items-center">*/}
								{/*									<div className="action">*/}
								{/*										<i className="fa-solid fa-triangle-exclamation"></i>*/}
								{/*									</div>*/}
								{/*									<div className="content">*/}
								{/*										<div>*/}
								{/*											<h6>{createSpaceAddMembershipForm.getFieldsError()[0]?.errors}</h6>*/}
								{/*										</div>*/}
								{/*									</div>*/}

								{/*								</div>*/}
								{/*							)}*/}
								{/*						</div>*/}
								{/*						<div className="cs-formrow-helper-end">*/}
								{/*							<p>This is the displayed Name of the Membership and is what*/}
								{/*							people will see when browsing your Space and viewing their*/}
								{/*							purchased Membership. This is usually similar to the NFT*/}
								{/*							Collection name.</p>*/}
								{/*						</div>*/}
								{/*					</div>*/}
								{/*				</Form.Item>*/}
								{/*				<Form.Item*/}
								{/*					name="listing_text"*/}
								{/*					label="Listing Text"*/}
								{/*					validateTrigger="onBlur"*/}
								{/*					rules={[*/}
								{/*						{ required: true },*/}
								{/*						{*/}
								{/*							max: 128,*/}
								{/*							message: 'Must not exceed 128 characters.'*/}
								{/*						}*/}
								{/*					]}*/}
								{/*				>*/}
								{/*					<div className="formrow">*/}
								{/*						<div className="form-textarea air">*/}
								{/*							<label htmlFor="text">Listing Text</label>*/}
								{/*							<textarea id="listing_text" name="listing_text" value={membershipListingText} maxLength={255} placeholder="Up to 255 characters, including spaces." rows="3" required></textarea>*/}
								{/*							{createSpaceAddMembershipForm.getFieldsError()[1]?.errors.length > 0 && (*/}
								{/*								<div className="alert callout warning !w-full items-center">*/}
								{/*									<div className="action">*/}
								{/*										<i className="fa-solid fa-triangle-exclamation"></i>*/}
								{/*									</div>*/}
								{/*									<div className="content">*/}
								{/*										<div>*/}
								{/*											<h6>{createSpaceAddMembershipForm.getFieldsError()[1]?.errors}</h6>*/}
								{/*										</div>*/}
								{/*									</div>*/}

								{/*								</div>*/}
								{/*							)}*/}
								{/*						</div>*/}
								{/*						<div className="cs-formrow-helper-end">*/}
								{/*							<p>The Listing Text is used when listing your Membership on*/}
								{/*								your Space for Holders to claim. It should be 1 to 2*/}
								{/*								sentences summarizing the Membership and who can claim it.</p>*/}
								{/*						</div>*/}
								{/*					</div>*/}
								{/*				</Form.Item>*/}
								{/*				<Form.Item*/}
								{/*				name="summary"*/}
								{/*				label="Summary"*/}
								{/*				validateTrigger="onBlur"*/}
								{/*				rules={[*/}
								{/*					{ required: true },*/}
								{/*					{*/}
								{/*						max: 128,*/}
								{/*						message: 'Must not exceed 128 characters.'*/}
								{/*					}*/}
								{/*				]}*/}
								{/*				>*/}
								{/*				<div className="formrow">*/}
								{/*						<div className="form-textarea air">*/}
								{/*							<label htmlFor="text">Summary</label>*/}
								{/*							<textarea id="summary" name="summary" maxLength={128} value={membershipSummary} placeholder="Up to 128 characters, including spaces." rows="3" required></textarea>*/}
								{/*							{createSpaceAddMembershipForm.getFieldsError()[2]?.errors.length > 0 && (*/}
								{/*								<div className="alert callout warning !w-full items-center">*/}
								{/*									<div className="action">*/}
								{/*										<i className="fa-solid fa-triangle-exclamation"></i>*/}
								{/*									</div>*/}
								{/*									<div className="content">*/}
								{/*										<div>*/}
								{/*											<h6>{createSpaceAddMembershipForm.getFieldsError()[2]?.errors}</h6>*/}
								{/*										</div>*/}
								{/*									</div>*/}

								{/*								</div>*/}
								{/*							)}*/}
								{/*						</div>*/}
								{/*						<div className="cs-formrow-helper-end">*/}
								{/*							<p>This Summary shows when Members view their Membership. It*/}
								{/*							should be 1 to 2 sentences summarizing the Membership and*/}
								{/*							its details.</p>*/}
								{/*						</div>*/}
								{/*					</div>*/}
								{/*			</Form.Item>*/}
								{/*			<Form.Item*/}
								{/*				name="sku_shortcode"*/}
								{/*				label="SKU Shortcode"*/}
								{/*				validateTrigger="onBlur"*/}
								{/*				rules={[*/}
								{/*					{ required: true },*/}
								{/*					{*/}
								{/*						min: 2,*/}
								{/*						message: 'Must be minimum 2 characters.'*/}
								{/*					},*/}
								{/*					{*/}
								{/*						max: 8,*/}
								{/*						message: 'Must not exceed 8 characters.'*/}
								{/*					},*/}
								{/*					({ getFieldValue }) => ({*/}
								{/*						async validator(_, value) {*/}
								{/*							if (value) {*/}
								{/*								const data = {*/}
								{/*									shortcode: value*/}
								{/*								};*/}

								{/*								if (spaceInfo) {*/}
								{/*									data['space_id'] = spaceInfo.id;*/}
								{/*								}*/}

								{/*								if (membershipID) {*/}
								{/*									data['membership_id'] =*/}
								{/*										membershipID;*/}
								{/*								}*/}

								{/*								let available = false;*/}
								{/*								const response =*/}
								{/*									await checkMembershipShortCodeAvailability(*/}
								{/*										data*/}
								{/*									);*/}
								{/*								if (response && response.result) {*/}
								{/*									available = response.availability;*/}
								{/*								}*/}

								{/*								if (!available && value.length <= 8) {*/}
								{/*									return Promise.reject(*/}
								{/*										new Error(*/}
								{/*											'Shortcode not available.'*/}
								{/*										)*/}
								{/*									);*/}
								{/*								}*/}
								{/*							}*/}

								{/*							if (*/}
								{/*								(value && value.match(/\ /g)) ||*/}
								{/*								/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(*/}
								{/*									value*/}
								{/*								)*/}
								{/*							) {*/}
								{/*								return Promise.reject(*/}
								{/*									new Error('Invalid Input')*/}
								{/*								);*/}
								{/*							}*/}

								{/*							return Promise.resolve();*/}
								{/*						}*/}
								{/*					})*/}
								{/*				]}*/}
								{/*			>*/}
								{/*				<div className="formrow">*/}
								{/*					<div className="form-input air">*/}
								{/*						<label htmlFor="text">SKU Shortcode</label>*/}
								{/*						<input id="name" name="name" value={membershipSKUShortcode} type="text" required placeholder="2 to 8 characters. Letters and numbers only, no spaces."/>*/}
								{/*						{createSpaceAddMembershipForm.getFieldsError()[3]?.errors.length > 0 && (*/}
								{/*							<div className="alert callout warning !w-full items-center">*/}
								{/*								<div className="action">*/}
								{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
								{/*								</div>*/}
								{/*								<div className="content">*/}
								{/*									<div>*/}
								{/*										<h6>{createSpaceAddMembershipForm.getFieldsError()[3]?.errors[0]}</h6>*/}
								{/*									</div>*/}
								{/*								</div>*/}

								{/*							</div>*/}
								{/*						)}*/}
								{/*					</div>*/}
								{/*					<div className="cs-formrow-helper-end">*/}
								{/*						<p>Choose a unique SKU Shortcode based the Membership Name.*/}
								{/*						This is must be unique within your Space and is used in*/}
								{/*						transactions, support, and other areas.</p>*/}
								{/*					</div>*/}
								{/*				</div>*/}
								{/*			</Form.Item>*/}
								{/*			<Form.Item*/}
								{/*				name="max_per_account"*/}
								{/*				label="Max per Account"*/}
								{/*				validateTrigger="onBlur"*/}
								{/*				rules={[*/}
								{/*					{ required: true },*/}
								{/*					{ validator: CustomValidators.maxPerCount }*/}
								{/*				]}*/}
								{/*			>*/}

								{/*				<div className="formrow">*/}
								{/*					<div className="form-input air">*/}
								{/*						<label htmlFor="text">Max Per Account</label>*/}
								{/*						<input id="name" value={membershipMaxPerAccount} name="name" type="number" required placeholder="Enter “0” for unlimited."/>*/}
								{/*						{createSpaceAddMembershipForm.getFieldsError()[4]?.errors.length > 0 && (*/}
								{/*							<div className="alert callout warning !w-full items-center">*/}
								{/*								<div className="action">*/}
								{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
								{/*								</div>*/}
								{/*								<div className="content">*/}
								{/*									<div>*/}
								{/*										<h6>{createSpaceAddMembershipForm.getFieldsError()[4]?.errors}</h6>*/}
								{/*									</div>*/}
								{/*								</div>*/}

								{/*							</div>*/}
								{/*						)}*/}
								{/*					</div>*/}
								{/*					<div className="cs-formrow-helper-end">*/}
								{/*						<p>You can put a limit how many Memberships can be claimed by a*/}
								{/*							single account at one time. Enter “0” to set this to*/}
								{/*							unlimited.</p>*/}
								{/*					</div>*/}
								{/*				</div>*/}
								{/*			</Form.Item>*/}
								{/*			<div className="formrow media">*/}
								{/*				<div className="cs-stack-form-media stack-row">*/}
								{/*					<div className="cs-stack-form-media-item">*/}
								{/*						<UploadDraggerV2*/}
								{/*							onError={setMembershipImageError}*/}
								{/*							cropperAspectRatio={4 / 3}*/}
								{/*							shape="video"*/}
								{/*							hideDragIcon="true"*/}
								{/*							hideText="true"*/}
								{/*							uploadFileTypes={IMAGE_FILETYPES.toString()}*/}
								{/*							uploadedFilePreview={membershipImageBlobPreview}*/}
								{/*							onUploadFileChange={*/}
								{/*								handleMembershipImageUploadFileChange*/}
								{/*							}*/}
								{/*							minHeight={IMAGE_LARGE_MIN_HEIGHT}*/}
								{/*							minWidth={IMAGE_LARGE_MIN_WIDTH}*/}
								{/*							maxHeight={IMAGE_LARGE_MAX_HEIGHT}*/}
								{/*							maxWidth={IMAGE_LARGE_MAX_WIDTH}*/}
								{/*							uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}*/}
								{/*							disableCropper={true}*/}
								{/*						/>*/}
								{/*						<p className="cs-label">Membership Image</p>*/}
								{/*						<p className="cs-helper">Up to 1920px/1080p, square preferred. MP4 or WEBM.</p>*/}
								{/*						{membershipImageError && (*/}
								{/*						<div className="alert callout warning !w-full items-center">*/}
								{/*							<div className="action">*/}
								{/*								<i className="fa-solid fa-triangle-exclamation"></i>*/}
								{/*							</div>*/}
								{/*							<div className="content">*/}
								{/*								<div>*/}
								{/*									<h6>{membershipImageError.message}</h6>*/}
								{/*								</div>*/}
								{/*							</div>*/}
								{/*						</div>*/}
								{/*					)}*/}
								{/*					</div>*/}
								{/*					<div className="cs-stack-form-media-item">*/}
								{/*							<UploadDraggerV2*/}
								{/*								onError={setMembershipVideoError}*/}
								{/*								hideDragIcon="true"*/}
								{/*								hideText="true"*/}
								{/*								shape="video"*/}
								{/*								uploadFileTypes={VIDEO_FILETYPES.toString()}*/}
								{/*								uploadedFilePreview={membershipVideoBlobPreview}*/}
								{/*								onUploadFileChange={*/}
								{/*									handleMembershipVideoUploadFileChange*/}
								{/*								}*/}
								{/*								minHeight={VIDEO_STANDARD_MIN_HEIGHT}*/}
								{/*								minWidth={VIDEO_STANDARD_MIN_WIDTH}*/}
								{/*								maxHeight={VIDEO_STANDARD_MAX_HEIGHT}*/}
								{/*								maxWidth={VIDEO_STANDARD_MAX_WIDTH}*/}
								{/*								uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}*/}
								{/*							/>*/}
								{/*						<p className="cs-label">Membership Video</p>*/}
								{/*						<p className="cs-helper">Optional up to 1920px/1080p MOV or MP4</p>*/}
								{/*						{membershipVideoError && (*/}
								{/*						<div className="alert callout warning !w-full items-center">*/}
								{/*							<div className="action">*/}
								{/*								<i className="fa-solid fa-triangle-exclamation"></i>*/}
								{/*							</div>*/}
								{/*							<div className="content">*/}
								{/*								<div>*/}
								{/*									<h6>{membershipVideoError.message}</h6>*/}
								{/*								</div>*/}
								{/*							</div>*/}

								{/*						</div>*/}
								{/*					)}*/}
								{/*					</div>*/}
								{/*				</div>*/}
								{/*				<div className="cs-formrow-helper-split">*/}
								{/*					<p>Your Membership Image (up to 8MB) should be as high*/}
								{/*						resolution as possible, up to 3840px height and width. This*/}
								{/*						will also be used for any thumbnails and previews.</p>*/}
								{/*								<p>You can add a Membership Video (up to 12MB). This will be*/}
								{/*						viewable by Members when viewing their purchased Membership.*/}
								{/*						Optional.</p>*/}
								{/*				</div>*/}
								{/*			</div>*/}
								{/*			<div className="formrow">*/}
								{/*				<div className="cs-stack-form-buttons">*/}
								{/*					<DropdownWithItems*/}
								{/*							title="Select Content Type"*/}
								{/*							customButtonclassName="button gradient secondary distribute w-full"*/}
								{/*							displayError={*/}
								{/*								displayDropDownError && selectedContentType === 'default'*/}
								{/*							}*/}
								{/*							icon={getListIcon(*/}
								{/*								CONTENT_TYPES_LIST_WITH_DEFAULT,*/}
								{/*								selectedContentType*/}
								{/*							)}*/}
								{/*							selectedValue={selectedContentType}*/}
								{/*							listItems={CONTENT_TYPES_LIST_WITH_DEFAULT}*/}
								{/*							onDropdownChange={(value) => openContentTypeModal(value)}*/}
								{/*						/>*/}
								{/*						<ExplicitContent*/}
								{/*							modalOpen={modalOpenExplicitContent}*/}
								{/*							setModalOpen={setModalOpenExplicitContent}*/}
								{/*							selectedItems={selectedExplicitContent}*/}
								{/*							isExplicitContent={true}*/}
								{/*							onSelectedItems={(value) => setSelectedExplicitContent(value)}*/}
								{/*						/>*/}
								{/*				</div>*/}
								{/*				<div>*/}
								{/*					<p>*/}
								{/*						Change the Content setting if your Details, Image, or Video contains explicit or sensitive content.{' '}*/}
								{/*						{getSitewideLink('view_content_guideline_memberships')}.*/}
								{/*					</p>*/}
								{/*				</div>*/}
								{/*			</div>*/}
								{/*			<div className="formrow">*/}
								{/*				<div className="cs-stack-form-buttons">*/}
								{/*					<DropdownWithItems*/}
								{/*							title="Select Access Type"*/}
								{/*							customButtonclassName="button gradient secondary distribute w-full"*/}
								{/*							displayError={*/}
								{/*								displayDropDownError && selectedAccessType === 'default'*/}
								{/*							}*/}
								{/*							icon={getListIcon(*/}
								{/*								ACCESS_TYPES_LIST_WITH_DEFAULT,*/}
								{/*								selectedAccessType*/}
								{/*							)}*/}
								{/*							selectedValue={selectedAccessType}*/}
								{/*							listItems={ACCESS_TYPES_LIST_WITH_DEFAULT}*/}
								{/*							onDropdownChange={(value) => openAccessTypeModal(value)}*/}
								{/*						/>*/}
								{/*						<ExplicitContent*/}
								{/*							modalOpen={modalOpenExplicitAccess}*/}
								{/*							setModalOpen={setModalOpenExplicitAccess}*/}
								{/*							selectedItems={selectedExplicitAccess}*/}
								{/*							isExplicitContent={false}*/}
								{/*							onSelectedItems={(value) => setSelectedExplicitAccess(value)}*/}
								{/*						/>*/}
								{/*				</div>*/}
								{/*				<div>*/}
								{/*					<p>*/}
								{/*						Change the Access setting if your Membership will provide access to explicit or sensitive content.{' '}*/}
								{/*						{getSitewideLink('view_access_guideline_memberships')}.*/}
								{/*					</p>*/}
								{/*				</div>*/}
								{/*			</div>*/}
								{/*			<Form.Item>*/}
								{/*				<div className="formrow">*/}
								{/*					/!* <Button*/}
								{/*						className="btn btn-lg btn-primary whitespace-pre-wrap mb-0"*/}
								{/*						type="submit"*/}
								{/*						disabled={submitted || formHasErrorMembership}*/}
								{/*						>*/}
								{/*							{submitted && (*/}
								{/*								<i className="fa-solid fa-spinner fa-spin"></i>*/}
								{/*							)}*/}
								{/*							Save Membership & Continue*/}
								{/*					</Button> *!/*/}
								{/*					<button disabled={submitted || formHasErrorMembership} type="submit" className="secondary-gradient">*/}
								{/*					<span><p>*/}
								{/*						{submitted && (*/}
								{/*									<i className="fa-solid fa-spinner fa-spin"></i>*/}
								{/*								)}*/}
								{/*								Save Membership & Continue*/}
								{/*					</p></span>*/}
								{/*					</button>*/}

								{/*					<div className="cs-formrow-helper-none">*/}
								{/*						<p></p>*/}
								{/*					</div>*/}
								{/*				</div>*/}
								{/*			</Form.Item>*/}
								{/*			<div className="formrow">*/}
								{/*						<button disabled={submitted || formHasErrorMembership} onClick={saveAndGoBack} htmlType="submit" className="secondary-gradient">*/}
								{/*				<span><p>*/}
								{/*					{submitted && (*/}
								{/*								<i className="fa-solid fa-spinner fa-spin"></i>*/}
								{/*							)}*/}
								{/*							Save & Go Back*/}
								{/*				</p></span>*/}
								{/*				</button>*/}
								{/*				<div className="cs-formrow-helper-none">*/}
								{/*					<p></p>*/}
								{/*				</div>*/}
								{/*			</div>*/}
								{/*			</Form>*/}
								{/*		)}*/}
                                {/*    </>*/}
                                {/*)}*/}
                                
                            </div>
                        </div>
                    </div>
                </section>
            </main>
		</>
	);
};

export default memo(SpaceCreateAddMembership);
