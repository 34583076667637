import React, {memo, useCallback, useEffect, useState} from "react";
import {Form} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {
    getMembershipDetails,
    getSpaceItemData, getSpaceUsernameByDomain,
    postUserSpaceItemClaimAndSetupSharing,
} from "../../../services/space";
import default_items_img from "../../../assets/images/form_background.png";
import routes from "../../../routes";
import {getSpaceInfo} from "../../../states/spaceGlobal/actions";
import {getLocalStorage, removeLocalStorage} from "../../../utils/storage";
import {MembershipItemUtils, PaymentType} from "../../../utils/MembershipItemUtils";
import {getAuthUserInfo} from "../../../states/general/actions";
import {getUserSpaceItemClaim, register} from "../../../services/user";
import {membershipItemImage} from "../../../components/commons/helpers/MembershipItemHelpers";
import {updateMembershipItemStatus} from "../../../services/payment_method";
import {GOOGLE_MAP_KEY, ROOT_DOMAIN, VALIDATE_MESSAGES} from "../../../constants/common";
import Autocomplete from "react-google-autocomplete";
import {isAlphaNumeric} from "../../../utils/form";
import {setAccessToken} from "../../../utils/common";


const ItemPaymentCompleted = () => {

    const [form] = Form.useForm();
    const [itemPaymentCompleted] = Form.useForm();

    const {authUserInfo, loading} = useSelector((state) => state.general);
    const {spaceInfo, spaceInfoLoading} = useSelector((state) => state.space);

    const currentLocation = useLocation();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const itemId = searchParams.get('id');

    const [isPaid, setIsPaid] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [isItemLoaded, setItemLoaded] = useState(false);
    const [formHasError, setFormHasError] = useState(true);
    const [loadItemOnce, setLoadItemOnce] = useState(false);
    const [formIsOnFocus, setFormIsOnFocus] = useState(false);
    const [isItemClaimLoaded, setItemClaimLoaded] = useState(false);

    const [item, setItem] = useState(null);
    const [alert, setAlert] = useState(null);
    const [membershipDetails, setMembershipDetails] = useState(null);
    const [collectionAssetBGImage, setCollectionAssetBGImage] = useState(null);

    const [selectedCity, setSelectedCity] = useState('share');
    const [selectedEmail, setSelectedEmail] = useState('share');
    const [selectedBirthDate, setSelectedBirthDate] = useState('share');
    const [selectedFirstName, setSelectedFirstName] = useState('share');
    const [collectionAssetType, setCollectionAssetType] = useState('image');

    const [blurredField, setBlurredField] = useState(null);
    const [isSubmitClicked, setSubmitClicked] = useState(false);

    const isProfileUpdate = authUserInfo &&
        (!authUserInfo?.profile ||
            (authUserInfo?.profile &&
                (
                    !authUserInfo?.profile?.first_name ||
                    authUserInfo?.profile?.first_name.trim() === '' ||
                    !authUserInfo?.profile?.last_name ||
                    authUserInfo?.profile?.last_name.trim() === '' ||
                    !authUserInfo?.profile?.last_name ||
                    authUserInfo?.profile?.last_name.trim() === ''
                )));
    const [spaceUsername, setSpaceUsername] = useState(null);

    const getMembershipDetailsRequest = useCallback(async (domain) => {
        const data = {
            space_domain: domain
        }
        const response = await getSpaceUsernameByDomain(data);
        if (response && response.result) {
            setSpaceUsername(response.space_username);
        }

    }, []);

    const getItem = useCallback(async (space_id, item_id) => {
        if (!item_id) {
            return;
        }
        const data = {
            space_id: space_id,
            item_id: item_id,
            is_get_payment_transaction : true
        }
        if (authUserInfo) {
            data['viewing_user_id'] = authUserInfo.id;
        }
        const response = await getSpaceItemData(data);
        if (response && response.result) {
            const itemData = response.item;
            setIsPaid(itemData.is_paid);
            setItem(itemData);
            setItemLoaded(true);
            if (itemData.photo || itemData.video) {
                if (itemData.photo) {
                    const bg = {
                        backgroundImage: 'url(' + itemData.photo + ')',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        borderRadius: '32px',
                    };
                    setCollectionAssetBGImage(bg);
                    setCollectionAssetType('image');
                }
                if (itemData.video) {
                    const bg = {
                        backgroundImage: 'none',
                    };
                    setCollectionAssetBGImage(bg)
                    setCollectionAssetType('video');
                }
            } else {
                const bg = {
                    backgroundImage: 'url(' + default_items_img + ')',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '32px',
                };
                setCollectionAssetBGImage(bg);
                setCollectionAssetType('image');
            }
        }
    }, [item]);


    const handleFormOnBlur = useCallback(async () => {
        await form
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const handleSkipActivation = useCallback(() => {
        // const url = routes.viewUserSpace();
        // const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + itemId;
        window.location.replace(routes.viewUserSpace() + spaceUsername +'/member');
        // window.location.replace(fin_url);
    }, [itemId, spaceUsername]);

    const handleSubmit = useCallback(
        async (values) => {
            setAlert(null);
            setSubmitted(true);

            if (!selectedFirstName) {
                return;
            }

            if (!selectedBirthDate) {
                return;
            }

            if (!selectedCity) {
                return;
            }

            if (!selectedEmail) {
                return;
            }

            const data = values;
            data.space_id = spaceInfo.id;
            data.item_id = itemId;
            data.share_name = selectedFirstName === 'share';
            data.share_birthdate = selectedBirthDate === 'share';
            data.share_city = selectedCity === 'share';
            data.share_email = selectedEmail === 'share';

            if (values.birthdate) {
                data.birthdate = values.birthdate.format('YYYY-MM-DD');
            }

            data.wallet_contract_address = getLocalStorage(
                'temp_membership_wallet_owner_contract_address'
            );

            // const isSpaceMember = spaceInfo.viewing_user_is_member != null ? spaceInfo.viewing_user_is_member : false;
            // const isSubscription = itemDetails.enable_expiration_and_subscription;

            if (authUserInfo) {
                data.user_id = authUserInfo.id;
                const response = await postUserSpaceItemClaimAndSetupSharing(data);
                if (response) {
                    if (response.result) {
                        // removeLocalStorage('temp_membership_nft_is_owned');
                        // removeLocalStorage('temp_membership_space_username');
                        // removeLocalStorage('temp_membership_wallet_owner_contract_address');
                        // removeLocalStorage('temp_membership_wallet_owner_email');

                        let url = routes.spaceItemBuy();

                        if (item.collection_type === 'item') {
                            url = routes.spaceItemActivate()
                        }
                        const fin_url =
                            url.replace(':space_username', '@' + spaceUsername) +
                            '?id=' +
                            itemId;
                        window.location.replace(fin_url);
                    } else {
                        if (response.message === 'Already claimed item.') {
                            const url = routes.spaceItemActivate()
                            const fin_url =
                                url.replace(':space_username', '@' + spaceUsername) +
                                '?id=' +
                                itemId;
                            window.location.replace(fin_url);
                        }
                    }
                } else {
                    console.log('ItemClaimAndSetupSharing-response: '+JSON.stringify(response));
                }
            }

            setSubmitted(false);
        },
        [
            authUserInfo,
            spaceInfo,
            selectedFirstName,
            selectedBirthDate,
            selectedCity,
            selectedEmail,
            itemId,
            item,
            spaceUsername
        ]
    );

    // Get the Membership Details of the user
    const getMembershipDetailsQuery = useCallback(async () => {
        try {
            if (!spaceInfo) {
                return;
            }
            if (!authUserInfo) {
                return;
            }
            const params = {};
            params.space_id = spaceInfo.id;
            params.user_id = authUserInfo.id;
            params.is_get_current_membership = true;
            const response = await getMembershipDetails(params);
            if (response && response.result) {
                setMembershipDetails(response.membership_details);
            }
        } catch (error) {
            console.log(error);
        }
    }, [spaceInfo, authUserInfo]);

    const activateMembershipStatus = useCallback(async () => {
        setSubmitted(true);
        if (!spaceInfo) return;
        const data = {
            space_id: spaceInfo.id,
            item_id: itemId,
            status: 'active'
        }
        if (authUserInfo) {
            data['user_id'] = authUserInfo.id;
        }

        if (!membershipDetails?.active_membership && membershipDetails?.current_membership && membershipDetails?.current_membership.status === 'inactive' ) {
            const url = routes.spaceItemActivate();
            const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + itemId;
            window.location.replace(fin_url);
        } else if (!membershipDetails?.active_membership && !membershipDetails?.current_membership) {
            const url = routes.spaceItemActivate();
            const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + itemId;
            window.location.replace(fin_url);
        } else {
            const createStripeResponse = await updateMembershipItemStatus(data);
            if (createStripeResponse && createStripeResponse.result) {
                const url = routes.spaceItemActivate();
                const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + itemId;
                window.location.replace(fin_url);

                setSubmitted(false);
            } else {
                setSubmitted(false);
            }
        }

    },[authUserInfo, spaceInfo, spaceUsername, itemId, membershipDetails])

    const handleSubmitSaveAccount = useCallback(async (values) => {
            const data = values;
            if (values.birthdate) {
                data.birthdate = values.birthdate;
            }
            if (authUserInfo) {
                data.user_id = authUserInfo.id;
                // if (isProfileUpdate) {
                //     data.is_profile_update = isProfileUpdate;
                // }
                const email = authUserInfo?.email;
                if (email) {
                    data.email = email
                } else {
                    return;
                }
            }
            const result = await register(data);
            if (result) {
                if (result.result) {
                    if (result.access_token) {
                        setAccessToken(result);
                    }
                    dispatch(getAuthUserInfo());
                    if (getLocalStorage('redirect_link')) {
                        removeLocalStorage('redirect_link')
                    }
                    let url = routes.spaceItemActivate();
                    const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + itemId;
                    window.location.replace(fin_url);
                } else {
                    setAlert({
                        variant: 'danger',
                        message: result.message
                    });
                }
            }
            setSubmitted(false);
        }, [authUserInfo, spaceUsername, itemId]
    );


    const getItemClaim = useCallback(async (space_id, item_id) => {
        if (!item_id) return;
        const data = {
            space_id: space_id,
            item_id: item_id
        }
        if (authUserInfo) {
            data['user_id'] = authUserInfo.id;
        }
        const response = await getUserSpaceItemClaim(data);
        if (response) {
            if (response.result) {
                // if (response.is_exist) {
                //     let url = routes.spaceItemActivate();
                //     const fin_url =
                //         url.replace(':space_username', '@' + space_username) +
                //         '?id=' +
                //         item_id;
                //     window.location.replace(fin_url);
                // }
            }
            setItemClaimLoaded(true);
        }
    }, [item, authUserInfo]);

    const handleFinishFailed = useCallback(async (errorInfo) => {
        setSubmitClicked(true);
        // Set the blurred field to the first field with an error
        if (errorInfo.errorFields.length > 0) {
            setBlurredField('all');
        }
    }, [blurredField, isSubmitClicked]);

    const handleOnblurField = useCallback(async (field) => {
        if (!isSubmitClicked) {
            setBlurredField(field);
        }
        setSubmitClicked(false);
    }, [blurredField, isSubmitClicked]);


    useEffect(() => {
        if (item != null) {
            setItemLoaded(false);
        }
        if (authUserInfo===null) {
            dispatch(getAuthUserInfo());
        }
        if (spaceUsername && !isItemLoaded) {
            if (spaceInfoLoading && spaceInfoLoading === 'done') {
                if (spaceInfo && spaceInfo.id) {
                    getItem(spaceInfo.id, itemId).then((r) => {});
                    getMembershipDetailsQuery().then(()=>{})
                    // setSpaceName(spaceInfo.name);
                }
            } else {
                if (!spaceInfoLoading && initialLoad) {
                    setInitialLoad(false);
                    dispatch(getSpaceInfo(spaceUsername));
                }
                if (spaceInfoLoading === 'failed') {
                    window.location.replace(routes.createSpace());
                }
            }
        }

        if (item != null) {
            if (authUserInfo != null && !loadItemOnce) {
                setLoadItemOnce(true);
                getItem(spaceInfo.id, item).then((r) => {});
            }

            //Redirect to details if already claim or paid
            if (authUserInfo != null) {
                getItemClaim(spaceInfo.id, itemId).then(r =>{});
            }
        }

        //Redirect user to logged in page if not authenticated
        MembershipItemUtils.notAuthRedirect(loading, authUserInfo);

    }, [
        currentLocation.pathname,
        authUserInfo,
        spaceInfo,
        spaceInfoLoading,
        spaceUsername,
        loadItemOnce,
        // initialLoad,
        itemId,
        item,
        isItemClaimLoaded
    ]);

    useEffect(()=>{

    },[membershipDetails])

    useEffect(() => {
        if (ROOT_DOMAIN !== window.location.hostname) {
            getMembershipDetailsRequest(window.location.hostname).then(r => {});
        } else {
            const path = currentLocation.pathname;
            if (path && path.startsWith('/@') && path.length > 3) {
                const clean_path = path.replace('/@', '');
                const path_exp = clean_path.split('/');
                setSpaceUsername(path_exp[0]);
            }
        }
    }, [currentLocation.pathname]);

    return (
        <>
            {/* <main id="membership-buy" className="flex">
                {!spaceInfo || !authUserInfo || !isItemClaimLoaded ? (
                    <div className="loading-items w-full mt-8 text-center">
                        <i className="fa-light fa-solar-system gradient-color-txt rotate"></i>
                    </div>
                ) : (
                    <>
                        <div className="margin-left-15 xsm:mx-5 md:mx-20 lg:mr-0 lg:ml-40">
                            <div className="grid gap-8 margin-right-0">
                                <div className="grid grid-cols-2 pr-0">
                                    <div className="col-span-2 grid grid-cols-2 ps-0 gap-4">
                                        <div className="lg:col-span-1 xsm:col-span-2">
                                            <h1 className="mb-4 mt-5 header-large">
                                                Payment Completed.<br/>
                                                Your Item is ready!
                                            </h1>
                                            <p className="body-text--reg">
                                                You’ve successfully purchased {' '}
                                                <a href={itemDetails?.type === "nft_collection" ?
                                                    routes.spaceMembersProductsItem().replace(':space_username', '@' + spaceInfo?.username) + '?id=' + itemId :
                                                    routes.spaceItemBuy().replace(':space_username', '@' + spaceInfo?.username) + '?id=' + itemId} className="gradient-color-txt">
                                                    {itemDetails ? itemDetails.name : ''}
                                                </a>
                                                {' '}for{' '}
                                                <a href={spaceInfo ? routes.viewUserSpace() + spaceInfo?.username : ""} className="gradient-color-txt">
                                                    <i className="fa-solid fa-solar-system"/>{' '}{spaceInfo ? spaceInfo.name : ''}
                                                </a>
                                                {' '}. To activate it, complete the details below:
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-span-2 grid lg:grid-cols-2 md:grid-cols-1 xsm:gap-10 lg:gap-30 ">

                                        <div className="lg:hidden md:flex">
                                            {membershipItemImage(collectionAssetType, itemDetails)}
                                        </div>

                                        <div className="lg:col-span-1">
                                            <h3 className="mb-4 mt-5 header-medium">
                                                Data Sharing with <i className="fa-solid fa-solar-system"/> {spaceInfo ? spaceInfo.name : ''}
                                            </h3>
                                            <p className="body-text--reg">
                                                Your Item for {' '}
                                                <a href={spaceInfo ? routes.viewUserSpace() + spaceInfo?.username : ""} className="gradient-color-txt">
                                                    <i className="fa-solid fa-solar-system"/>{' '}{spaceInfo ? spaceInfo.name : ''}
                                                </a>
                                                {' '}includes access, updates,
                                                benefits, and more. To get full access to
                                                everything {' '}<i className="fa-solid fa-solar-system"/>{' '}{spaceInfo ? spaceInfo.name : ''}
                                                {' '}offers, it’s recommended you share your Account Details with their
                                                management team. This information is never shared publicly.
                                            </p>
                                            <Form
                                                form={itemPaymentCompleted}
                                                name="itemPaymentCompleted"
                                                id="itemPaymentCompleted"
                                                validateMessages={VALIDATE_MESSAGES}
                                                onFocus={() => {
                                                    setFormIsOnFocus(true);
                                                    setAlert(null);
                                                }}
                                                onBlur={() => {
                                                    setTimeout(() => {
                                                        setFormIsOnFocus(false);
                                                        handleFormOnBlur();
                                                    }, 200);
                                                }}
                                                autoComplete="off"
                                                onFinish={handleSubmit}
                                                className={formIsOnFocus ? 'hide-antd-error-messages' : ''}
                                            >

                                                <div className="grid grid-cols-12 mt-5 flex items-center">
                                                    <div className="col-span-4">
                                                        <p className="m-0 body-text--small gradient-color-txt">
                                                            First & Last Name
                                                        </p>
                                                    </div>
                                                    <div className="col-span-8">
                                                        <div className="input-group-com">
                                                            <DropdownWithItems
                                                                dropDownClass="w-full"
                                                                customButtonClass="btn-lg btn-secondary dropdown-primary"
                                                                title="First & Last Name"
                                                                selectedValue={selectedFirstName}
                                                                listItems={MEMBERSHIP_DATA_SHARING}
                                                                onDropdownChange={(value) =>
                                                                    setSelectedFirstName(value)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="grid grid-cols-12 mt-5 flex items-center">
                                                    <div className="col-span-4">
                                                        <p className="m-0 body-text--small gradient-color-txt">
                                                            Birthdate
                                                        </p>
                                                    </div>
                                                    <div className="col-span-8">
                                                        <div className="input-group-com">
                                                            <DropdownWithItems
                                                                dropDownClass="w-full"
                                                                customButtonClass="btn-lg btn-secondary dropdown-primary"
                                                                title="Birthdate"
                                                                selectedValue={selectedBirthDate}
                                                                listItems={MEMBERSHIP_DATA_SHARING}
                                                                onDropdownChange={(value) =>
                                                                    setSelectedBirthDate(value)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="grid grid-cols-12 mt-5 flex items-center">
                                                    <div className="col-span-4">
                                                        <p className="m-0 body-text--small gradient-color-txt">
                                                            City
                                                        </p>
                                                    </div>
                                                    <div className="col-span-8">
                                                        <div className="input-group-com">
                                                            <DropdownWithItems
                                                                dropDownClass="w-full"
                                                                customButtonClass="btn-lg btn-secondary dropdown-primary"
                                                                title="City"
                                                                selectedValue={selectedCity}
                                                                listItems={MEMBERSHIP_DATA_SHARING}
                                                                onDropdownChange={(value) =>
                                                                    setSelectedCity(value)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="grid grid-cols-12 mt-5 flex items-center">
                                                    <div className="col-span-4">
                                                        <p className="m-0 body-text--small gradient-color-txt">
                                                            Email Address
                                                        </p>
                                                    </div>
                                                    <div className="col-span-8">
                                                        <div className="input-group-com">
                                                            <DropdownWithItems
                                                                dropDownClass="w-full"
                                                                customButtonClass="btn-lg btn-secondary dropdown-primary"
                                                                title="Email"
                                                                selectedValue={selectedEmail}
                                                                listItems={MEMBERSHIP_DATA_SHARING}
                                                                onDropdownChange={(value) =>
                                                                    setSelectedEmail(value)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="input-group-com my-5">
                                                    <Form.Item className="mb-0">
                                                        <Button
                                                            className="btn btn-primary btn-lg"
                                                            type="submit"
                                                            disabled={submitted}
                                                        >
                                                            {submitted && (
                                                                <i className="fa-solid fa-spinner fa-spin me-3"></i>
                                                            )}
                                                            Save & Activate
                                                        </Button>
                                                    </Form.Item>
                                                </div>
                                            </Form>
                                        </div>
                                        <div className="lg:inline xsm:hidden p-0">
                                            {membershipItemImage(collectionAssetType, itemDetails)}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>
                    )
                }
            </main> */}
            { (!authUserInfo || !item) ? (
                <section id="page-content-loading-overlay" className="screen-modal p-section-safeview theme-transparent">
                    <div className="centerframe-flex frame-modal">
                        <div className="block-lg modal-block">
                            <i className="fa-regular fa-solar-system"/>
                        </div>
                    </div>
                </section>
            ) : (
                <main id="cs-space-main" className="cs-main main-theme">
                    <section id="cs-space-checkout-complete-c1" className="py-0 px-0 lg:py-section-safeview theme-transparent">
                        <div className="cs-centerframe-split-lg">
                            <div className="cs-centerframe-split-left-lg order-2 lg:order-1">
                                <div className="p-section-safeview lg:pr-gutter">
                                    <div className="cs-stack-checkout-form stack-col-base gap-gutterxx lg:max-w-half-breakpoint-less-gutter">
                                        <div className="cs-stack-checkout-confirmation stack-col">
                                            <hgroup className="heading">
                                                <h2>Thank you!</h2>
                                                { item?.payment_transaction?.payment_type === PaymentType.onetime ? (
                                                    <p>Your {' '}
                                                        <a href={item?.type === "nft_collection" ?
                                                            routes.spaceMembersProductsMembership().replace(':space_username', '@' + spaceInfo?.username) + '?id=' + itemId :
                                                            routes.spaceMembershipBuy().replace(':space_username', '@' + spaceInfo?.username) + '?id=' + itemId} className="gradient-color-txt">
                                                            <i className="fa-regular fa-solar-system"/>{' '}
                                                            {spaceInfo?.name ?? ''}
                                                        </a>
                                                        {' '}purchase is complete and your payment method has been charged for{' '}${(item?.price != null ? parseFloat(item?.price).toFixed(2) : 0) + ' ' + (item?.currency != null ? item?.currency : 'USD') }.
                                                        {/*(after “Order…” should be order & transaction details and NOT <Spacename>*/}
                                                        {/*{' '}You will receive an email receipt shortly for Order{' '}{item?.name ?? ''}{' '}with transaction{' '}{item?.payment_transaction?.transaction_id ?? ''}.*/}
                                                    </p>
                                                ) : (
                                                    <p>Your {' '}
                                                        <a href={item?.type === "nft_collection" ?
                                                            routes.spaceMembersProductsItem().replace(':space_username', '@' + spaceInfo?.username) + '?id=' + itemId :
                                                            routes.spaceItemBuy().replace(':space_username', '@' + spaceInfo?.username) + '?id=' + itemId} className="gradient-color-txt">
                                                            <i className="fa-regular fa-solar-system"/>{' '}
                                                            {spaceInfo?.name ?? ''}
                                                        </a>{' '}order is complete.
                                                        {/*(after “Order…” should be order & transaction details and NOT <Spacename>*/}
                                                        {/*You will receive an email receipt shortly for Order{' '}*/}
                                                        {/*{item?.name ?? ''}*/}
                                                        {/*<a href={spaceInfo ? routes.viewUserSpace() + spaceInfo?.username : ""} className="gradient-color-txt">*/}
                                                        {/*    <i className="fa-solid fa-solar-system"/>{' '}{spaceInfo ? spaceInfo.name : ''}*/}
                                                        {/*</a> for {' '} ${(item?.price != null ? parseFloat(item?.price).toFixed(2) : 0) + ' ' + (item?.currency != null ? item?.currency : 'USD') }.*/}
                                                    </p>
                                                )}
                                            </hgroup>
                                            { isProfileUpdate &&
                                                <div className="cs-product-preview-row">
                                                    <div className="avatar-square-sm">
                                                        <img src={item?.photo} alt="Image of Friends & Mike Membership"/>
                                                    </div>
                                                    <hgroup>
                                                        <h5>{item ? item.name : ''}</h5>
                                                        <p>{item ? item.summary : ''}</p>
                                                    </hgroup>
                                                </div>
                                            }
                                        </div>
                                        { isProfileUpdate ? (
                                            <>
                                                <hgroup className="heading">
                                                    {getLocalStorage('redirect_link') ?
                                                        <>
                                                            <h3>Complete your Account</h3>
                                                            <p>Continue your account creation below.</p>
                                                        </>
                                                        :
                                                        <>
                                                            <h3>Complete your Account & Activate your Membership</h3>
                                                            <p>Continue your account creation below then activate your Membership to access its benefits, or you can choose to activate it later from your Dashboard.</p>
                                                        </>
                                                    }
                                                </hgroup>

                                                <Form
                                                    form={form}
                                                    name="registerForm"
                                                    id="registerForm"
                                                    validateMessages={VALIDATE_MESSAGES}
                                                    onFocus={() => {
                                                        setFormIsOnFocus(true);
                                                    }}
                                                    onBlur={() => {
                                                        setTimeout(() => {
                                                            setFormIsOnFocus(false);
                                                            handleFormOnBlur().then(() => {});
                                                        }, 200);
                                                    }}
                                                    initialValues={{
                                                        remember: true
                                                    }}
                                                    // onChange={handleFormChanges}
                                                    onFinish={handleSubmitSaveAccount}
                                                    onFinishFailed={handleFinishFailed}
                                                    className='cs-stack-create-account stack-col'
                                                >
                                                    <Form.Item
                                                        name="first_name"
                                                        label="First Name"
                                                        rules={[
                                                            {
                                                                required: true
                                                            }
                                                        ]}
                                                    >
                                                        <div className="form-input">
                                                            <label htmlFor="firstname">First Name</label>
                                                            <input id="first_name" name="first_name"
                                                                   onBlur={()=>handleOnblurField('first_name')}
                                                                   type="text" autoComplete="first_name" placeholder="Name"/>
                                                        </div>
                                                    </Form.Item>
                                                    {((blurredField === 'first_name' || blurredField === 'all') && (form.getFieldsError()[0]?.errors.length > 0)) && (
                                                        <div className="alert callout warning">
                                                            <div className="action">
                                                                <i className="fa-solid fa-triangle-exclamation"></i>
                                                            </div>
                                                            <div className="content">
                                                                <div>
                                                                    <h6>{form.getFieldsError()[0]?.errors}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <Form.Item
                                                        name="last_name"
                                                        label="Last Name"
                                                        rules={[
                                                            {
                                                                required: true
                                                            }
                                                        ]}
                                                    >
                                                        <div className="form-input">
                                                            <label htmlFor="lastname">Last Name</label>
                                                            <input id="last_name" name="last_name"
                                                                   onBlur={()=>handleOnblurField('last_name')}
                                                                   type="text" autoComplete="last_name" placeholder="Surname"/>
                                                        </div>
                                                    </Form.Item>
                                                    {((blurredField === 'last_name' || blurredField === 'all') && (form.getFieldsError()[1]?.errors.length > 0)) && (
                                                        <div className="alert callout warning">
                                                            <div className="action">
                                                                <i className="fa-solid fa-triangle-exclamation"></i>
                                                            </div>
                                                            <div className="content">
                                                                <div>
                                                                    <h6>{form.getFieldsError()[1]?.errors}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <Form.Item
                                                        name="birthdate"
                                                        label="Birthdate"
                                                        rules={[
                                                            {
                                                                required: true
                                                            }
                                                        ]}
                                                    >
                                                        <div  className="form-input">
                                                            <label htmlFor="birthdate">Birthdate</label>
                                                            <input id="birthdate" onChange={(date) => {
                                                                form.setFieldsValue({ birthdate: date.target.value });
                                                            }}
                                                                   onBlur={()=>handleOnblurField('birthdate')}
                                                                   name="birthdate" type="date" autoComplete="birthdate" placeholder="MM/DD/YYYY"></input>
                                                        </div>
                                                    </Form.Item>
                                                    {((blurredField === 'birthdate' || blurredField === 'all') && (form.getFieldsError()[2]?.errors.length > 0)) && (
                                                        <div className="alert callout warning">
                                                            <div className="action">
                                                                <i className="fa-solid fa-triangle-exclamation"></i>
                                                            </div>
                                                            <div className="content">
                                                                <div>
                                                                    <h6>{form.getFieldsError()[2]?.errors}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <Form.Item
                                                        name="city"
                                                        label="City"
                                                        rules={[
                                                            {
                                                                required: true
                                                            }
                                                        ]}
                                                    >
                                                        <div className="form-input">
                                                            <label htmlFor="city">City</label>
                                                            <Autocomplete
                                                                placeholder="Start typing & select from list."
                                                                autoComplete="off"
                                                                apiKey={GOOGLE_MAP_KEY}
                                                                onBlur={()=>handleOnblurField('city')}
                                                                onPlaceSelected={(place) => {
                                                                    form.setFieldValue(
                                                                        'city',
                                                                        place.formatted_address
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </Form.Item>
                                                    {((blurredField === 'city' || blurredField === 'all') && (form.getFieldsError()[3]?.errors.length > 0)) && (
                                                        <div className="alert callout warning">
                                                            <div className="action">
                                                                <i className="fa-solid fa-triangle-exclamation"></i>
                                                            </div>
                                                            <div className="content">
                                                                <div>
                                                                    <h6>{form.getFieldsError()[3]?.errors}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <p className="cs-helper">Your Birthdate and City are kept private and not publicly visible. This information is needed to comply with local regulations and for access to age-appropriate content.</p>
                                                    <Form.Item
                                                        name="password"
                                                        label="Password"
                                                        rules={[
                                                            {required: true},
                                                            ({getFieldValue}) => ({
                                                                validator(_, value) {
                                                                    if (value && value.length < 8) {
                                                                        return Promise.reject(
                                                                            new Error('Password must be 8 characters long or more.')
                                                                        );
                                                                    }
                                                                    if (isAlphaNumeric(value)) {
                                                                        return Promise.resolve();
                                                                    }
                                                                    if (value && value.length > 0) {
                                                                        return Promise.reject(
                                                                            new Error(
                                                                                'Password is case sensitive, must be at least 8 characters, and include a symbol and number.'
                                                                            )
                                                                        );
                                                                    }
                                                                    return Promise.resolve();
                                                                }
                                                            })
                                                        ]}
                                                    >
                                                        <div className="form-input">
                                                            <label htmlFor="password">Password</label>
                                                            <input id="password" name="password"
                                                                   onBlur={()=>handleOnblurField('password')}
                                                                   type="password" autoComplete="password" placeholder="8+ characters with a symbol and number."/>
                                                        </div>
                                                    </Form.Item>
                                                    {((blurredField === 'password' || blurredField === 'all') && (form.getFieldsError()[4]?.errors.length > 0)) && (
                                                        <div className="alert callout warning">
                                                            <div className="action">
                                                                <i className="fa-solid fa-triangle-exclamation"></i>
                                                            </div>
                                                            <div className="content">
                                                                <div>
                                                                    <h6>{form.getFieldsError()[4]?.errors}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <Form.Item
                                                        name="confirmpassword"
                                                        label="Confrim Password"
                                                        dependencies={['password']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please confirm your password.'
                                                            },
                                                            ({getFieldValue}) => ({
                                                                validator(_, value) {
                                                                    if (!value || getFieldValue('password') === value) {
                                                                        return Promise.resolve();
                                                                    }
                                                                    return Promise.reject(new Error('Password does not match.'));
                                                                }
                                                            })
                                                        ]}
                                                    >
                                                        <div className="form-input">
                                                            <label htmlFor="password">Re-enter Password*</label>
                                                            <input id="confirmpassword" name="confirmpassword"
                                                                   onBlur={()=>handleOnblurField('confirmpassword')}
                                                                   type="password" autoComplete="confirmpassword" placeholder="Re-enter Password"/>
                                                        </div>
                                                    </Form.Item>
                                                    {((blurredField === 'confirmpassword' || blurredField === 'all') && (form.getFieldsError()[5]?.errors.length > 0)) && (
                                                        <div className="alert callout warning">
                                                            <div className="action">
                                                                <i className="fa-solid fa-triangle-exclamation"></i>
                                                            </div>
                                                            <div className="content">
                                                                <div>
                                                                    <h6>{form.getFieldsError()[5]?.errors}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <Form.Item>
                                                        <div className="stack-buttons col">
                                                            <button
                                                                disabled={submitted}
                                                                type="submit"
                                                                className="primary-gradient">
                                                                <span><p>Save & Continue</p></span>
                                                            </button>

                                                            {/*<a onClick={handleSkipActivation} type="button" className="button-secondary-gradient"><span><p>Skip Activation</p></span></a>*/}
                                                        </div>
                                                    </Form.Item>

                                                    {alert && (
                                                        <div className="alert callout warning !m-0">
                                                            <div className="action">
                                                                <i className="fa-solid fa-triangle-exclamation"></i>
                                                            </div>
                                                            <div className="content">
                                                                <div>
                                                                    <h6>{alert?.message ?? 'Something went wrong!'}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Form>
                                            </>
                                        ) : (
                                            <div className="cs-stack-checkout-activate stack-col">
                                                <hgroup className="heading">
                                                    <h2>Activate your Item</h2>
                                                    <p>You can activate your Item on your Account now to access its benefits, or you can activate it later from your Dashboard.</p>
                                                </hgroup>

                                                <div className="cs-product-preview-row">
                                                    <div className="avatar-square-sm">
                                                        <img src={item?.photo} alt="Image of Friends & Mike Membership"/>
                                                    </div>
                                                    <hgroup>
                                                        <h5>{item ? item.name : ''}</h5>
                                                        <p>{item ? item.summary : ''}</p>
                                                    </hgroup>
                                                </div>

                                                <div className="stack-buttons col">
                                                    <a onClick={activateMembershipStatus} type="button" className="button-primary-gradient"><span><p>Continue to Activate</p></span></a>
                                                    <a type="button" onClick={handleSkipActivation} className="button-secondary-gradient"><span><p>Skip Activation</p></span></a>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                            <div className="cs-centerframe-split-right-lg order-1 lg:order-2 lg:pt-gutter lg:pb-gutter">

                                <div className="cs-checkout-product-hero">

                                    {membershipItemImage(collectionAssetType, item)}
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            )}
        </>
    );
}

export default memo(ItemPaymentCompleted);