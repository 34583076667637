import { memo, useCallback, useEffect, useState } from 'react';
import { MANAGE_MEMBERS_FILTER, MANAGE_MEMBERS_SORT } from '../../../constants/space';
import DropdownWithItems from '../../../components/commons/DropdownWithItems';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Pagination from 'react-bootstrap/Pagination';
import moment from 'moment';

import routes from '../../../routes';
import { getMembersList } from '../../../services/space';
import { shortenNumber } from '../../../utils/number';
import InviteGrantMembershipModal from '../../../components/commons/Modals/InviteGrantMembership/InviteGrantMembershipModal';
import {getListIcon} from "../../../utils/list";
import {VISIBILITY_SUB_SETTINGS_LIST_WITH_DEFAULT, VISIBILITY_SUB_SETTINGS_PRIVATE_LIST_WITH_DEFAULT} from "../../../constants/profile";


const Members = () => {
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [selectedFilter, setSelectedFilter] = useState('default');
	const [selectedSort, setSelectedSort] = useState('default');
	const [filterArr, setFilterArr] = useState(null);
	const [sortArr, setSortArr] = useState(null);
	const [membersFetching, setMembersFetching] = useState(false);
	const [membersList, setMembersList] = useState(null);
	const [initialLoad, setInitialLoad] = useState(true);
	const [totalMembersCount, setTotalMembersCount] = useState(0);
	const [totalActiveMembersCount, setTotalActiveMembersCount] = useState(0);
	const [newMembersTodayCount, setNewMembersTodayCount] = useState(0);
	const [newMembersMonthCount, setNewMembersMonthCount] = useState(0);
	const [lostMembersMonthCount, setLostMembersMonthCount] = useState(0);
	const [monthlyActiveCount, setMonthlyActiveCount] = useState(0);
	const [weeklyActiveCount, setWeeklyActiveCount] = useState(0);
	const [dailyActiveCount, setDailyActiveCount] = useState(0);
	const [membersPagination, setMembersPagination] = useState([]);
	const [membersCurrentPage, setMembersCurrentPage] = useState(1);
	const [modalOpen, setModalOpen] = useState(false);

	const [clickedTableColumnOrder, setClickedTableColumnOrder] = useState(null);
	const [usernameSortOrder, setUsernameSortOrder] = useState(null);
	const [joinDateSortOrder, setJoinDateSortOrder] = useState(null);
	const [followerSortOrder, setFollowerSortOrder] = useState(null);
	const [membershipSortOrder, setMembershipSortOrder] = useState(null);
	const [itemSortOrder, setItemSortOrder] = useState(null);

	const [searchInputTimer, setSearchInputTimer] = useState(null);
	const [searchText, setSearchText] = useState('');

	function changeDelay(change) {
		if (searchInputTimer) {
			clearTimeout(searchInputTimer);
			setSearchInputTimer(null);
		}
		setSearchInputTimer(
			setTimeout(() => {
				getMembersRequest().then((r) => {});
			}, 3000)
		);
	}

	const filterDropdownChange = useCallback(
		async (value) => {
			const membershipTypes = ['free', 'paid', 'recurring'];
			const accountTypes = ['<30', '>30', '1space', '2plusSpace'];
			const activityTypes = [
				'lastday',
				'last7days',
				'last30days',
				'last90days',
				'last180days'
			];

			const data = {
				filter_value: value
			};
			if (membershipTypes.includes(value)) {
				data['filter_type'] = 'membership';
			}
			if (accountTypes.includes(value)) {
				data['filter_type'] = 'account';
			}
			if (activityTypes.includes(value)) {
				data['filter_type'] = 'activity';
			}

			setFilterArr(data);
			setSelectedFilter(value);
		},
		[authUserInfo, spaceInfo, membersCurrentPage]
	);

	const sortDropdownChange = useCallback(
		async (value) => {
			setUsernameSortOrder(null);
			setJoinDateSortOrder(null);
			setFollowerSortOrder(null);
			setMembershipSortOrder(null);
			setItemSortOrder(null);

			const data = {
				sort_by: value
			};

			setSortArr(data);
			setSelectedSort(value);
		},
		[authUserInfo, spaceInfo, membersCurrentPage]
	);

	const handleTableSorts = useCallback(
		async (column) => {
			setClickedTableColumnOrder(column);

			if (column === 'username') {
				setJoinDateSortOrder(null);
				setFollowerSortOrder(null);
				setMembershipSortOrder(null);
				setItemSortOrder(null);

				if (usernameSortOrder === 'asc') {
					setUsernameSortOrder(null);
				} else if (usernameSortOrder === 'desc') {
					setUsernameSortOrder('asc');
				} else {
					setUsernameSortOrder('desc');
				}
			}
			if (column === 'join_date') {
				setUsernameSortOrder(null);
				setFollowerSortOrder(null);
				setMembershipSortOrder(null);
				setItemSortOrder(null);

				if (joinDateSortOrder === 'asc') {
					setJoinDateSortOrder(null);
				} else if (joinDateSortOrder === 'desc') {
					setJoinDateSortOrder('asc');
				} else {
					setJoinDateSortOrder('desc');
				}
			}
			if (column === 'follower_count') {
				setUsernameSortOrder(null);
				setJoinDateSortOrder(null);
				setMembershipSortOrder(null);
				setItemSortOrder(null);

				if (followerSortOrder === 'asc') {
					setFollowerSortOrder(null);
				} else if (followerSortOrder === 'desc') {
					setFollowerSortOrder('asc');
				} else {
					setFollowerSortOrder('desc');
				}
			}
			if (column === 'memberships') {
				setUsernameSortOrder(null);
				setJoinDateSortOrder(null);
				setFollowerSortOrder(null);
				setItemSortOrder(null);

				if (membershipSortOrder === 'asc') {
					setMembershipSortOrder(null);
				} else if (membershipSortOrder === 'desc') {
					setMembershipSortOrder('asc');
				} else {
					setMembershipSortOrder('desc');
				}
			}
			if (column === 'items') {
				setUsernameSortOrder(null);
				setJoinDateSortOrder(null);
				setFollowerSortOrder(null);
				setMembershipSortOrder(null);

				if (itemSortOrder === 'asc') {
					setItemSortOrder(null);
				} else if (itemSortOrder === 'desc') {
					setItemSortOrder('asc');
				} else {
					setItemSortOrder('desc');
				}
			}
		},
		[
			usernameSortOrder,
			joinDateSortOrder,
			followerSortOrder,
			membershipSortOrder,
			itemSortOrder
		]
	);

	const handleOnClickPagination = useCallback(async (event) => {
		const itemClicked = event.target.text;
		if (itemClicked) {
			const number = parseInt(itemClicked);
			setMembersCurrentPage(number);
			await getMembersRequest();
		}
	}, []);

	const buildPagination = (current_page, max_page) => {
		const items = [];
		if (max_page) {
			for (let number = 1; number <= max_page; number++) {
				items.push(
					<Pagination.Item key={number} active={number === current_page}>
						{number}
					</Pagination.Item>
				);
			}
		}

		setMembersPagination(items);
	};

	const getPercentage = (totalCount, count) => {
		if (totalCount === 0 && count === 0) {
			return '0%';
		}
		const perc = (count / totalCount) * 100;
		return perc + '%';
	};

	const getMembersRequest = useCallback(async () => {
		try {
			if (authUserInfo && spaceInfo) {
				const data = {
					viewing_user_id: authUserInfo.id,
					space_id: spaceInfo.id,
					list_count: 10,
					page: membersCurrentPage
				};

				if (selectedFilter !== 'default') {
					data['filter_type'] = filterArr['filter_type'];
					data['filter_value'] = filterArr['filter_value'];
				}

				if (selectedSort !== 'default') {
					data['sort_by'] = sortArr['sort_by'];

					if (selectedSort === 'following_count') {
						data['sort_order'] = 'desc';
					}
				}

				if (clickedTableColumnOrder === 'username') {
					if (usernameSortOrder) {
						data['sort_by'] = 'username';
						data['sort_order'] = usernameSortOrder;
					}
				}
				if (clickedTableColumnOrder === 'join_date') {
					if (joinDateSortOrder) {
						data['sort_by'] = 'join_date';
						data['sort_order'] = joinDateSortOrder;
					}
				}
				if (clickedTableColumnOrder === 'follower_count') {
					if (followerSortOrder) {
						data['sort_by'] = 'follower_count';
						data['sort_order'] = followerSortOrder;
					}
				}
				if (clickedTableColumnOrder === 'memberships') {
					if (membershipSortOrder) {
						data['sort_by'] = 'memberships';
						data['sort_order'] = membershipSortOrder;
					}
				}
				if (clickedTableColumnOrder === 'items') {
					if (itemSortOrder) {
						data['sort_by'] = 'items';
						data['sort_order'] = itemSortOrder;
					}
				}

				if (searchText && searchText !== '') {
					data['search'] = searchText;
				}

				setMembersFetching(true);

				const response = await getMembersList(data);
				if (response && response.result) {
					if (response.data) {
						setMembersList(response.data);
						setTotalMembersCount(response.total_members_count);
						setTotalActiveMembersCount(response.total_active_members_count);
						setNewMembersTodayCount(response.new_members_today_count);
						setNewMembersMonthCount(response.new_members_month_count);
						setLostMembersMonthCount(response.lost_members_month_count);
						setMonthlyActiveCount(response.monthly_active_count);
						setWeeklyActiveCount(response.weekly_active_count);
						setDailyActiveCount(response.daily_active_count);
						buildPagination(parseInt(response.page), parseInt(response.page_range));
					} else {
						setMembersList(null);
					}
				}
				setMembersFetching(false);
			}
		} catch (error) {
			console.log(error);
		}
	}, [
		authUserInfo,
		spaceInfo,
		membersCurrentPage,
		selectedFilter,
		selectedSort,
		filterArr,
		sortArr,
		clickedTableColumnOrder,
		usernameSortOrder,
		joinDateSortOrder,
		followerSortOrder,
		membershipSortOrder,
		itemSortOrder,
		searchText
	]);

	const redirectToSpaceLink = useCallback(
		(route, member_id) => {
			if (spaceInfo) {
				let url = route.replace(':space_username', '@' + spaceInfo.username);
				return url.replace(':member_id', member_id);
			}
			return '';
		},
		[spaceInfo]
	);

	const MemberRow = (data) => {
		return (
			<div className='min-w-[768px]'>
				<div className="row-view-wrapper mt-4">
					<div className="grid grid-cols-12">
						<div className="col-span-4 flex items-center ">
							<img
								className="rounded-circle me-4 shrink-0"
								src={data.user.profile.photo_thumb}
							/>
							<h3>
								{data.user.profile.username
									? '@' + data.user.profile.username
									: data.user.profile.full_name}
							</h3>
						</div>
						<div className="col-span-2 flex items-center ">
							<i className="fa-light fa-calendar"></i>
							<label className="body-text--smallest ms-1">
								{moment(data.joined_on).format('MM/DD/YYYY')}
							</label>
						</div>
						<div className="col-span-1 flex items-center ">
							<i className="fa-light fa-user-group"></i>
							<label className="body-text--smallest ms-1">
								{shortenNumber(data.user.followers_count)}
							</label>
						</div>
						<div className="col-span-1 flex items-center ">
							<i className="fa-light fa-square-user"></i>
							<label className="body-text--smallest ms-1">
								{data.space_membership_count}
							</label>
						</div>
						<div className="col-span-1 flex items-center ">
							<i className="fa-light fa-hexagon-image"></i>
							<label className="body-text--smallest ms-1">
								{data.space_item_count}
							</label>
						</div>
						<div className="col-span-3 flex items-center">
							<a
								className="button-secondary-gradient w-full"
								href={routes.viewUserSpace() + data.user.profile.username + '/about'}
							>
								<span><p><i className="fa-light fa-arrow-up-right-from-square"/></p></span>
							</a>
							<a
								className="button-secondary-gradient ms-4 w-full"
								href={redirectToSpaceLink(routes.manageMember(), data.id)}
							>
								<span><p><i className="fa-light fa-user-magnifying-glass"/></p></span>
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	};

	useEffect(() => {
		if (!initialLoad) {
			getMembersRequest().then((r) => {});
		}

		if (authUserInfo && spaceInfoLoading && spaceInfoLoading === 'done') {
			if (initialLoad) {
				getMembersRequest().then((r) => {});
			}

			setInitialLoad(false);
		}
	}, [
		authUserInfo,
		spaceInfoLoading,
		spaceInfo,
		membersCurrentPage,
		selectedFilter,
		selectedSort,
		filterArr,
		sortArr,
		usernameSortOrder,
		joinDateSortOrder,
		followerSortOrder,
		membershipSortOrder,
		itemSortOrder
	]);

	return (
		<div className="block-full">
			<div className="grid grid-cols-12 gap-2 md:gap-8">
				<div className="col-span-12 md:col-span-6 block-lg card">
					<div className="dashboard-wrapper">
						<h5 className="header-small text-center">{totalMembersCount}</h5>
						<p className="body-text--smallest text-center">Total Members</p>
						{/*<div className="grid grid-cols-1 md:grid-cols-3 justify-evenly mt-5">*/}
						{/*	<div className="text-center ">*/}
						{/*		<p className="body-text--smallest gradient-color-txt mb-2">*/}
						{/*			+{newMembersTodayCount}*/}
						{/*		</p>*/}
						{/*		<p className="body-text--tiny m-0">New Today</p>*/}
						{/*	</div>*/}
						{/*	<div className="text-center">*/}
						{/*		<p className="body-text--smallest gradient-color-txt mb-2">*/}
						{/*			+{newMembersMonthCount}*/}
						{/*		</p>*/}
						{/*		<p className="body-text--tiny m-0">New This Month</p>*/}
						{/*	</div>*/}
						{/*	<div className="text-center">*/}
						{/*		<p className="body-text--smallest gradient-color-txt mb-2">*/}
						{/*			+{lostMembersMonthCount}*/}
						{/*		</p>*/}
						{/*		<p className="body-text--tiny m-0">Lost This Month</p>*/}
						{/*	</div>*/}
						{/*</div>*/}
					</div>
				</div>
				<div className="col-span-12 md:col-span-6 block-lg card">
					<div className="dashboard-wrapper">
						<h5 className="header-small text-center">
							{totalActiveMembersCount}(
							{getPercentage(totalMembersCount, totalActiveMembersCount)})
						</h5>
						<p className="body-text--smallest text-center">Recently Active</p>
						{/*<div className="grid grid-cols-1 md:grid-cols-3 justify-evenly mt-5">*/}
						{/*	<div className="text-center">*/}
						{/*		<p className="body-text--smallest gradient-color-txt mb-2">*/}
						{/*			{totalActiveMembersCount} (*/}
						{/*			{getPercentage(totalMembersCount, dailyActiveCount)})*/}
						{/*		</p>*/}
						{/*		<p className="body-text--tiny m-0">Daily Active</p>*/}
						{/*	</div>*/}
						{/*	<div className="text-center">*/}
						{/*		<p className="body-text--smallest gradient-color-txt mb-2">*/}
						{/*			{totalActiveMembersCount} (*/}
						{/*			{getPercentage(totalMembersCount, weeklyActiveCount)})*/}
						{/*		</p>*/}
						{/*		<p className="body-text--tiny m-0">Weekly Active</p>*/}
						{/*	</div>*/}
						{/*	<div className="text-center">*/}
						{/*		<p className="body-text--smallest gradient-color-txt mb-2">*/}
						{/*			{totalActiveMembersCount} (*/}
						{/*			{getPercentage(totalMembersCount, monthlyActiveCount)})*/}
						{/*		</p>*/}
						{/*		<p className="body-text--tiny m-0">Monthly Active</p>*/}
						{/*	</div>*/}
						{/*</div>*/}
					</div>
				</div>
			</div>
			<div className="grid grid-cols-12 gap-y-8 md:gap-y-0 md:gap-8 mt-4 block-full card">
				<div className="col-span-12">
					<div className="dashboard-wrapper">
						<div className="section-flex">
							<div className="block-lg">
								<div className="section-flex items-center">
									<div className="block-md">
										<h2>Manage Members</h2>
									</div>
									<div className="block-xs text-end">
										<p className="italic">{totalMembersCount} Total</p>
									</div>
								</div>
							</div>
							<div className="block-lg">
								<div className="section-flex">
									<div className="block-smx">
										<div className="form-input-combo">
											<input
												type="text"
												placeholder="Enter Text"
												onChange={(e) => {
													setSearchText(e.target.value);
													changeDelay();
												}}
												value={searchText}
												onBlur={() => getMembersRequest()}
											/>
											<button
												onClick={() => {
													clearTimeout(searchInputTimer);
													setSearchInputTimer(null);
													getMembersRequest().then((r) => {});
												}}
												className="secondary-gradient">
												<span><p><i className="fa-light fa-magnifying-glass"/></p></span>
											</button>
										</div>
									</div>
									<div className="block-smx">
										<button type="button" className="secondary-gradient w-full"
												onClick={() => setModalOpen(true)}
										>
											<span><p>Invite</p></span>
										</button>
										<InviteGrantMembershipModal
											modalOpen={modalOpen}
											setModalOpen={setModalOpen}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="grid grid-cols-1 sm:grid-cols-2 mt-5 gap-8">
							<DropdownWithItems
								title="Currency"
								customButtonClass="button gradient secondary distribute w-full"
								icon="fa-light fa-filter"
								selectedValue={selectedFilter}
								listItems={MANAGE_MEMBERS_FILTER}
								onDropdownChange={(value) => filterDropdownChange(value)}
							/>
							<DropdownWithItems
								title="Currency"
								customButtonClass="button gradient secondary distribute w-full"
								icon="fa-light fa-bars-sort"
								selectedValue={selectedSort}
								listItems={MANAGE_MEMBERS_SORT}
								onDropdownChange={(value) => sortDropdownChange(value)}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="row-view">
				<div className="block-full card mt-4 overflow-auto ">
					<div className='min-w-[760px] w-full overflow-auto relative'>
					<div className="grid grid-cols-12 header-tiny table-header color-light items-center">
						<div className="col-span-4">
							<Link onClick={() => handleTableSorts('username')}>
								Username
								{usernameSortOrder ? (
									<>
										{usernameSortOrder === 'asc' ? (
											<i className="fa-solid fa-sort-up"></i>
										) : (
											<i className="fa-solid fa-sort-down"></i>
										)}
									</>
								) : (
									<i className="fa-regular fa-sort"></i>
								)}
							</Link>
						</div>
						<div className="col-span-2">
							<Link onClick={() => handleTableSorts('join_date')}>
								Member
								<br />
								Join Date
								{joinDateSortOrder ? (
									<>
										{joinDateSortOrder === 'asc' ? (
											<i className="fa-solid fa-sort-up"></i>
										) : (
											<i className="fa-solid fa-sort-down"></i>
										)}
									</>
								) : (
									<i className="fa-regular fa-sort"></i>
								)}
							</Link>
						</div>
						<div className="col-span-1">
							<Link onClick={() => handleTableSorts('follower_count')}>
								Follower
								<br />
								Count
								{followerSortOrder ? (
									<>
										{followerSortOrder === 'asc' ? (
											<i className="fa-solid fa-sort-up"></i>
										) : (
											<i className="fa-solid fa-sort-down"></i>
										)}
									</>
								) : (
									<i className="fa-regular fa-sort"></i>
								)}
							</Link>
						</div>
						<div className="col-span-1">
							<Link onClick={() => handleTableSorts('memberships')}>
								Membership
								<br />
								Count
								{membershipSortOrder ? (
									<>
										{membershipSortOrder === 'asc' ? (
											<i className="fa-solid fa-sort-up"></i>
										) : (
											<i className="fa-solid fa-sort-down"></i>
										)}
									</>
								) : (
									<i className="fa-regular fa-sort"></i>
								)}
							</Link>
						</div>
						<div className="col-span-1">
							<Link onClick={() => handleTableSorts('items')}>
								Item
								<br />
								Count
								{itemSortOrder ? (
									<>
										{itemSortOrder === 'asc' ? (
											<i className="fa-solid fa-sort-up"></i>
										) : (
											<i className="fa-solid fa-sort-down"></i>
										)}
									</>
								) : (
									<i className="fa-regular fa-sort"></i>
								)}
							</Link>
						</div>
						<Link className="col-span-3">Actions</Link>
					</div>
				</div>
				{membersList && (
					<>
						{membersList.map((data, index) => (
							<div key={index}>{MemberRow(data)}</div>
						))}
					</>
				)}
			</div>
			</div>
			{membersList && membersList.length > 10 && (
			<div className="grid grid-cols-12 gap-8 mt-3 items-center">
				<Pagination size="sm" onClick={(event) => handleOnClickPagination(event)}>
					{membersPagination}
				</Pagination>
			</div>
			)}
		</div>
	);
};

export default memo(Members);
