import React, {memo, useCallback, useEffect, useState} from "react";
import {Form} from "antd";
import {GOOGLE_MAP_KEY, ROOT_DOMAIN, VALIDATE_MESSAGES} from "../../../constants/common";
import {useDispatch, useSelector} from "react-redux";
import '../SpaceBuyMembership.scss'
import {
    getSpaceMembership, getSpaceUsernameByDomain,
    postUserSpaceMembershipClaimAndSetupSharing,
} from "../../../services/space";
import default_items_img from "../../../assets/images/form_background.png";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {getSpaceInfo} from "../../../states/spaceGlobal/actions";
import routes from "../../../routes";
import {getLocalStorage} from "../../../utils/storage";
import {getAuthUserInfo} from "../../../states/general/actions";
import {MembershipItemUtils, PaymentType} from "../../../utils/MembershipItemUtils";
import {getUserSpaceMembershipClaim, register} from "../../../services/user";
import {membershipItemImage} from "../../../components/commons/helpers/MembershipItemHelpers";
import {checkActiveMembershipStatus, updateMembershipItemStatus} from "../../../services/payment_method";
import ActivationMembershipModal from "../../../components/commons/Modals/ActivationMembership/ActivationMembershipModal";
import Autocomplete from "react-google-autocomplete";
import {isAlphaNumeric} from "../../../utils/form";
import {setAccessToken} from "../../../utils/common";

const MembershipPaymentCompleted = () => {

    const [form] = Form.useForm();
    const [membershipPaymentCompleted] = Form.useForm();
    const {authUserInfo, loading} = useSelector((state) => state.general);
    const {spaceInfo, spaceInfoLoading} = useSelector((state) => state.space);

    const currentLocation = useLocation();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const membershipId = searchParams.get('id');

    const [isPaid, setIsPaid] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [formHasError, setFormHasError] = useState(true);
    const [formIsOnFocus, setFormIsOnFocus] = useState(false);
    const [isActivationOpen, setActivationOpen] = useState(false);
    const [loadMembershipOnce, setLoadMembershipOnce] = useState(false);
    const [isMembershipLoaded, setMembershipLoaded] = useState(false);
    const [isMembershipClaimLoaded, setMembershipClaimLoaded] = useState(false);

    const [alert, setAlert] = useState(null);
    const [membership, setMembership] = useState(null);
    const [collectionAssetBGImage, setCollectionAssetBGImage] = useState(null);

    const [collectionAssetType, setCollectionAssetType] = useState('image');
    const [selectedFirstName, setSelectedFirstName] = useState('share');
    const [selectedBirthDate, setSelectedBirthDate] = useState('share');
    const [selectedCity, setSelectedCity] = useState('share');
    const [selectedEmail, setSelectedEmail] = useState('share');

    const [blurredField, setBlurredField] = useState(null);
    const [isSubmitClicked, setSubmitClicked] = useState(false);

    const isProfileUpdate = authUserInfo &&
        (!authUserInfo?.profile ||
            (authUserInfo?.profile &&
                (
                    !authUserInfo?.profile?.first_name ||
                    authUserInfo?.profile?.first_name.trim() === '' ||
                    !authUserInfo?.profile?.last_name ||
                    authUserInfo?.profile?.last_name.trim() === '' ||
                    !authUserInfo?.profile?.last_name ||
                    authUserInfo?.profile?.last_name.trim() === ''
                )));
    const [spaceUsername, setSpaceUsername] = useState(null);

    const getMembershipDetailsRequest = useCallback(async (domain) => {
        const data = {
            space_domain: domain
        }
        const response = await getSpaceUsernameByDomain(data);
        if (response && response.result) {
            setSpaceUsername(response.space_username);
        }
    }, []);

    const getMembership = useCallback(async (space_id, membership_id) => {
        if (!membership_id) return;
        const data = {
            space_id: space_id,
            membership_id: membership_id,
            is_get_payment_transaction : true
        }
        if (authUserInfo) {
            data['viewing_user_id'] = authUserInfo.id;
        }
        const response = await getSpaceMembership(data);
        if (response && response.result) {
            const membershipData = response.membership;
            setMembership(membershipData);
            setMembershipLoaded(true);
            setIsPaid(membershipData.is_paid);
            if (membershipData.photo || membershipData.video) {
                if (membershipData.photo) {
                    const bg = {
                        backgroundImage: 'url(' + membershipData.photo + ')',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        borderRadius: '32px',
                    };
                    setCollectionAssetBGImage(bg);
                    setCollectionAssetType('image');
                }
                if (membershipData.video) {
                    setCollectionAssetType('video');
                }
            } else {
                const bg = {
                    backgroundImage: 'url(' + default_items_img + ')',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '32px',
                };
                setCollectionAssetBGImage(bg);
                setCollectionAssetType('image');
            }
        }
    }, [membership, authUserInfo]);

    const getMembershipClaim = useCallback(async (space_id, membership_id) => {
        if (!membership_id) return;
        const data = {
            space_id: space_id,
            membership_id: membership_id
        }
        if (authUserInfo) {
            data['user_id'] = authUserInfo.id;
        }
        const response = await getUserSpaceMembershipClaim(data);
        if (response) {
            if (response.result) {
                if (response.is_exist) {
                    let url = routes.spaceMembershipActivate();
                    const fin_url =
                        url.replace(':space_username', '@' + spaceUsername) +
                        '?id=' +
                        membershipId;
                    // window.location.replace(fin_url);
                }
            }
            setMembershipClaimLoaded(true);
        }
    }, [membership, authUserInfo, spaceUsername]);

    const handleFormOnBlur = useCallback(async () => {
        await form
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const redirectToActivation = () => {
        const url = routes.spaceMembershipActivate();
        const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + membershipId;
        window.location.replace(fin_url);
    }

    const handleSubmit = useCallback(
        async (values) => {
            setAlert(null);
            setSubmitted(true);
            if (!selectedFirstName) {
                return;
            }
            if (!selectedBirthDate) {
                return;
            }
            if (!selectedCity) {
                return;
            }
            if (!selectedEmail) {
                return;
            }
            const data = values;
            data.space_id = spaceInfo.id;
            data.membership_id = membershipId;
            data.share_name = selectedFirstName === 'share';
            data.share_birthdate = selectedBirthDate === 'share';
            data.share_city = selectedCity === 'share';
            data.share_email = selectedEmail === 'share';
            if (values.birthdate) {
                data.birthdate = values.birthdate.format('YYYY-MM-DD');
            }
            data.wallet_contract_address = getLocalStorage(
                'temp_membership_wallet_owner_contract_address'
            );
            if (authUserInfo) {
                data.user_id = authUserInfo.id;
                const response = await postUserSpaceMembershipClaimAndSetupSharing(data);
                if (response) {
                    if (response.result) {
                        // let url = routes.spaceMembershipActivate();
                        // // if (membershipDetails.collection_type === 'membership') {
                        // //     url = routes.spaceMembershipActivate()
                        // // }
                        // const fin_url =
                        //     url.replace(':space_username', '@' + space_username) +
                        //     '?id=' +
                        //     membershipId;
                        // window.location.replace(fin_url);
                        await checkActivateSubscription();
                    } else {
                        // if (response.message === 'Already joined membership.') {
                        //     const url = routes.spaceMembershipActivate()
                        //     const fin_url =
                        //         url.replace(':space_username', '@' + space_username) +
                        //         '?id=' +
                        //         membershipId;
                        //     window.location.replace(fin_url);
                        // }
                        await checkActivateSubscription();
                    }
                } else {
                    console.log('MembershipClaimAndSetupSharing-response: '+JSON.stringify(response));
                }
            }
            setSubmitted(false);
        },
        [
            authUserInfo,
            spaceInfo,
            selectedFirstName,
            selectedBirthDate,
            selectedCity,
            selectedEmail,
            membershipId,
            membership
        ]
    );

    const checkActivateSubscription = useCallback(async () => {
        const data = {
            space_id: spaceInfo.id
        }
        if (authUserInfo) {
            data['user_id'] = authUserInfo.id;
        }
        const checkActiveStatusResponse = await checkActiveMembershipStatus(data);
        if (checkActiveStatusResponse && checkActiveStatusResponse.result) {
            if (checkActiveStatusResponse.is_active_exist) {
                setActivationOpen(true);
            } else {
                await activateSubscription();
            }
        } else {
            await activateSubscription();
        }
    },[spaceInfo, authUserInfo])

    const activateSubscription = useCallback(async () => {
            setSubmitted(true);
            // if (!verifyEmailSpaceMembershipId) return;
            const data = {
                space_id: spaceInfo.id,
                membership_id: membershipId,
                status: 'active'
            }
            if (authUserInfo) {
                data['user_id'] = authUserInfo.id;
            }
            const createStripeResponse = await updateMembershipItemStatus(data);
            if (createStripeResponse) {
                if (createStripeResponse && createStripeResponse.result) {
                    const url = routes.spaceMembershipActivate()
                    const fin_url =
                        url.replace(':space_username', '@' + space_username) +
                        '?id=' +
                        membershipId;
                    window.location.replace(fin_url);
                    // await redirectionPath();
                }
                setSubmitted(false);
            }
        },[
            authUserInfo,
            spaceInfo,
            // verifyEmailSpaceUsername,
            membershipId,
            spaceUsername
        ]
    )

    useEffect(() => {
        if (membership != null) {
            setMembershipLoaded(false);
        }
        if (authUserInfo===null) {
            dispatch(getAuthUserInfo());
        }
        if (spaceUsername && !isMembershipLoaded) {
            if (spaceInfoLoading && spaceInfoLoading === 'done') {
                if (spaceInfo && spaceInfo.id) {
                    getMembership(spaceInfo.id, membershipId).then((r) => {});
                    // setSpaceName(spaceInfo.name);
                }
            } else {
                if (!spaceInfoLoading && initialLoad) {
                    setInitialLoad(false);
                    dispatch(getSpaceInfo(spaceUsername));
                }
                if (spaceInfoLoading === 'failed') {
                    window.location.replace(routes.createSpace());
                }
            }
        }
        if (membership != null) {
            if (authUserInfo != null && !loadMembershipOnce) {
                setLoadMembershipOnce(true);
                getMembership(spaceInfo.id, membershipId).then((r) => {});
            }
            // Redirect to details if already claim or paid
            if (authUserInfo != null) {
                getMembershipClaim(spaceInfo.id, membershipId).then(r =>{});
            }
        }
        //Redirect user to logged in page if not authenticated
        MembershipItemUtils.notAuthRedirect(loading, authUserInfo);
    }, [
        currentLocation.pathname,
        authUserInfo,
        spaceInfo,
        spaceInfoLoading,
        spaceUsername,
        membershipId,
        membership,
        isMembershipClaimLoaded
    ]);

    const redirectionPath = useCallback(async () => {
        // const url = routes.viewUserSpace() + verifyEmailSpaceUsername;
        // const fin_url = url.replace(':space_username', '@' + verifyEmailSpaceUsername) + '?id=' + verifyEmailSpaceMembershipId;
        // window.location.replace(fin_url);
    }, [
        // verifyEmailSpaceUsername,
        // verifyEmailSpaceMembershipId
    ])

    const handleFinishFailed = useCallback(async (errorInfo) => {
        setSubmitClicked(true);
        // Set the blurred field to the first field with an error
        if (errorInfo.errorFields.length > 0) {
            setBlurredField('all');
        }
    }, [blurredField, isSubmitClicked]);

    const handleOnblurField = useCallback(async (field) => {
        if (!isSubmitClicked) {
            setBlurredField(field);
        }
        setSubmitClicked(false);
    }, [blurredField, isSubmitClicked]);

    function handleSkipActivation() {
        const url = routes.spaceMembershipBuy();
        const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + membership.id;
        window.location.replace(fin_url);
        // window.location.replace(fin_url);
    }

    const handleSubmitSaveAccount = useCallback(async (values) => {
            const data = values;
            if (values.birthdate) {
                data.birthdate = values.birthdate;
            }
            if (authUserInfo) {
                data.user_id = authUserInfo.id;
                // if (isProfileUpdate) {
                //     data.is_profile_update = isProfileUpdate;
                // }
                const email = authUserInfo?.email;
                if (email) {
                    data.email = email
                } else {
                    return;
                }
            }
            const result = await register(data);
            if (result) {
                if (result.result) {
                    if (result.access_token) {
                        setAccessToken(result);
                    }
                    dispatch(getAuthUserInfo());
                    let url = routes.spaceMembershipActivate();
                    const fin_url = url.replace(':space_username', '@' + spaceUsername) + '?id=' + membershipId;
                    window.location.replace(fin_url);
                } else {
                    setAlert({
                        variant: 'danger',
                        message: result.message
                    });
                }
            }
            setSubmitted(false);
        }, [authUserInfo, spaceUsername, membershipId]
    );

    useEffect(() => {
        if (ROOT_DOMAIN !== window.location.hostname) {
            getMembershipDetailsRequest(window.location.hostname).then(r => {});
        } else {
            const path = currentLocation.pathname;
            if (path && path.startsWith('/@') && path.length > 3) {
                const clean_path = path.replace('/@', '');
                const path_exp = clean_path.split('/');
                setSpaceUsername(path_exp[0]);
            }
        }
    }, [currentLocation.pathname]);


    return (
        <>
            { (!authUserInfo || !membership) ? (
                <section id="page-content-loading-overlay" className="screen-modal p-section-safeview theme-transparent">
                    <div className="centerframe-flex frame-modal">
                        <div className="block-lg modal-block">
                            <i className="fa-regular fa-solar-system"/>
                        </div>
                    </div>
                </section>
            ) : (
                <main id="cs-space-main" className="cs-main main-theme">
                    {/*{ membership &&*/}
                    {/*    <ActivationMembershipModal*/}
                    {/*        modalOpen={isActivationOpen}*/}
                    {/*        setModalOpen={setActivationOpen}*/}
                    {/*        membership={membership}*/}
                    {/*        space={spaceInfo}*/}
                    {/*        user={authUserInfo}*/}
                    {/*        activationStatus={'active'}*/}
                    {/*        isPaymentMethod={true}*/}
                    {/*        redirectionPath={redirectionPath}*/}
                    {/*    />*/}
                    {/*}*/}
                    <section id="cs-space-checkout-complete-c1" className="py-0 px-0 lg:py-section-safeview theme-transparent">
                        <div className="cs-centerframe-split-lg">
                            <div className="cs-centerframe-split-left-lg order-2 lg:order-1">
                                <div className="p-section-safeview lg:pr-gutter">
                                    <div className="cs-stack-checkout-form stack-col-base gap-gutterxx lg:max-w-half-breakpoint-less-gutter">
                                        <div className="cs-stack-checkout-confirmation stack-col">
                                            <hgroup className="heading">
                                                <h2>Thank you!</h2>
                                                { membership?.payment_transaction?.payment_type === PaymentType.subscription ? (
                                                    <>
                                                        <p>Your {' '}
                                                            <a href={membership?.type === "nft_collection" ?
                                                                routes.spaceMembersProductsMembership().replace(':space_username', '@' + spaceInfo?.username) + '?id=' + membershipId :
                                                                routes.spaceMembershipBuy().replace(':space_username', '@' + spaceInfo?.username) + '?id=' + membershipId} className="gradient-color-txt">
                                                                <i className="fa-regular fa-solar-system"/>{' '}
                                                                {spaceInfo?.name ?? ''}
                                                            </a>
                                                            {' '}purchase is complete and your payment method has been charged for
                                                            {' '}${(parseFloat(membership?.price ?? 0).toFixed(2)) + ' ' + (membership?.currency != null ? membership?.currency : 'USD') }.
                                                            {/*{' '}${(parseFloat(membership?.payment_transaction?.amount ?? 0).toFixed(2)) + ' ' + (membership?.currency != null ? membership?.currency : 'USD') }.*/}
                                                            {/*(after “Order…” should be order & transaction details and NOT <Spacename>*/}
                                                            {/*{' '}You will receive an email receipt shortly for Order{' '}{membership?.name ?? ''}{' '}with transaction {' '}{membership?.payment_transaction?.transaction_id ?? ''}.*/}
                                                        </p>
                                                        <p>
                                                            <i>Your next renewal will be automatically charged on {membership?.payment_transaction?.subscription_period_end} for ${(parseFloat(membership?.payment_transaction?.amount ?? 0).toFixed(2)) + ' ' + (membership?.currency != null ? membership?.currency : 'USD') }.
                                                                Your renewal plan can be managed at any time from your Dashboard. If you wish to cancel,
                                                                please do so at least 1 full day before the renewal date to avoid being charged.
                                                            </i>
                                                        </p>
                                                    </>
                                                ) : membership?.payment_transaction?.payment_type === PaymentType.onetime ? (
                                                    <p>Your {' '}
                                                        <a href={membership?.type === "nft_collection" ?
                                                            routes.spaceMembersProductsMembership().replace(':space_username', '@' + spaceInfo?.username) + '?id=' + membershipId :
                                                            routes.spaceMembershipBuy().replace(':space_username', '@' + spaceInfo?.username) + '?id=' + membershipId} className="gradient-color-txt">
                                                            <i className="fa-regular fa-solar-system"/>{' '}
                                                            {spaceInfo?.name ?? ''}
                                                        </a>
                                                        {' '}purchase is complete and your payment method has been charged for{' '}${(membership?.price != null ? parseFloat(membership?.price).toFixed(2) : 0) + ' ' + (membership?.currency != null ? membership?.currency : 'USD') }.
                                                        {/*(after “Order…” should be order & transaction details and NOT <Spacename>*/}
                                                        {/*{' '}You will receive an email receipt shortly for Order{' '}{membership?.name ?? ''}{' '}with transaction{' '}{membership?.payment_transaction?.transaction_id ?? ''}.*/}
                                                    </p>
                                                ) : (
                                                    <p>Your {' '}
                                                        <a href={membership?.type === "nft_collection" ?
                                                            routes.spaceMembersProductsMembership().replace(':space_username', '@' + spaceInfo?.username) + '?id=' + membershipId :
                                                            routes.spaceMembershipBuy().replace(':space_username', '@' + spaceInfo?.username) + '?id=' + membershipId} className="gradient-color-txt">
                                                            <i className="fa-regular fa-solar-system"/>{' '}
                                                            {spaceInfo?.name ?? ''}
                                                        </a>
                                                        {' '}order is complete.
                                                        {/*(after “Order…” should be order & transaction details and NOT <Spacename>*/}
                                                        {/*You will receive an email receipt shortly for Order {membership?.name ?? ''}*/}
                                                        {/*{' '}for{' '}${(membership?.price != null ? parseFloat(membership?.price).toFixed(2) : 0) + ' ' + (membership?.currency != null ? membership?.currency : 'USD') }.*/}
                                                    </p>
                                                )}
                                            </hgroup>

                                            { isProfileUpdate &&
                                                <div className="cs-product-preview-row">
                                                    <div className="avatar-square-sm">
                                                        <img src={membership?.photo} alt="Image of Friends & Mike Membership"/>
                                                    </div>
                                                    <hgroup>
                                                        <h5>{' '}{spaceInfo ? spaceInfo.name : ''} Membership</h5>
                                                        <p>The Official {' '}{spaceInfo ? spaceInfo.name : ''} Membership unlocks global benefits and community.</p>
                                                    </hgroup>
                                                </div>
                                            }

                                        </div>
                                        <div className="cs-stack-checkout-activate stack-col">
                                            { isProfileUpdate ? (
                                                <>
                                                    <hgroup className="heading">
                                                        <h3>Complete your Account & Activate your Membership</h3>
                                                        <p>Continue your account creation below then activate your Membership to access its benefits, or you can choose to activate it later from your Dashboard.</p>
                                                    </hgroup>

                                                    <Form
                                                        form={form}
                                                        name="registerForm"
                                                        id="registerForm"
                                                        validateMessages={VALIDATE_MESSAGES}
                                                        onFocus={() => {
                                                            setFormIsOnFocus(true);
                                                        }}
                                                        onBlur={() => {
                                                            setTimeout(() => {
                                                                setFormIsOnFocus(false);
                                                                handleFormOnBlur().then(() => {});
                                                            }, 200);
                                                        }}
                                                        initialValues={{
                                                            remember: true
                                                        }}
                                                        // onChange={handleFormChanges}
                                                        onFinish={handleSubmitSaveAccount}
                                                        onFinishFailed={handleFinishFailed}
                                                        className='cs-stack-create-account stack-col'
                                                    >
                                                        <Form.Item
                                                            name="first_name"
                                                            label="First Name"
                                                            rules={[
                                                                {
                                                                    required: true
                                                                }
                                                            ]}
                                                        >
                                                            <div className="form-input">
                                                                <label htmlFor="firstname">First Name</label>
                                                                <input id="first_name"
                                                                       onBlur={()=>handleOnblurField('first_name')}
                                                                       name="first_name" type="text" autoComplete="first_name" placeholder="Name"/>
                                                            </div>
                                                        </Form.Item>
                                                        {((blurredField === 'first_name' || blurredField === 'all') && (form.getFieldsError()[0]?.errors.length > 0)) && (
                                                            <div className="alert callout warning">
                                                                <div className="action">
                                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                                </div>
                                                                <div className="content">
                                                                    <div>
                                                                        <h6>{form.getFieldsError()[0]?.errors}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <Form.Item
                                                            name="last_name"
                                                            label="Last Name"
                                                            rules={[
                                                                {
                                                                    required: true
                                                                }
                                                            ]}
                                                        >
                                                            <div className="form-input">
                                                                <label htmlFor="lastname">Last Name</label>
                                                                <input id="last_name"
                                                                       onBlur={()=>handleOnblurField('last_name')}
                                                                       name="last_name" type="text" autoComplete="last_name" placeholder="Surname"/>
                                                            </div>
                                                        </Form.Item>
                                                        {((blurredField === 'last_name' || blurredField === 'all') && (form.getFieldsError()[1]?.errors.length > 0)) && (
                                                            <div className="alert callout warning">
                                                                <div className="action">
                                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                                </div>
                                                                <div className="content">
                                                                    <div>
                                                                        <h6>{form.getFieldsError()[1]?.errors}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <Form.Item
                                                            name="birthdate"
                                                            label="Birthdate"
                                                            rules={[
                                                                {
                                                                    required: true
                                                                }
                                                            ]}
                                                        >
                                                            <div  className="form-input">
                                                                <label htmlFor="birthdate">Birthdate</label>
                                                                <input id="birthdate"
                                                                       onBlur={()=>handleOnblurField('birthdate')}
                                                                       onChange={(date) => {
                                                                    form.setFieldsValue({ birthdate: date.target.value });
                                                                }}
                                                                       name="birthdate" type="date" autoComplete="birthdate" placeholder="MM/DD/YYYY"></input>
                                                            </div>
                                                        </Form.Item>
                                                        {((blurredField === 'birthdate' || blurredField === 'all') && (form.getFieldsError()[2]?.errors.length > 0)) && (
                                                            <div className="alert callout warning">
                                                                <div className="action">
                                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                                </div>
                                                                <div className="content">
                                                                    <div>
                                                                        <h6>{form.getFieldsError()[2]?.errors}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <Form.Item
                                                            name="city"
                                                            label="City"
                                                            rules={[
                                                                {
                                                                    required: true
                                                                }
                                                            ]}
                                                        >
                                                            <div className="form-input">
                                                                <label htmlFor="city">City</label>
                                                                <Autocomplete
                                                                    placeholder="Start typing & select from list."
                                                                    autoComplete="off"
                                                                    onBlur={()=>handleOnblurField('city')}
                                                                    apiKey={GOOGLE_MAP_KEY}
                                                                    onPlaceSelected={(place) => {
                                                                        form.setFieldValue(
                                                                            'city',
                                                                            place.formatted_address
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        </Form.Item>
                                                        {((blurredField === 'city' || blurredField === 'all') && (form.getFieldsError()[3]?.errors.length > 0)) && (
                                                            <div className="alert callout warning">
                                                                <div className="action">
                                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                                </div>
                                                                <div className="content">
                                                                    <div>
                                                                        <h6>{form.getFieldsError()[3]?.errors}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <p className="cs-helper">Your Birthdate and City are kept private and not publicly visible. This information is needed to comply with local regulations and for access to age-appropriate content.</p>
                                                        <Form.Item
                                                            name="password"
                                                            label="Password"
                                                            rules={[
                                                                {required: true},
                                                                ({getFieldValue}) => ({
                                                                    validator(_, value) {
                                                                        if (value && value.length < 8) {
                                                                            return Promise.reject(
                                                                                new Error('Password must be 8 characters long or more.')
                                                                            );
                                                                        }
                                                                        if (isAlphaNumeric(value)) {
                                                                            return Promise.resolve();
                                                                        }
                                                                        if (value && value.length > 0) {
                                                                            return Promise.reject(
                                                                                new Error(
                                                                                    'Password is case sensitive, must be at least 8 characters, and include a symbol and number.'
                                                                                )
                                                                            );
                                                                        }
                                                                        return Promise.resolve();
                                                                    }
                                                                })
                                                            ]}
                                                        >
                                                            <div className="form-input">
                                                                <label htmlFor="password">Password</label>
                                                                <input id="password"
                                                                       onBlur={()=>handleOnblurField('password')}
                                                                       name="password" type="password" autoComplete="password" placeholder="8+ characters with a symbol and number."/>
                                                            </div>
                                                        </Form.Item>
                                                        {((blurredField === 'password' || blurredField === 'all') && (form.getFieldsError()[4]?.errors.length > 0)) && (
                                                            <div className="alert callout warning">
                                                                <div className="action">
                                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                                </div>
                                                                <div className="content">
                                                                    <div>
                                                                        <h6>{form.getFieldsError()[4]?.errors}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <Form.Item
                                                            name="confirmpassword"
                                                            label="Confrim Password"
                                                            dependencies={['password']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please confirm your password.'
                                                                },
                                                                ({getFieldValue}) => ({
                                                                    validator(_, value) {
                                                                        if (!value || getFieldValue('password') === value) {
                                                                            return Promise.resolve();
                                                                        }
                                                                        return Promise.reject(new Error('Password does not match.'));
                                                                    }
                                                                })
                                                            ]}
                                                        >
                                                            <div className="form-input">
                                                                <label htmlFor="password">Re-enter Password*</label>
                                                                <input id="confirmpassword"
                                                                       onBlur={()=>handleOnblurField('confirmpassword')}
                                                                       name="confirmpassword" type="password" autoComplete="confirmpassword" placeholder="Re-enter Password"/>
                                                            </div>
                                                        </Form.Item>
                                                        {((blurredField === 'confirmpassword' || blurredField === 'all') && (form.getFieldsError()[5]?.errors.length > 0)) && (
                                                            <div className="alert callout warning">
                                                                <div className="action">
                                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                                </div>
                                                                <div className="content">
                                                                    <div>
                                                                        <h6>{form.getFieldsError()[5]?.errors}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <Form.Item>
                                                            <div className="stack-buttons col">
                                                                <button
                                                                    disabled={submitted}
                                                                    type="submit"
                                                                    className="primary-gradient">
                                                                    <span><p>Save & Continue</p></span>
                                                                </button>
                                                                {/*<a onClick={handleSkipActivation} type="button" className="button-secondary-gradient"><span><p>Skip Activation</p></span></a>*/}
                                                            </div>
                                                        </Form.Item>

                                                        {alert && (
                                                            <div className="alert callout warning !m-0">
                                                                <div className="action">
                                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                                </div>
                                                                <div className="content">
                                                                    <div>
                                                                        <h6>{alert?.message ?? 'Something went wrong!'}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Form>
                                                </>
                                            ) : (
                                                <div className="cs-stack-checkout-activate stack-col">
                                                    <hgroup className="heading">
                                                        {/* CSJS "Membership" or "Item" based on the product type */}
                                                        <h2>Activate your Membership</h2>
                                                        <p>You can activate your Membership on your Account now to access its benefits, or you can activate it later from your Dashboard.</p>
                                                    </hgroup>
                                                    <div className="cs-product-preview-row">
                                                        <div className="avatar-square-sm">
                                                            <img src={membership?.photo} alt="Image of Friends & Mike Membership"/>
                                                        </div>
                                                        <hgroup>
                                                            <h5>{' '}{spaceInfo ? spaceInfo.name : ''} Membership</h5>
                                                            <p>The Official {' '}{spaceInfo ? spaceInfo.name : ''} Membership unlocks global benefits and community.</p>
                                                        </hgroup>
                                                    </div>

                                                    <div className="stack-buttons col">
                                                        <a onClick={redirectToActivation} type="button" className="button-primary-gradient"><span><p>Continue to Activate</p></span></a>
                                                        <a onClick={handleSkipActivation} type="button" className="button-secondary-gradient"><span><p>Skip Activation</p></span></a>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cs-centerframe-split-right-lg order-1 lg:order-2 lg:pt-gutter lg:pb-gutter">
                                <div className="cs-checkout-product-hero">
                                    {membershipItemImage(collectionAssetType, membership)}
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            )}
        </>
    );
}

export default memo(MembershipPaymentCompleted);