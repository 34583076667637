import React, { memo, useEffect, useState, useCallback } from 'react';
import { Form } from 'antd';
import { VALIDATE_MESSAGES } from '../../../../constants/common';
import Alert from 'react-bootstrap/Alert';
import { createSpaceBenefit } from '../../../../services/space';
import { useSelector } from 'react-redux';
import DropdownWithItems from "../../../../components/commons/DropdownWithItems";
import routes from "../../../../routes";
import {useNavigate} from "react-router-dom";
import {formatFileSize} from "../../../../utils/file";



const CreateDownloadBenefit = (props) => {
    const navigate = useNavigate();
    const [createBenefitForm] = Form.useForm();
    const [formHasError, setFormHasError] = useState(true);
    const [formIsOnFocus, setFormIsOnFocus] = useState(false);
    const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
    const { authUserInfo, loading } = useSelector((state) => state.general);
    const [selectedButtonSetting, setSelectedButtonSetting] = useState('default');
    const [alert, setAlert] = useState(null);
    const [isSubmitClicked, setSubmitClicked] = useState(false);
    const [blurredField, setBlurredField] = useState(null);
    const [numberOfFiles, setNumberOfFiles] = useState([1]);
    const [files, setFiles] = useState([]);
    const [submitted, setSubmitted] = useState(false);

    const dummyButtonOptions = [
        { label: 'Default', value: 'default' },
        { label: 'Benefits Post', value: 'benefits_post' },
        { label: 'Custom URL', value: 'custom_url' }
    ];

    const handleFormOnBlur = useCallback(async () => {
        await createBenefitForm
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });

    }, []);

    const handleSubmit = useCallback(
        async (values) => {
            if (formHasError || submitted) {
                return;
            }

            setSubmitted(true);

            const data = { ...createBenefitForm.getFieldsValue(), space_id: spaceInfo.id };
            data['type'] = 'download';

            let count = 0;
            files.forEach((val) => {
                data['file_' + val['index']] = val['file'];
                count = val['index'] + 1;
            });
            data['files_count'] = count;

            const response = await createSpaceBenefit(data);
            if (response) {
                if (!response.result) {
                    if (response.message) {
                        setAlert({ variant: 'danger', message: response.message });
                    } else {
                        setAlert({
                            variant: 'danger',
                            message: 'Something went wrong'
                        });
                    }
                }
            }

            setSubmitted(false);

            redirectToSpaceLink(routes.spaceBenefits(), spaceInfo.username, '@');
        }, [spaceInfo, formHasError, files, numberOfFiles, submitted, createBenefitForm]);

    const onChangeFile = useCallback(async (elem, file_data, index) => {
        const arr = {
            'index': index,
            'file': file_data
        };

        const big_size_array = ['GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const fileSize = formatFileSize(file_data.size);
        if (fileSize) {
            const size = parseInt(fileSize.split(" ")[0]);
            const size_group = fileSize.split(" ")[1];
            if (big_size_array.includes(size_group)) {
                console.log('File exceed max size!');
                elem.target.value = null;
                return false;
            }
            if (size_group === 'MB') {
                if (size > 128) {
                    console.log('File exceed max size!');
                    elem.target.value = null;
                    return false;
                }
            }
        }

        const file_index = files.findIndex(x => x.index === index);
        if (file_index >= 0) {
            files[file_index] = arr;
        } else {
            files.push(arr);
        }

        setFiles(files);
    }, [files]);

    const handleFormChanges = useCallback(async () => {
        await createBenefitForm
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });

    }, []);

    const redirectToSpaceLink = (route, spaceUsername, prepend = '') => {
        navigate(route.replace(':space_username', prepend + spaceUsername));
    };

    const handleButtonSettingChange = (value) => {
        setSelectedButtonSetting(value)
        createBenefitForm.setFieldsValue({ button_settings: value });
    };

    const handleOnblurField = useCallback(async (field) => {
        if (!isSubmitClicked) {
            setBlurredField(field);
        }
        setSubmitClicked(false);
    }, [createBenefitForm, blurredField, isSubmitClicked]);

    const addAdditionalFile = useCallback(async () => {
        if (numberOfFiles.length >= 16) {
            return;
        }
        setFormIsOnFocus(true);

        numberOfFiles.push(numberOfFiles.length + 1);
        setNumberOfFiles(numberOfFiles);

        setTimeout(() => {
            setFormIsOnFocus(false);
            handleFormOnBlur().then(r => {});
        });
    }, [numberOfFiles]);

    const removeInputFile = (numberOfFiles, files, index) => {
        const file_index = files.findIndex(x => x.index === index);
        if (file_index >= 0) {
            files.splice(file_index, 1);
        }

        const input_index = numberOfFiles.indexOf(index);
        if (input_index >= 0) {
            numberOfFiles.splice(index, 1);
        }

        setFormIsOnFocus(true);

        setNumberOfFiles(numberOfFiles);
        setFiles(files);

        setTimeout(() => {
            setFormIsOnFocus(false);
            handleFormOnBlur().then(r => {});
        });
    };

    useEffect(()=>{
        if (selectedButtonSetting === 'custom_url') {
            handleOnblurField('custom_url').then(r => {});
            handleFormChanges().then(r => {});
        }
    }, [selectedButtonSetting])

    useEffect(() =>{},[spaceInfo, createBenefitForm, numberOfFiles, files, submitted]);

    return (
        <div className="cs-dashframe section-flex">
            <div className="cs-dash-card block-full card">
                <hgroup className="heading">
                    <h2>Create New Download Benefit</h2>
                    <p>A <a href="#link-via-admin" target="_blank">Download Benefit</a> is a type of Benefit which provides a limited or unlimited number of doanloads of one or many files. Each individual Membership or Item with this Benefit will be able to access the same specified file(s), however each will have it's own individual download limit if a limit is set. <a href="#link-via-admin" target="_blank">Learn more about the Download Benefit Type.</a></p>
                </hgroup>
            </div>
            <div className="cs-dash-card block-full card">
                <Form
                    form={createBenefitForm}
                    name="createBenefitForm"
                    id="createBenefitForm"
                    validateMessages={VALIDATE_MESSAGES}
                    onFocus={() => {
                        setFormIsOnFocus(true);
                    }}
                    onBlur={() => {
                        setFormIsOnFocus(false);
                        handleFormOnBlur().then(r => {});
                    }}
                    onChange={handleFormChanges}
                    onFinish={handleSubmit}
                    className={formIsOnFocus ? 'hide-antd-error-messages stack-col' : 'stack-col'}
                >
                    <>
                        {alert && (
                            <div className="grid grid-cols-12 gap-8 mt-5">
                                <div className="col-span-8">
                                    <Alert
                                        key={alert.variant}
                                        variant={alert.variant}
                                        className={
                                            'custom-alert ' +
                                            (alert.variant === 'danger'
                                                ? 'custom-alert-danger'
                                                : 'custom-alert-success')
                                        }
                                    >
                                        {alert.message}
                                    </Alert>
                                </div>
                            </div>
                        )}
                    </>
                    {/* <form className="stack-col"> */}
                    <div className="formrow title">
                        <h3>Details</h3>
                        <p>Provide Benefit information</p>
                    </div>

                    <Form.Item
                        label="Name"
                        name="name"
                        className="cs-stack-controlrows stack-form-col"
                        validateTrigger="onBlur"
                        rules={[{ required: true }]}
                    >
                        <div className="formrow split">
                            <div className="form-input gradient">
                                <span>
                                    <label htmlFor="name">Name</label>
                                    <input id="name"
                                           type="text"
                                           maxLength={32}
                                           onBlur={()=>handleOnblurField('name')}
                                           placeholder="Up to 32 characters."/>
                                </span>
                            </div>
                            <div>
                                <p className='text-sm text-color-secondary italic'>This will be listed on the Product page of Memberships or Items with this Benefit.</p>
                            </div>
                        </div>
                    </Form.Item>
                    { ((blurredField === 'name' || blurredField === 'all') && createBenefitForm.getFieldsError()[0]?.errors.length > 0) && (
                        <div className="alert callout warning !w-full items-center">
                            <div className="action">
                                <i className="fa-solid fa-triangle-exclamation"></i>
                            </div>
                            <div className="content">
                                <div>
                                    <h6>{createBenefitForm.getFieldsError()[0]?.errors}</h6>
                                </div>
                            </div>

                        </div>
                    )}

                    <Form.Item
                        label="Summary"
                        name="details"
                        className="cs-stack-controlrows stack-form-col"
                        validateTrigger="onBlur"
                        rules={[{ required: true }]}
                    >
                        <div className="formrow split">
                            <div className="form-input gradient">
                                <span>
                                    <label htmlFor="details">Summary</label>
                                    <input id="details"
                                           type="text"
                                           maxLength={160}
                                           onBlur={()=>handleOnblurField('details')}
                                           placeholder="Up to 160 characters."/>
                                </span>
                            </div>
                            <div>
                                <p className='text-sm text-color-secondary italic'>This Summary text with display next to the Benefit Name and should communicate what the Benefit provides.</p>
                            </div>
                        </div>
                    </Form.Item>
                    { ((blurredField === 'details' || blurredField === 'all') && createBenefitForm.getFieldsError()[1]?.errors.length > 0) && (
                        <div className="alert callout warning !w-full items-center">
                            <div className="action">
                                <i className="fa-solid fa-triangle-exclamation"></i>
                            </div>
                            <div className="content">
                                <div>
                                    <h6>{createBenefitForm.getFieldsError()[1]?.errors}</h6>
                                </div>
                            </div>

                        </div>
                    )}

                    <Form.Item
                        label="Note"
                        name="note"
                        className="cs-stack-controlrows stack-form-col"
                        validateTrigger="onBlur"
                        rules={[{ required: true }]}
                    >
                        <div className="formrow split">
                            <div className="form-input gradient">
                                <span>
                                    <label htmlFor="note">Note</label>
                                    <input id="note"
                                           type="text"
                                           maxLength={160}
                                           onBlur={()=>handleOnblurField('note')}
                                           placeholder="Optional, up to 160 characters."/>
                                </span>
                            </div>
                            <div><p className='text-sm text-color-secondary italic'>This private text is shown to Members as a interstitial, pop-up, or content before the Benefit is accessed.</p></div>
                        </div>
                    </Form.Item>
                    { ((blurredField === 'note' || blurredField === 'all') && createBenefitForm.getFieldsError()[2]?.errors.length > 0) && (
                        <div className="alert callout warning !w-full items-center">
                            <div className="action">
                                <i className="fa-solid fa-triangle-exclamation"></i>
                            </div>
                            <div className="content">
                                <div>
                                    <h6>{createBenefitForm.getFieldsError()[2]?.errors}</h6>
                                </div>
                            </div>

                        </div>
                    )}

                    <div className="formrow title">
                        <h4>Settings</h4>
                        <p>Configure the functions for this Benefit type</p>
                    </div>
                    <Form.Item
                        label="DL Limit"
                        name="dl_limit"
                        className="cs-stack-controlrows stack-form-col"
                        validateTrigger="onBlur"
                    >
                        <div className="formrow split">
                            <div className="form-input gradient"><span><label htmlFor="dl_limit">DL Limit</label>
                                <input id="dl_limit"
                                       type="text"
                                       defaultValue={0}
                                       placeholder="Enter number or 0 for unlimited."
                                       onKeyPress={(event) => {
                                           if (!/[0-9]/.test(event.key)) {
                                               event.preventDefault();
                                           }
                                       }}
                                /></span></div>
                            <div><p className='text-sm text-color-secondary italic'>Limit downloads or enter "0" for unlimited downloads. Each download makes the file(s) available for 24 hours.</p></div>
                        </div>
                    </Form.Item>

                    {numberOfFiles && numberOfFiles.map((val, index) => (
                        <div className="formrow split" key={index}>
                            <div className="form-input-file unlabeled gradient has-">
                                <span>
                                    <label htmlFor="file-input">Upload File</label>
                                    <input type="file"
                                           onChange={(event) => onChangeFile(event, event.target.files[0] || null, index)}
                                    />
                                    {/*{index > 0 && (*/}
                                    {/*    <i className="fa fa-minus-circle remove-benefit-file" onClick={(e) => {removeInputFile(numberOfFiles, files, index)}}></i>*/}
                                    {/*)}*/}
                                </span>
                            </div>
                            <div><p className='text-sm text-color-secondary italic'>Upload any document, media, or archive file type, up to 128MB.</p></div>
                        </div>
                    ))}

                    {numberOfFiles.length < 16 && (
                        <>
                            <div className="formrow split">
                                <div><p className="w-full text-center"><a href="#" onClick={addAdditionalFile}>Add Another File</a></p></div>
                                <div><p className='text-sm text-color-secondary italic'>You can add up to 16 files.</p></div>
                            </div>
                        </>
                    )}

                    <div className="formrow title">
                        <h4>Button</h4>
                        <p>Set the button behavior for this Benefit</p>
                    </div>
                    <Form.Item
                        label="Button Settings"
                        name="button_settings"
                        className="cs-stack-controlrows stack-form-col"
                        validateTrigger="onBlur"
                        rules={[{ required: true }]}
                    >
                        <div className="formrow split">
                            {/* <button type="button" className="button gradient secondary distribute" id="menu-button" aria-expanded="false" aria-haspopup="true"><span><i className="fa-light fa-key"></i><p>Default</p><i className="fa-solid fa-caret-down"></i></span></button> */}
                            <DropdownWithItems
                                title="Select Button Setting"
                                customButtonClass="button gradient secondary dropdown w-full"
                                selectedValue={selectedButtonSetting}
                                listItems={dummyButtonOptions}
                                onDropdownChange={(value) => { handleButtonSettingChange(value) }}
                            />
                            <div><p className='text-sm text-color-secondary italic'>Default directly accesses the Benefit. Benefits Post links to a selected post. Custom URL opens a specified link.</p></div>
                        </div>
                    </Form.Item>

                    {selectedButtonSetting === 'custom_url' && (
                        <>
                            <Form.Item
                                label="Custom URL"
                                name="custom_url"
                                className="cs-stack-controlrows stack-form-col"
                                validateTrigger="onBlur"
                                rules={[{ required: true }]}
                            >
                                <div className="formrow split">
                                    <div className="form-input gradient"><span><label htmlFor="name">Custom URL</label>
                                        <input id="custom_url"
                                               type="text"
                                               onBlur={()=>handleOnblurField('custom_url')}
                                        /></span>
                                    </div>
                                    <div><p className='text-sm text-color-secondary italic'>Redirect URL</p></div>
                                </div>
                            </Form.Item>
                            { ((blurredField === 'custom_url' || blurredField === 'all') && createBenefitForm.getFieldsError()[5]?.errors.length > 0) && (
                                <div className="alert callout warning !w-full items-center">
                                    <div className="action">
                                        <i className="fa-solid fa-triangle-exclamation"></i>
                                    </div>
                                    <div className="content">
                                        <div>
                                            <h6>{createBenefitForm.getFieldsError()[5]?.errors}</h6>
                                        </div>
                                    </div>

                                </div>
                            )}
                        </>
                    )}

                    <div className="stack-buttons col">
                        <a onClick={handleSubmit} className={"button gradient " + (submitted ? "disabled" : "")}>
                            <span>
                                {submitted && <i className="fa-solid fa-spinner fa-spin"></i>}
                                <p className="pl-1">Save Benefit</p>
                            </span>
                        </a>
                        <a onClick={()=>{redirectToSpaceLink(routes.spaceBenefits(), spaceInfo.username, '@')}} className="button gradient secondary"><span><p>Cancel</p></span></a>
                    </div>
                </Form>
            </div>
        </div>
    )
};

export default memo(CreateDownloadBenefit);